import React, { ComponentProps, ReactNode, RefObject, forwardRef, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Dialog as DialogSource, Icon, IconName } from '@blueprintjs/core';
import { Modal } from 'mui';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import Typography from '../Typography';
import Color from '../../styles/color.module.css';
import { ThemeContext } from '../ThemeContext';
import Type from './_type.module.css';
import View from './_view.module.css';

/**
  __Component 'Dialog'__
* */

const Dialog = forwardRef(
  (
    props: {
      type?: keyof typeof Type;
      view?: 'filled' | 'smooth' | 'raised';
      color?: keyof typeof Color;
      icon?: ComponentProps<typeof Icon>['icon'];
      title?: ReactNode;
      text?: ReactNode;
      leftButton?: ReactNode;
      rightButton?: ReactNode;
      backdropOpacity?: 0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;
      onClose?: () => void;
      className?: string;
      notification?: ReactNode;
      onClick?: () => void;
      isOpen: boolean;
      showCloseModalIcon?: boolean;
    } & ComponentProps<typeof DialogSource>,
    ref,
  ) => {
    const {
      type = 'default',
      view = 'filled',
      color = 'default',
      icon,
      title,
      text,
      leftButton,
      rightButton,
      backdropOpacity = '20',
      onClose,
      className,
      notification,
      showCloseModalIcon = true,
      ...restProps
    } = props;
    const [showNotification, setShowNotification] = useState(true);

    useEffect(() => {
      if (notification) {
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 5000);
      }
    }, [notification]);

    const newVersion = useRedesign();

    return newVersion ? (
      <Modal isOpen={restProps.isOpen} onClose={onClose} showCloseIcon={showCloseModalIcon}>
        <Modal.Header>
          <h3 className="lg:hidden">{title}</h3>
          <h5 className="hidden lg:block lg:visible">{title}</h5>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
      </Modal>
    ) : (
      <ThemeContext.Consumer>
        {({ isDark }) => (
          <DialogSource
            {...restProps}
            ref={ref as RefObject<DialogSource>}
            className={cx(Type[type], isDark ? View[`${view}-dark`] : View[view], Color[color], className)}
            backdropClassName={View[`backdrop-${backdropOpacity}`]}
            onClose={onClose}
          >
            <div className={Type.header}>
              <Icon icon={icon} iconSize={type === 'dense' ? 20 : 24} />
              <Typography type={type === 'dense' ? 'h6' : 'h5'} colorStep={100}>
                {title}
              </Typography>
              {showCloseModalIcon && (
                <div className={Type['close-button']} onClick={onClose}>
                  <Icon icon="cross" iconSize={type === 'dense' ? 20 : 24} />
                </div>
              )}
            </div>
            <div className={View['notification']}>
              {showNotification && notification && (
                <div>
                  <div>{notification}</div>
                  <Icon icon="cross" iconSize={20} onClick={() => setShowNotification(false)} />
                </div>
              )}
            </div>
            <Typography type="body" small={type === 'dense'} colorStep={100}>
              {text}
            </Typography>
            {!!(leftButton || rightButton) && (
              <div className={Type.footer}>
                <div className={Type['left-button']}>{leftButton}</div>
                <div className={Type['right-button']}>{rightButton}</div>
              </div>
            )}
          </DialogSource>
        )}
      </ThemeContext.Consumer>
    );
  },
);

Dialog.propTypes = {
  /**
   `The type of the component.
   * Variants: `dense` `default`
   * Default value (if undefined): `default` `
   */
  type: PropTypes.oneOf(['default', 'dense']),
  /**
   ` The view of the component.
   * Variants: `smooth` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(['filled', 'smooth', 'raised']),
  /**
  ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    'default',
    'primary',
    'warning',
    'danger',
    'success',
    'primary_alt',
    'warning_alt',
    'danger_alt',
    'success_alt',
  ]),
  /**
   * Click event handler.
   */
  onClick: PropTypes.func,
  /**
   * Name of a icon to render in the dialog's header. Note that the header
   *      will only be rendered if title is provided.
   */
  icon: PropTypes.any,
  /**
   * Title of the dialog.
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Action text. Haven't default state.
   */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Opacity of backdrop. From 0 to 100 with step 10.
   */
  backdropOpacity: PropTypes.oneOf([0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]),
  /**
   * Left button of dialog.
   */
  leftButton: PropTypes.element,
  /**
   * Right button of dialog.
   */
  rightButton: PropTypes.element,
  /**
   * Toggles the visibility of the overlay and its children. This prop is required because the component is controlled.
   */
  isOpen: PropTypes.any,
  /**
   * A callback that is invoked when user interaction causes the overlay to close, such as clicking on the overlay or pressing the esc key.
   */
  onClose: PropTypes.func,
  /**
   * A boolean flag that decides to either show the cross icon at the top of modal or not.
   */
  showCloseModalIcon: PropTypes.bool,
};

export default Dialog;
