import { P } from 'app/components/common/Typography';
import React from 'react';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

const WarriorSafetyDetails = {
  topical_four_in_one: {
    productHeader: '4 in 1 Gel',
    q1: 'What is 4 in 1 Gel?',
    a1: '4 in 1 Gel is a topical hair product containing dutasteride, minoxidil, tretinoin, fexofenadine, melatonin, and caffeine. It is used to promote hair growth and reduce hair loss.',
    q2: 'Who should not use 4 in 1 Gel?',
    a2: {
      head: 'Do not use 4 in 1 Gel if you:',
      bullets: [
        'Are allergic to any of its ingredients.',
        'Are pregnant, breastfeeding, or planning to become pregnant.',
        'Have open wounds, infections, or irritated skin on the application area.',
        'Are under 18 years of age.',
      ],
    },
    q3: 'What should I tell my healthcare provider before using 4 in 1 Gel?',
    a3: {
      head: 'Before using 4 in 1 Gel, tell your healthcare provider if you:',
      bullets: [
        'Have a history of skin sensitivity or allergies.',
        'Are taking oral dutasteride or other oral or topical hair loss treatments.',
        'Have liver or kidney problems.',
        'Are pregnant or breastfeeding, as this product contains dutasteride, which can cause harm if absorbed through the skin.',
        'Have any other medical conditions.',
        'Are using any medications, including over-the-counter products, supplements, or herbal remedies.',
      ],
    },
    q4: 'How should I use 4 in 1 Gel?',
    a4: [
      'Apply the gel exactly as directed by your healthcare provider.',
      'Use on clean, dry scalp areas.',
      'Avoid contact with eyes or mucous membranes.',
      'Wash your hands thoroughly after application.',
    ],
    q5: 'What are the possible side effects of 4 in 1 Gel?',
    a5: {
      head: 'Due to decreased systemic absorption of topical dutasteride, the likelihood of side effects is lower compared to oral dutasteride. However, potential side effects include:\n',
      common: 'Common side effects include:',
      rare: 'Rare but serious side effects (less likely than with oral dutasteride):',
      bullets: {
        first: ['Scalp irritation, dryness, or redness.', 'Temporary hair shedding.'],
        second: [
          'Allergic reactions such as rash, itching, or swelling.',
          'Hormonal side effects such as decreased libido or breast tenderness.',
        ],
      },
      foot: 'Seek medical attention if you experience these or any unusual symptoms.',
    },
    q6: 'What else should I know about 4 in 1 Gel?',
    a6: [
      'Avoid exposing the treated area to sunlight until it is dry.',
      'Do not use more product than prescribed, as this will not improve results and may increase side effects.',
      'Regular follow-ups may be necessary to monitor your progress and response to treatment.',
    ],
  },
  oral_minoxidil: {
    productHeader: 'Oral Minoxidil',
    q1: 'What is Oral Minoxidil?',
    a1: 'Oral minoxidil is a prescription medication used off-label to treat hair loss by promoting hair growth.',
    q2: 'Who should not take Oral Minoxidil?',
    a2: {
      head: 'Do not take Oral Minoxidil if you:',
      bullets: [
        'Are allergic to minoxidil or its components.',
        'Have untreated or poorly controlled high blood pressure.',
        'Are pregnant or breastfeeding.',
      ],
    },
    q3: 'What should I tell my healthcare provider before taking Oral Minoxidil?',
    a3: {
      head: 'Before taking Oral Minoxidil, tell your provider if you:',
      bullets: [
        'Have a history of heart disease or arrhythmias.',
        'Have kidney or liver problems.',
        'Are taking other medications or supplements.',
      ],
    },
    q4: 'How should I take Oral Minoxidil?',
    a4: [
      'Take as prescribed by your healthcare provider, with or without food.',
      'Do not adjust your dose without consulting your provider.',
    ],
    q5: 'What are the possible side effects of Oral Minoxidil?',
    a5: {
      head: '',
      common: 'Common side effects include:',
      rare: 'Serious side effects include:',
      bullets: {
        first: ['Water retention or swelling in extremities.', 'Increased hair growth in unintended areas.'],
        second: ['Rapid heart rate.', 'Signs of fluid overload, such as shortness of breath.'],
      },
      foot: 'Seek medical attention for any severe side effects.',
    },
  },
  oral_dutasteride: {
    productHeader: 'Oral Dutasteride',
    q1: 'What is Oral Dutasteride?',
    a1: 'Oral Dutasteride is a prescription medication used off-label to reduce hair loss by blocking DHT, a key hormone contributing to androgenetic alopecia.',
    q2: 'Who should not take Oral Dutasteride?',
    a2: {
      head: 'Do not take Oral Dutasteride if you:',
      bullets: [
        'Are allergic to dutasteride or related medications.',
        'Are pregnant, breastfeeding, or planning to become pregnant.',
        'Have severe liver disease.',
      ],
    },
    q3: 'What should I tell my healthcare provider before taking Oral Dutasteride?',
    a3: {
      head: 'Before taking Oral Dutasteride, tell your provider if you:',
      bullets: ['Have a history of liver problems or allergies.', 'Take any other medications or supplements.'],
    },
    q4: 'How should I take Oral Dutasteride?',
    a4: [
      'Take exactly as prescribed, with or without food.',
      'Do not skip doses or stop without consulting your provider.',
    ],
    q5: 'What are the possible side effects of Oral Dutasteride?',
    a5: {
      head: '',
      common: 'Common side effects include:',
      rare: 'Serious side effects include:',
      bullets: {
        first: ['Reduced libido.', 'Erectile dysfunction.'],
        second: [
          'Breast tenderness or lumps.',
          'Signs of an allergic reaction, such as swelling or difficulty breathing.',
        ],
      },
      foot: 'Seek medical care for severe or concerning symptoms.',
    },
    q6: 'What else should I know about Oral Dutasteride?',
    a6: ['Regular follow-up appointments may be needed to monitor your response.'],
  },
  oral_finasteride: {
    productHeader: 'Oral Finasteride',
    q1: 'What is Oral Finasteride?',
    a1: 'Oral finasteride is a prescription medication used to treat androgenetic alopecia (male pattern hair loss) by reducing levels of dihydrotestosterone (DHT), a hormone that contributes to hair loss.',
    q2: 'Who should not take Oral Finasteride?',
    a2: {
      head: 'Do not take Oral Finasteride if you:',
      bullets: [
        'Are allergic to finasteride or its ingredients.',
        'Are pregnant, breastfeeding, or planning to become pregnant (exposure to the medication can cause harm to a developing fetus).',
        'Have severe liver disease.',
      ],
    },
    q3: 'What should I tell my healthcare provider before taking Oral Finasteride?',
    a3: {
      head: 'Before taking Oral Finasteride, tell your provider if you:',
      bullets: [
        'Have a history of liver problems.',
        'Have prostate conditions or elevated PSA levels (finasteride may reduce PSA levels).',
        'Are planning to donate blood, as you should wait at least one month after stopping finasteride.',
        'Are taking other medications, supplements, or herbal remedies.',
      ],
    },
    q4: 'How should I take Oral Finasteride?',
    a4: [
      'Take exactly as prescribed, once daily, with or without food.',
      'Do not skip doses or stop taking without consulting your provider.',
    ],
    q5: 'What are the possible side effects of Oral Finasteride?',
    a5: {
      head: '',
      common: 'Common side effects include:',
      rare: 'Rare but serious side effects include:',
      bullets: {
        first: ['Decreased libido.', 'Erectile dysfunction.', 'Reduced semen volume.'],
        second: [
          'Breast tenderness or enlargement.',
          'Depression or mood changes.',
          'Allergic reactions such as rash or swelling.',
        ],
      },
      foot: 'Seek medical attention if you experience serious or persistent side effects.',
    },
    q6: 'What else should I know about Oral Finasteride?',
    a6: [
      'Finasteride can harm a developing fetus; pregnant women should not handle crushed or broken tablets.',
      'Regular follow-up visits may be necessary to monitor your response and side effects.',
      'Do not use this medication for conditions other than prescribed.',
    ],
    q7: 'How should I store Oral Finasteride?',
    a7: ['Store at room temperature away from moisture and heat.', 'Keep out of reach of children and pets.'],
  },
  topical_minoxidil: {
    productHeader: 'Minoxidil+ Gel',
    q1: 'What is Minoxidil+?',
    a1: 'Minoxidil+ is a topical hair product that contains minoxidil, tretinoin, fexofenadine, melatonin, and caffeine to stimulate hair growth and improve scalp health.',
    q2: 'Who should not use Minoxidil+?',
    a2: {
      head: 'Do not use Minoxidil+ if you:',
      bullets: [
        'Are allergic to any of its ingredients.',
        'Have a scalp condition, such as psoriasis or eczema, without consulting your doctor.',
        'Are under 18 years of age.',
      ],
    },
    q3: 'What should I tell my healthcare provider before using Minoxidil+?',
    a3: {
      head: 'Before using Minoxidil+, tell your healthcare provider if you:',
      bullets: [
        'Have heart disease or high blood pressure.',
        'Are taking oral minoxidil or other hair loss treatments.',
        'Are pregnant or breastfeeding.',
        'Use any prescription or over-the-counter medications.',
      ],
    },
    q4: 'How should I use Minoxidil+?',
    a4: [
      'Apply the product to the affected scalp area as directed by your provider.',
      'Avoid contact with eyes or broken skin.',
      'Do not use more than prescribed to avoid increased side effects.',
    ],
    q5: 'What are the possible side effects of Minoxidil+?',
    a5: {
      head: '',
      common: 'Common side effects include:',
      rare: 'Serious side effects include:',
      bullets: {
        first: ['Itchy or dry scalp.', 'Temporary increase in hair shedding.'],
        second: ['Allergic reactions such as rash or swelling.', 'Chest pain or rapid heartbeat.'],
      },
      foot: 'Contact your healthcare provider if you experience these or other concerning symptoms.',
    },
    q6: 'What else should I know about Minoxidil+?',
    a6: [
      "Avoid using other scalp treatments simultaneously without your provider's approval.",
      'Follow-up visits may be needed to assess your response to treatment.',
    ],
  },
  topical_dutasteride: {
    productHeader: 'Dutasteride+ Gel',
    q1: 'What is Dutasteride+?',
    a1: 'Dutasteride+ is a topical formulation for hair loss containing dutasteride, tretinoin, fexofenadine, melatonin, and caffeine to support hair regrowth and scalp health.',
    q2: 'Who should not use Dutasteride+?',
    a2: {
      head: 'Do not use Dutasteride+ if you:',
      bullets: [
        'Are allergic to any of the ingredients.',
        'Are pregnant, breastfeeding, or planning to become pregnant (handling the product may carry risks).',
        'Have skin conditions or infections in the application area.',
        'Are under 18 years of age.',
      ],
    },
    q3: 'What should I tell my healthcare provider before using Dutasteride+?',
    a3: {
      head: 'Before using Dutasteride+, tell your healthcare provider if you:',
      bullets: [
        'Have skin sensitivities or known allergies.',
        'Are taking oral dutasteride or other hair loss treatments.',
        'Are pregnant or breastfeeding, as this product contains dutasteride, which may harm the fetus or infant if absorbed.',
        'Have liver disease or other chronic conditions.',
        'Use any other prescription or over-the-counter medications.',
      ],
    },
    q4: 'How should I use Dutasteride+?',
    a4: [
      "Follow your healthcare provider's instructions carefully.",
      'Apply to clean, dry scalp areas once daily.',
      'Avoid contact with eyes, mouth, or broken skin.',
    ],
    q5: 'What are the possible side effects of Dutasteride+?',
    a5: {
      head: 'Due to decreased systemic absorption, side effects are less common than with oral dutasteride.',
      common: 'Common side effects include:',
      rare: 'Rare but serious side effects (less likely than with oral dutasteride):',
      bullets: {
        first: ['Mild scalp irritation.', 'Temporary hair shedding.'],
        second: [
          'Allergic reactions such as rash or swelling.',
          'Hormonal side effects such as decreased libido, breast tenderness, or changes in mood.',
        ],
      },
      foot: 'Seek immediate medical care for any severe or concerning symptoms.',
    },
    q6: 'What else should I know about Dutasteride+?',
    a6: [
      'Avoid allowing others to come into contact with the treated area before it is dry.',
      'Do not exceed the recommended dose or frequency of use.',
    ],
  },
};

export const WarriorSafetyInfo = ({ sub_product }) => {
  if (
    sub_product === 'oral_dutasteride_oral_minoxidil' ||
    sub_product === 'oral_minoxidil_topical_dutasteride' ||
    sub_product === 'oral_dutasteride_topical_minoxidil'
  ) {
    const firstUnderscore = sub_product.indexOf('_');
    const secondUnderscore = sub_product.indexOf('_', firstUnderscore + 1);
    const firstPart = sub_product.slice(0, secondUnderscore);
    const secondPart = sub_product.slice(secondUnderscore + 1);
    return (
      <>
        <WarriorSafetyComponent subProductHash={WarriorSafetyDetails[firstPart]} />
        <WarriorSafetyComponent subProductHash={WarriorSafetyDetails[secondPart]} />
      </>
    );
  } else {
    const subProductHash = WarriorSafetyDetails[sub_product];
    return <WarriorSafetyComponent subProductHash={subProductHash} />;
  }
};

const WarriorSafetyComponent = ({ subProductHash }) => {
  const newVersion = useRedesign();

  return newVersion ? (
    <>
      <p className="underline mb-4">{subProductHash.productHeader}</p>

      <p className="bold">{subProductHash.q1}</p>
      <p className="mb12">{subProductHash.a1}</p>
      <p className="bold">{subProductHash.q2}</p>
      <p className="mb12">
        <p className="mb12">{subProductHash.a2.head}</p>
        <ul className="ml-5" style={{ listStyle: 'disc' }}>
          {subProductHash.a2.bullets.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </p>

      <p className="bold">{subProductHash.q3}</p>
      <p className="mb12">
        <p className="mb12">{subProductHash.a3.head}</p>
        <ul className="ml-5" style={{ listStyle: 'disc' }}>
          {subProductHash.a3.bullets.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </p>

      <p className="bold mb12">{subProductHash.q4}</p>
      <p className="mb12">
        <ul className="ml-5" style={{ listStyle: 'disc' }}>
          {subProductHash.a4.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </p>

      <p className="bold">{subProductHash.q5}</p>
      <p className="mb12">
        <p className="mb12">{subProductHash.a5.head}</p>
        <p className="mb12">{subProductHash.a5.common}</p>
        <ul className="ml-5" style={{ listStyle: 'disc' }}>
          {subProductHash.a5.bullets.first.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
        <p className="mb12">{subProductHash.a5.rare}</p>
        <ul className="ml-5" style={{ listStyle: 'disc' }}>
          {subProductHash.a5.bullets.second.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
        {subProductHash.a5.foot}
      </p>
      {Object.keys(subProductHash).length >= 13 && (
        <>
          <p className="bold mb12">{subProductHash.q6}</p>
          <p className="mb12">
            <ul className="ml-5" style={{ listStyle: 'disc' }}>
              {subProductHash.a6.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </p>
        </>
      )}
      {Object.keys(subProductHash).length >= 15 && (
        <>
          <p className="bold mb12">{subProductHash.q7}</p>
          <p className="mb12">
            <ul className="ml-5" style={{ listStyle: 'disc' }}>
              {subProductHash.a7.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </p>
        </>
      )}
    </>
  ) : (
    <>
      <h5 className="subtitle mb12">{subProductHash.productHeader}</h5>

      <P className="bold">{subProductHash.q1}</P>
      <P className="mb12">{subProductHash.a1}</P>
      <P className="bold">{subProductHash.q2}</P>
      <P className="mb12">
        <P className="mb12">{subProductHash.a2.head}</P>
        <ul className="modal-list">
          {subProductHash.a2.bullets.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </P>

      <P className="bold">{subProductHash.q3}</P>
      <P className="mb12">
        <P className="mb12">{subProductHash.a3.head}</P>
        <ul className="modal-list">
          {subProductHash.a3.bullets.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </P>

      <P className="bold mb12">{subProductHash.q4}</P>
      <P className="mb12">
        <ul className="modal-list">
          {subProductHash.a4.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </P>

      <P className="bold">{subProductHash.q5}</P>
      <P className="mb12">
        <P className="mb12">{subProductHash.a5.head}</P>
        <P className="mb12">{subProductHash.a5.common}</P>
        <ul className="modal-list mb12">
          {subProductHash.a5.bullets.first.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
        <P className="mb12">{subProductHash.a5.rare}</P>
        <ul className="modal-list mb12">
          {subProductHash.a5.bullets.second.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
        {subProductHash.a5.foot}
      </P>
      {Object.keys(subProductHash).length >= 13 && (
        <>
          <P className="bold mb12">{subProductHash.q6}</P>
          <P className="mb12">
            <ul className="modal-list">
              {subProductHash.a6.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </P>
        </>
      )}
      {Object.keys(subProductHash).length >= 15 && (
        <>
          <P className="bold mb12">{subProductHash.q7}</P>
          <P className="mb12">
            <ul className="modal-list">
              {subProductHash.a7.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </P>
        </>
      )}
    </>
  );
};
