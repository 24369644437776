import React from 'react';
import Card from '@setproduct-ui/core/Card';
import { useField } from 'formik';
import Input from 'app/components/common/Input';
import { IconButton } from 'app/components/common/Button';
import './css/Numeric.scss';
import { useRedesign } from '../../../utils/redesign/RedesignProvider';
import { FormField } from 'mui';

const convertToFixed = (value) => {
  if (value < 0) {
    return '0';
  }

  const newValue = String(value)
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1');
  const regExpFixed = newValue.match(/^([0-9]+)[.]([0-9]+)$/);
  if (regExpFixed && regExpFixed[2].length > 2) {
    return parseFloat(newValue).toFixed(2);
  }

  return newValue;
};

export const NumericField = ({ value, onChange, label, className, unit }) => {
  const numeric = Number(value);

  const newVersion = useRedesign();

  return newVersion ? (
    <FormField
      value={numeric === 0 ? '' : String(value)}
      onChange={(e) => onChange(e.target.value)}
      label={label}
      testid="numeric-field"
      type="number"
      placeholder={numeric === 0 ? '0' : 'Enter number'}
      insideElement={unit}
    />
  ) : (
    <div className={`numeric-card mt16 mh16 ${className}`}>
      <Card>
        <div className="top-level">
          <div className="down-button">
            <IconButton className="clickable mh24" down onClick={() => onChange(convertToFixed(numeric - 1))} />
          </div>
          <Input
            className="center-align"
            value={String(value)}
            onChange={(event) => {
              let { value } = event.target;
              if (value) {
                value = convertToFixed(value);
              }
              onChange(value);
            }}
            inputMode="numeric"
            testId="numeric-field"
          />
          <div className="up-button">
            <IconButton
              className="clickable mh24"
              onClick={() => onChange(convertToFixed(numeric + 1))}
              testId="up-arrow"
            />
          </div>
        </div>
        <div className="center-align">{label}</div>
      </Card>
    </div>
  );
};

const NumericQuestion = ({ question }) => {
  const {
    name,
    data: { min, max, label, unit },
  } = question;
  const [{ value }, , { setValue }] = useField({ id: name, name });

  return (
    <div key={name}>
      <NumericField min={min} max={max} onChange={setValue} value={value} label={label} unit={unit} />
    </div>
  );
};

export default NumericQuestion;
