import React, { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { RedesignControl } from 'app/utils/redesign/RedesignControl';
import { isStagingOrLower } from 'app/helpers/env';
import { useAppSelector } from 'app/helpers/hooks';
import { selectAuthentication } from 'app/selectors/customer';
import { getAmplitudeExperiment } from 'app/utils/amplitudeExperiments';
import { useExperiment } from 'app/utils/useExperiment';

const RedesignContext = createContext(false);
const ToggleThemeContext = createContext((_value) => {});

const DISABLED_PATHS = ['admin', 'login'];

export const applyRedesignExperiment = (callback) => {
  const toggleTheme = useThemeToggle();
  const brandUpdateExperimentId = 'testosterone_brand_update';
  useLayoutEffect(() => {
    getAmplitudeExperiment(brandUpdateExperimentId, (variant) => {
      toggleTheme(variant.key === 'testosterone_brand_update_variation_1');
      callback();
    });
  }, []);
};

export const RedesignProvider = ({ children }) => {
  const key = 'Maximus2024DesignVersion';
  const newVersionAssigned =
    useExperiment('testosterone_brand_update', 'testosterone_brand_update_variation_0') ===
    'testosterone_brand_update_variation_1';
  const [isNewVersion, setIsNewVersion] = useState(() => {
    const savedFlag = localStorage.getItem(key);
    const disabled = DISABLED_PATHS.some((str) => window.location.pathname.includes(str));
    return (savedFlag === 'true' && !disabled) || newVersionAssigned;
  });

  useEffect(() => {
    const element = document.querySelector('html');

    element?.classList?.toggle('old-theme', !isNewVersion);
    element?.classList?.toggle('new-theme', isNewVersion);
  }, [isNewVersion]);

  const toggleTheme = (value) => {
    setIsNewVersion(value);
    localStorage.setItem(key, value.toString());
  };

  const authentication = useAppSelector(selectAuthentication);
  const isAdmin = authentication === 'admin';
  const showRedesignControl = !isAdmin && isStagingOrLower();

  return (
    <ToggleThemeContext.Provider value={toggleTheme}>
      <RedesignContext.Provider value={isNewVersion}>
        {showRedesignControl && <RedesignControl onChange={toggleTheme} />}
        {children}
      </RedesignContext.Provider>
    </ToggleThemeContext.Provider>
  );
};

export const useRedesign = () => useContext(RedesignContext);
export const useThemeToggle = () => useContext(ToggleThemeContext);
