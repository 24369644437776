import * as Braze from '@braze/web-sdk';

export const trackEvent = (eventName: string, properties?: Record<string, any>) => {
  if (window.brazeSdkApiKey && window.brazeSdkHost) {
    Braze.logCustomEvent(eventName, {
      ...properties,
      timestamp: new Date().toISOString(),
    });
  }
};
