import React, { useMemo } from 'react';
import cx from 'classnames';
import Scroller from 'react-custom-scroller';
import Select from 'react-select';
import { FormSelect } from 'mui';
import Question from 'app/constants/Question';
import { P } from 'app/components/common/Typography';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import './css/Dropdown.scss';

const MenuListComponent = ({ maxHeight, children }) => (
  <Scroller style={{ height: maxHeight }} className="scroller">
    {children}
  </Scroller>
);
const MenuList = React.memo(MenuListComponent);

const Dropdown = ({
  name,
  value,
  onClick,
  className,
  emptyValue,
  showLabel = true,
  isSearchable = true,
  size = 'medium',
  disabled,
  error,
  success,
  errorHelperText,
  ...restProps
}) => {
  const { options, label: defaultLabel } = useMemo(() => new Question(name), [name]);
  const opt = options?.find((opt) => opt.value === value);

  const newVersion = useRedesign();
  return newVersion ? (
    <FormSelect
      {...restProps}
      name={name}
      options={options}
      value={opt}
      onChange={onClick}
      className={className}
      placeholder={emptyValue}
      labelHidden={!showLabel}
      isSearchable={isSearchable}
      disabled={disabled}
      error={errorHelperText}
      valid={!error}
      label={defaultLabel}
    />
  ) : (
    <div className="dropdown-wrapper" style={{ position: 'relative' }} data-testid={restProps.testId}>
      {showLabel && (
        <label style={{ marginBottom: '2px' }}>
          <P colorStep={50}>{defaultLabel}</P>
        </label>
      )}
      <Select
        className={cx(className, 'input', size, { dropdown__error: error }, { dropdown__success: success })}
        classNamePrefix="select"
        onChange={onClick}
        options={options || []}
        placeholder={emptyValue}
        value={opt}
        isDisabled={disabled}
        isSearchable={isSearchable}
        components={{ MenuList }}
      />
      {errorHelperText && error && <div className="dropdown__helper_text">{errorHelperText}</div>}
    </div>
  );
};

export default Dropdown;
