import React from 'react';
import { Button } from 'mui';
import WhiteButton from 'app/components/common/WhiteButton';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { P } from 'app/components/common/Typography';

const ModalBodySubscription = ({ setOpenModalSubscription, subscriptionPeriodDays = 30 }) => {
  const newVersion = useRedesign();

  return newVersion ? (
    <>
      <p>
        Your subscription is billed every {subscriptionPeriodDays} days and covers the cost of licensed doctor
        consultations, licensed psychologist-led coaching, and medications (if qualified).
      </p>
      <p>
        Your subscription will automatically renew every {subscriptionPeriodDays} days until you a cancel. You may
        cancel your subscription at any time by visiting the Settings page.
      </p>
      <p>
        Maximus may change the subscription price from time to time, which will take effect at the start of your next
        subscription period. You have the right to reject any price change by canceling your subscription prior to the
        change. Your cancelation will go into effect at the next billing cycle as long as you have paid for all
        medication received.
      </p>
      <p>
        For more information, read our{' '}
        <a href="/terms-of-use" target="_blank">
          Terms of Use
        </a>
        .
      </p>
      <hr />
      <Button onClick={() => setOpenModalSubscription(false)}>Close</Button>
    </>
  ) : (
    <>
      <P className="mb12">
        Your subscription is billed every <span className="bold">{subscriptionPeriodDays} days</span> and covers the
        cost of licensed doctor consultations, licensed psychologist-led coaching, and medications (if qualified).
      </P>

      <P className="mb12">
        Your subscription will automatically renew every <span className="bold">{subscriptionPeriodDays} days</span>{' '}
        until you a cancel. You may cancel your subscription at any time by visiting the Settings page.
      </P>

      <P className="mb12">
        Maximus may change the subscription price from time to time, which will take effect at the start of your next
        subscription period. You have the right to reject any price change by canceling your subscription prior to the
        change. Your cancelation will go into effect at the next billing cycle as long as you have paid for all
        medication received.
      </P>

      <P className="mb24">
        For more information, read our{' '}
        <a href="/terms-of-use" target="_blank">
          Terms of Use
        </a>
        .
      </P>

      <WhiteButton text="Close" onClick={() => setOpenModalSubscription(false)} />
    </>
  );
};

export default ModalBodySubscription;
