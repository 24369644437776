import React, { useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import { useAppDispatch } from 'app/helpers/hooks';
import { DangerDialog } from 'app/components/common/Dialog';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import * as Routes from 'app/constants/Routes';
import { push } from 'connected-react-router/immutable';

import BillingTileImgSrc from 'images/account-settings/img-1.png';
import CancelTileImgSrc from 'images/account-settings/img-2.png';
import DeleteTileImgSrc from 'images/account-settings/img-3.png';
import PasswordUpdateTileImgSrc from 'images/account-settings/img-4.png';

import TitleWithLine from 'app/components/common/Typography/TitleWithLine';

import './css/Settings.scss';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Button, Card, CardBody, Modal, PageWrapper } from 'mui';
import DumbShippingCard from './steps/Payment/DumbShippingCard';
import { ShippingSchemaWithoutFullName } from '../../helpers/validators';
import { Formik } from 'formik';
import { useAppSelector } from '../../helpers/hooks';
import { selectCustomerShippingAddress } from '../../selectors/customer';
import DumbPaymentMethodNew from './steps/Payment/DumbPaymentMethodNew';
import PaymentContextProvider from './steps/Payment/PaymentContext/provider';
import { PasswordPreferencesForm } from './dashboard/PasswordPreferences';

const mapStateToProps = (state) => {
  const customerId = selectors.selectCustomerId(state);
  const deleteContext = {
    onSuccessAction: actions.hardRedirectRequested('/goodbye'),
  };
  const uncancelParams = {
    type: 'uncancel_account',
    user_id: customerId,
    params: {},
  };
  const deleteAction = actions.apiRequestUserCommand({
    params: {},
    cmdType: 'delete_customer',
    context: deleteContext,
  });
  const uncancelAction = actions.apiRequestCommand({
    params: uncancelParams,
    context: {},
  });
  const paymentErrorMessage = selectors.selectLatestPaymentMethodErrorMessage(state);

  return {
    deleteAction,
    uncancelAction,
    paymentErrorMessage,
    showShippingBillingSettings: !!selectors.selectCustomerShippingAddress(state),
  };
};

const mapDispatchToProps = {
  redirect: push,
};

const DELETE_TITLE = 'Delete Account';
const DELETE_TEXT = 'Are you sure you want to delete your account?';
const Settings = ({ deleteAction, redirect, paymentErrorMessage, showShippingBillingSettings }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openPasswordChangeModal, setOpenPasswordChangeModal] = useState(false);

  const dispatch = useAppDispatch();
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const toggleDeleteModal = () => {
    setOpenDelete(!openDelete);
  };
  const togglePasswordChangeModal = () => {
    setOpenPasswordChangeModal(!openPasswordChangeModal);
  };

  const handleDeleteClick = () => {
    setDeleteButtonDisabled(true);
    dispatch(deleteAction);
  };

  const newVersion = useRedesign();
  const shippingAddress = useAppSelector(selectCustomerShippingAddress)?.toJS() || {};
  const { city = '', address_line_1 = '', address_line_2 = '', state = '', postal_code = '' } = shippingAddress;
  const initialValues = {
    ...shippingAddress,
    address_line_1,
    address_line_2,
    city,
    state,
    postal_code,
  };

  delete initialValues.full_name;

  return newVersion ? (
    <PageWrapper withDesktop={false}>
      <div className={'flex flex-col gap-6'}>
        <h3 className="ml-10 sm:ml-5">Account Settings</h3>
        {shippingAddress && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={() => {}}
            validationSchema={ShippingSchemaWithoutFullName}
          >
            <DumbShippingCard />
          </Formik>
        )}
        <Card>
          <CardBody>
            <h4>Payment method</h4>
            <PaymentContextProvider>
              <DumbPaymentMethodNew />
            </PaymentContextProvider>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4>Account</h4>
            <Button variant={'outline'} onClick={() => redirect(Routes.ManageSubscription)}>
              Manage Subscriptions
            </Button>
            <Button variant={'outline'} onClick={togglePasswordChangeModal}>
              Change Password
            </Button>
            <Button variant={'text'} onClick={toggleDeleteModal}>
              Delete Account
            </Button>
            <DangerDialog
              isOpen={openDelete}
              title={DELETE_TITLE}
              text={DELETE_TEXT}
              leftDisabled={deleteButtonDisabled}
              onClose={toggleDeleteModal}
              leftText="Delete"
              leftClick={handleDeleteClick}
              rightText="Cancel"
              rightClick={toggleDeleteModal}
              leftButton
              rightButton
            />
            <Modal isOpen={openPasswordChangeModal} onClose={togglePasswordChangeModal} showCloseIcon={true}>
              <PasswordPreferencesForm customOnCancel={togglePasswordChangeModal} />
            </Modal>
            <Modal isOpen={openDelete} onClose={toggleDeleteModal} showCloseIcon={false}>
              <Modal.Header>
                <h3 className="lg:hidden">Delete Account</h3>
                <h5 className="hidden lg:block lg:visible">Delete Account</h5>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                <Button variant={'primary'} color="danger" onClick={handleDeleteClick}>
                  Delete
                </Button>
                <Button variant={'outline'} onClick={toggleDeleteModal}>
                  Cancel
                </Button>
              </Modal.Body>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </PageWrapper>
  ) : (
    <div className="account-settings flex-column">
      <div className="bg-gray" />
      <div className="pt52 pb52">
        <DangerDialog
          canOutsideClickClose={false}
          isOpen={openDelete}
          title={DELETE_TITLE}
          text={DELETE_TEXT}
          leftDisabled={deleteButtonDisabled}
          onClose={toggleDeleteModal}
          leftText="Delete"
          leftClick={handleDeleteClick}
          rightText="Cancel"
          rightClick={toggleDeleteModal}
          leftButton
          rightButton
        />
        <div className="tiles-container">
          {showShippingBillingSettings && (
            <div>
              <TitleWithLine>Billing & Shipping Information</TitleWithLine>
              <div className={cx('tile', { tile_notification: !!paymentErrorMessage })}>
                <img src={BillingTileImgSrc} />
                <div className="ml12">
                  <div className="tile__contents ml12">
                    Update your payment method, billing address, and shipping address.
                  </div>
                  <button
                    type="button"
                    className="default-button primary-button ml12"
                    onClick={() => dispatch(push(Routes.BillingSettings))}
                  >
                    Update Billing & Shipping
                  </button>
                </div>
              </div>
            </div>
          )}
          <div>
            <TitleWithLine className="orange">Manage Subscriptions</TitleWithLine>
            <div className="tile">
              <img src={CancelTileImgSrc} />
              <div className="ml12">
                <div className="tile__contents ml12">Review and manage your Maximus subscriptions.</div>
                <button
                  type="button"
                  className="default-button warning-button ml12"
                  onClick={() => redirect(Routes.ManageSubscription)}
                >
                  Manage Subscriptions
                </button>
              </div>
            </div>
          </div>
          <div>
            <TitleWithLine className="red">Delete Account</TitleWithLine>
            <div className="tile">
              <img src={DeleteTileImgSrc} />
              <div className="ml12">
                <div className="tile__contents ml12">
                  If you delete your account, you will need to create a new account should you return. No information
                  from your previous account will be available.
                </div>
                <button type="button" className="default-button danger-button ml12" onClick={toggleDeleteModal}>
                  Delete Account
                </button>
              </div>
            </div>
          </div>

          <div>
            <TitleWithLine className="pink">Update Password</TitleWithLine>
            <div className="tile">
              <img src={PasswordUpdateTileImgSrc} />
              <div className="ml12">
                <div className="tile__contents ml12">Update your password for future logins to the Maximus portal.</div>
                <button
                  type="button"
                  className="default-button pink-button ml12"
                  onClick={() => dispatch(push(Routes.PasswordSettings))}
                >
                  Update Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
