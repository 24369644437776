import React from 'react';

import './css/checkpoint.scss';

import { connect } from 'react-redux';

import { H6, H4, P } from 'app/components/common/Typography';
import { PrimaryButton } from 'app/components/common/Button';
import { push } from 'connected-react-router/immutable';
import * as selectors from 'app/selectors/customer';

import checkGray from 'images/payment/check-gray.svg';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Button, Card, Heading, IconList, PageWrapper } from 'mui';
import UserCircleIcon from 'images/icons/user_circle.svg';
import UserPlusIcon from 'images/icons/user_plus.svg';

const mapStateToProps = (state) => {
  const nextStepAction = push(selectors.selectNextStepPath(state));

  return {
    nextStepAction,
  };
};

const Checkpoint = ({ nextStepAction, dispatch }) => {
  const newVersion = useRedesign();

  return newVersion ? (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <div>
        <Heading headingLevel="p" label>
          Thank you
        </Heading>
        <h3>Your Order #3214</h3>
        <span className="mt-2">Almost Done!</span>
      </div>
      <Card>
        <Card.Body>
          <h5>Two quick steps to finish</h5>
          <IconList
            items={[
              {
                icon: () => <img src={UserCircleIcon} />,
                title: (
                  <div>
                    Verify ID and location <span className={'text-main-danger font-normal'}>(required)</span>
                  </div>
                ),
                text: 'Please have your government issued ID ready. We are required to verify that we can provide high quality care in your area.',
              },
              {
                icon: () => <img src={UserPlusIcon} />,
                title: (
                  <div>
                    Leave a note for your doctor <span className={'text-main-danger font-normal'}>(required)</span>
                  </div>
                ),
                text: 'This is your opportunity to start a conversation with your doctor so they can address any questions or concerns you may have.',
              },
            ]}
          />
          <Button onClick={() => dispatch(nextStepAction)}>Finish Up</Button>
        </Card.Body>
      </Card>
    </PageWrapper>
  ) : (
    <>
      <H6 className="mt16">
        <P color="primary" className="font-size-inherit font-weight-inherit">
          THANK YOU!
        </P>
      </H6>
      <H4 className="checkpoint__title">Payment Complete.</H4>
      <P className="mt8">Before we can send your information to the doctor, we first need verify your identity. </P>
      <div className="checkpoint__background">
        <div className="checkpoint__background__item">
          <div>
            <img src={checkGray} />
          </div>
          <div>
            <P className="checkpoint__background__title">
              <span className="bold">Pharmacy Confirmation</span> <span className="required">(required)</span>
            </P>
            <P>Verify pharmacy selection.</P>
          </div>
        </div>
        <div className="checkpoint__background__item">
          <div>
            <img src={checkGray} />
          </div>
          <div>
            <P className="checkpoint__background__title">
              <span className="bold">ID Verification</span> <span className="required">(required)</span>
            </P>
            <P>Please have your government issued ID ready.</P>
          </div>
        </div>
        <div className="checkpoint__background__item">
          <div>
            <img src={checkGray} />
          </div>
          <div>
            <P className="checkpoint__background__title">
              <span className="bold">Location Verification</span> <span className="required">(required)</span>
            </P>
            <P>Double checking that we can provide high quality care in your area.</P>
          </div>
        </div>
        <div className="checkpoint__background__item">
          <div>
            <img src={checkGray} />
          </div>
          <div>
            <P className="checkpoint__background__title">
              <span className="bold">Add Additional Info</span> <span className="gray">(optional)</span>
            </P>
            <P>Have something to tell the doctor? </P>
          </div>
        </div>
        <PrimaryButton
          className="mt1224"
          type="button"
          data-testid="checkpoint_continue"
          text="Continue"
          onClick={() => dispatch(nextStepAction)}
        />
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Checkpoint);
