import React from 'react';
import { useField, useFormikContext } from 'formik';
import { FormField } from 'mui';
import CommonTextArea from '../TextArea';
import { useRedesign } from '../../../utils/redesign/RedesignProvider';

const TextArea = ({ id, name, ...restProps }) => {
  const [{ value }] = useField({ id, name });
  const { setFieldValue } = useFormikContext();
  const onChange = ({ target: { value } }) => setFieldValue(name, value);

  const newVersion = useRedesign();

  return newVersion ? (
    <FormField {...restProps} multiline inputClassName="min-h-[5rem]" testid={name} value={value} onChange={onChange} />
  ) : (
    <CommonTextArea {...restProps} onChange={onChange} value={value} testId={name} />
  );
};

export default TextArea;
