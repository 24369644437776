import React from 'react';

import { P } from 'app/components/common/Typography';
import { useFormikContext } from 'formik';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import nextBillingCycle from 'app/helpers/nextBillingCycle';
import KingSubProductsList from 'app/components/customer/steps/Payment/king/KingSubProductsList';
import KingSubProductScreen from 'app/components/customer/steps/Payment/king/KingSubProductScreen';
import { trackEvent } from 'app/utils/useCommonTracking';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import cx from 'classnames';

type Props = {
  firstTimeChoice?: boolean;
  availableSubProducts: any;
  handleSubmit: () => void;
};

const KingProductSelectionForm = ({ availableSubProducts, firstTimeChoice, handleSubmit }: Props) => {
  const { values } = useFormikContext<{
    selected_king_v2_product: string;
  }>();
  const nextBillingCycleStr = nextBillingCycle(AvailableProducts.King);
  const newVersion = useRedesign();

  const renderAlert = () => (
    <div
      className={cx({
        'product-selection-alert mb32': !newVersion,
        'mt-2': newVersion,
      })}
    >
      <P className="mb12">
        <b>NOTE:</b> Any changes you make to your product will take effect after your next billing cycle on{' '}
        <b>{nextBillingCycleStr}</b>.
      </P>
      <P>
        If you would like it sooner,{' '}
        <a target="_blank" className="underlined" href="mailto:support@maximustribe.com" rel="noreferrer">
          contact a Maximus concierge team member
        </a>{' '}
        to make a change to your current subscription.
      </P>
    </div>
  );

  const trackExploreOtherProtocols = () => {
    trackEvent('Clicked Explore Other Protocols', { protocol: 'king' });
  };

  const trackProtocolChosen = (product: string) => {
    try {
      trackEvent('Chose a Protocol', { protocol: 'king', product });
    } catch (error) {
      console.error('Error tracking protocol chosen', error);
    }
  };

  const renderBody = () => {
    const selectedProduct = values.selected_king_v2_product;

    if (
      !selectedProduct ||
      selectedProduct === KingV2SubProducts.EncloPregnolone ||
      selectedProduct === KingV2SubProducts.EncloPregnoloneTrt
    ) {
      return <KingSubProductsList availableSubProducts={availableSubProducts} onProtocolChosen={trackProtocolChosen} />;
    }

    return <KingSubProductScreen handleSubmit={handleSubmit} onExploreOtherProtocols={trackExploreOtherProtocols} />;
  };

  return (
    <>
      {!firstTimeChoice && renderAlert()}
      {renderBody()}
    </>
  );
};

export default KingProductSelectionForm;
