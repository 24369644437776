import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';
import Cookies from 'js-cookie';
import * as routerSelectors from 'app/selectors/router';
import Header from 'app/components/customer/Header';
import Footer from 'app/components/customer/Footer';
import FailureState from 'app/components/customer/dashboard/FailureState';
import { DangerDialog } from 'app/components/common/Dialog';
import { AvailableProducts } from 'app/constants/Products';
import BeenHereBefore from './Parts/BeenHereBefore';
import LoverRegistration from './LoverRegistration';
import KingRegistration from './KingRegistration';
import WarriorRegistration from './WarriorRegistration';
import MagicianRegistration from './MagicianRegistration';
import LabsRegistration from './LabsRegistration';
import './css/Registration.scss';
import { applyRedesignExperiment, useRedesign } from 'app/utils/redesign/RedesignProvider';
import DrawerMenu from 'app/components/common/DrawerMenu';
import { Drawer, NavBar } from 'mui';
import Spinner from 'app/components/customer/Spinner';

const mapStateToProps = (state) => {
  const feedbackParam = routerSelectors.selectRouterQuery(state).get('registration_feedback');
  const currentProduct = (() => {
    const product = routerSelectors.selectRouterQuery(state).get('product');

    if (product === 'labs') return product;

    return AvailableProducts[capitalize(product)] || AvailableProducts.King;
  })();
  const testMode = routerSelectors.selectRouterQuery(state).get('test_mode');

  return {
    feedbackParam,
    currentProduct,
    initialValues: {
      settings: {
        product: currentProduct,
        test_mode: testMode,
      },
      step0: {},
      step2: {
        state: '',
        pc_opt_in: false,
        mso_opt_in: false,
        over_18: false,
      },
      step3: {
        first_name: '',
        last_name: '',
      },
      step4: {
        email: window.emailFromTypeform,
        password: '',
        password_confirmation: '',
      },
    },
  };
};

const Registration = ({ feedbackParam, currentProduct }) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [themeApplied, setThemeApplied] = useState(false);
  applyRedesignExperiment(() => setThemeApplied(true));
  const newVersion = useRedesign();

  useEffect(() => {
    if (Cookies.get('email') && currentProduct !== 'labs') {
      setShowLoginModal(true);
    }
  }, []);

  if (feedbackParam) {
    return (
      <div className="grid-container">
        <Header style="black" />
        <main>
          <FailureState state={feedbackParam} currentProduct={currentProduct} />
        </main>
        <Footer />
      </div>
    );
  }

  const Step0 = {
    king: KingRegistration,
    lover: LoverRegistration,
    warrior: WarriorRegistration,
    magician: MagicianRegistration,
    labs: LabsRegistration,
  }[currentProduct];

  const content = (
    <>
      <DangerDialog
        isOpen={showLoginModal}
        className="cookie-email-modal"
        onClose={() => setShowLoginModal(false)}
        title="Been Here Before?"
        text={<BeenHereBefore setShowLoginModal={setShowLoginModal} />}
      />
      <Step0 />
    </>
  );
  if (!themeApplied)
    return (
      <div className="global-spinner">
        <Spinner isCenter={true} />
      </div>
    );

  return newVersion ? (
    <Drawer sideContent={<DrawerMenu loggedIn={false} />}>
      <NavBar />
      {content}
    </Drawer>
  ) : (
    <div className="grid-container">
      <Header style="black" />
      <main>
        <div className="centered">{content}</div>
      </main>
      <Footer />
    </div>
  );
};

export default connect(mapStateToProps)(Registration);
