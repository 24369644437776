import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import {
  selectCurrentIntake,
  selectCustomer,
  selectCustomerProduct,
  selectFurthestProductStepPath,
} from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { apiRequestUserCommand } from 'app/actions/customer';
import { Formik } from 'formik';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';
import DiscountBanner from '../DiscountBanner';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { PageWrapper } from 'mui';
import cx from 'classnames';
import RecommendationFlowSubProductsList from 'app/components/customer/steps/Payment/king/RecommendationFlowSubProductsList';
import { KingV2SubProducts } from 'app/constants/Products';
import PageHeader from 'app/components/common/PageHeader';
import RecommendationFlowSubProductScreen from 'app/components/customer/steps/Payment/king/RecommendationFlowSubProductScreen';
import RecommendationFlowAddon from 'app/components/customer/steps/Payment/king/RecommendationFlowAddon';
import { trackEvent } from 'app/utils/useCommonTracking';

const ProductRecommendationStep = () => {
  const newVersion = useRedesign();
  const intake = useAppSelector(selectCurrentIntake);
  const dispatch = useAppDispatch();
  const customer = useAppSelector(selectCustomer);
  const productName = intake.get('product_name');
  const product = useAppSelector((state) => selectCustomerProduct(state, productName));

  const [selectedBaseProduct, setSelectedBaseProduct] = useState<KingV2SubProducts | null>(null);

  const checkoutHelper = createCheckoutHelper(intake, customer, product, true);

  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectFurthestProductStepPath({ customer: stateAfterSuccess }, productName));

  const initialValues = {
    ...checkoutHelper.productSpecificInitialValues(),
    selected_king_v2_product: null,
  };
  const onSubmit = (params: any) => {
    const action = apiRequestUserCommand({
      cmdType: checkoutHelper.updateSubProductCommandName,
      params: { ...params, intake: intake.get('name') },
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  const renderHeader = (values) => {
    if (!selectedBaseProduct && !values.selected_king_v2_product) {
      return newVersion ? (
        <div className="ml-10 sm:ml-5">
          <h3>Choose your base protocol</h3>
        </div>
      ) : (
        <PageHeader title="Choose your base protocol" className="treatment_plan__header mb24 -edged" />
      );
    }
  };

  const renderCurrentStep = (values) => {
    if (!selectedBaseProduct && !values.selected_king_v2_product) {
      return (
        <RecommendationFlowSubProductsList
          availableSubProducts={intake.get('base_sub_products')}
          onProtocolChosen={(value) => {
            setSelectedBaseProduct(value);
            trackEvent('Viewed Protocol Deep Dive Page', { protocol: 'king', product: value });
          }}
        />
      );
    }

    if (!!selectedBaseProduct && !values.selected_king_v2_product) {
      return (
        <RecommendationFlowSubProductScreen
          subProductName={selectedBaseProduct}
          handleBack={() => setSelectedBaseProduct(null)}
        />
      );
    }

    if (values.selected_king_v2_product) return <RecommendationFlowAddon />;
  };

  useEffect(() => {
    trackEvent('Viewed Protocol Selector Page', { protocol: 'king' });
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values }) =>
        newVersion ? (
          <PageWrapper
            wrapperClassName={cx('flex flex-col gap-y-6 lg:gap-y-8', {
              'mb-60':
                checkoutHelper.recommendationSystemEnabled() && selectedBaseProduct && !values.selected_king_v2_product,
            })}
          >
            <DiscountBanner />
            {renderHeader(values)}
            {renderCurrentStep(values)}
          </PageWrapper>
        ) : (
          <div className="centered updated-design payments mb24">
            <DiscountBanner />
            {renderHeader(values)}
            <div className="content">{renderCurrentStep(values)}</div>
          </div>
        )
      }
    </Formik>
  );
};

export default ProductRecommendationStep;
