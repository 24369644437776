import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import ArrowIconRigth from 'images/arrow-icon-right.svg';
import ArrowIconLeft from 'images/arrow-icon-left.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.scss';

const CarouselBox = ({ items }) => {
  const prevArrowFunc = (onClickHandler, hasPrev, _label) => {
    return (
      hasPrev && (
        <button className="carousel__button carousel__button--left" onClick={onClickHandler}>
          <img src={ArrowIconLeft} />
        </button>
      )
    );
  };

  const nextArrowFunc = (onClickHandler, hasNext, _label) => {
    return (
      hasNext && (
        <button className="carousel__button carousel__button--right" onClick={onClickHandler}>
          <img src={ArrowIconRigth} />
        </button>
      )
    );
  };

  return (
    <Carousel
      centerMode={true}
      renderArrowPrev={prevArrowFunc}
      renderArrowNext={nextArrowFunc}
      showStatus={false}
      showThumbs={false}
    >
      {items.map((item) => item)}
    </Carousel>
  );
};

export default CarouselBox;
