import React from 'react';
import { H6 } from 'app/components/common/Typography';
import Input, { InputMask } from 'app/components/common/formik/Input';
import { ShippingFields } from 'app/components/customer/steps/Shipping';
import { PHONE_MASK, phoneDisplayFormatter } from 'app/helpers/formatters';
import { useRedesign } from '../../../../utils/redesign/RedesignProvider';

const PharmacyAddress = ({ choice }) => {
  if (choice !== 'other') {
    return null;
  }

  const newVersion = useRedesign();

  return (
    <div className="flex flex-col gap-y-2">
      <H6 colorStep={90} className="mt12 mb12">
        Pharmacy Info
      </H6>
      <div>
        <Input
          className="pharmacy-input"
          id="pharmacy_name"
          name="pharmacy_name"
          placeholder={newVersion ? 'Pharmacy' : 'Pharmacy Name'}
          label={newVersion ? 'Pharmacy Name' : null}
        />
      </div>
      <ShippingFields labels={newVersion} className="pharmacy-input" />
      <InputMask
        id="phone_number"
        name="phone_number"
        className=""
        displayFormatter={phoneDisplayFormatter}
        label={newVersion ? 'Phone Number' : null}
        mask={PHONE_MASK}
        placeholder={newVersion ? '(___) ___-___' : null}
      />
    </div>
  );
};

export default PharmacyAddress;
