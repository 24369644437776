import React from 'react';
import { P } from 'app/components/common/Typography';
import { CardCheckbox } from 'app/components/common/Checkbox';
import Card from '@setproduct-ui/core/Card';
import { useField, useFormikContext } from 'formik';
import mapValues from 'lodash/mapValues';
import './css/MultiSelect.scss';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { IntakeCheckbox, FormField } from 'mui';

export const CheckboxComponent = ({ onChange, checked, testId, label, sublabel = '', ...restProps }) => (
  <div className="checkbox-component">
    <CardCheckbox
      checked={checked}
      label={<P className="mv8 label">{label}</P>}
      testId={testId}
      helperText={
        sublabel && (
          <P small={false} type="caption" colorStep={50} className="mb8 sublabel">
            {sublabel}
          </P>
        )
      }
      onChange={onChange}
      {...restProps}
    />
  </div>
);

const MultiSelectQuestion = ({ question, showNoneOfTheAbove = true, showOther = true, ...restProps }) => {
  const { id, name, options } = question;
  const newVersion = useRedesign();

  const noneOfTheAboveText = question.data.none_of_the_above?.text;
  const noneOfTheAbovePosition = question.data.none_of_the_above?.position;
  const otherTextContent = question.data.other ? question.data.other?.text : 'Other (please list)';
  if (question.data.no_other) {
    showOther = false;
  }
  const [{ value: fieldVal }] = useField({ id, name });
  const value = fieldVal || {};
  const { setFieldValue, isSubmitting } = useFormikContext();
  const questionOptions = options.map((option, index) => {
    const checked = value[option.value];
    const onOptionChanged = () => {
      let newValues = value;
      if (option.no_other) {
        newValues = mapValues(value, (v) => (v === true ? false : v));
      }
      options
        .filter((o) => !!o.no_other)
        .forEach((v) => {
          newValues[v.value] = false;
        });
      const val = {
        ...newValues,
        [option.value]: !checked,
      };
      if (noneOfTheAboveText) {
        val.none_of_the_above = false;
      }

      setFieldValue(name, val);
    };

    return newVersion ? (
      <IntakeCheckbox
        key={index}
        label={[option.label, option.sublabel].join('\n')}
        onChange={onOptionChanged}
        checked={checked}
        testid={`checkbox_${option.value}`}
        disabled={isSubmitting}
        {...restProps}
      />
    ) : (
      <CheckboxComponent
        key={index}
        label={option.label}
        sublabel={option.sublabel}
        onChange={onOptionChanged}
        checked={checked}
        testId={`checkbox_${option.value}`}
        disabled={isSubmitting}
        {...restProps}
      />
    );
  });
  const otherClicked = () => {
    const nextOther = !value.other;
    const overrides = nextOther ? { none_of_the_above: false } : { other_content: '' };
    setFieldValue(name, { ...value, ...overrides, other: nextOther });
  };
  const noneOfTheAboveClicked = () => {
    const falseValues = mapValues(value, () => false);
    if (!question.data.no_other) {
      (falseValues.other_content as unknown) = '';
    }
    setFieldValue(name, {
      ...falseValues,
      none_of_the_above: !value.none_of_the_above,
    });
  };
  const textarea = newVersion ? (
    <FormField
      multiline
      inputClassName="min-h-[5rem]"
      testid="other-textarea"
      value={value.other_content}
      placeholder="Enter text here"
      onChange={({ target: { value: content } }) => {
        setFieldValue(name, { ...value, other_content: content });
      }}
    />
  ) : (
    <Card view="raised" className="checkbox-component multi-select-question__other_card">
      <textarea
        className="multi-select-question__other_textarea"
        style={{ width: '100%' }}
        onChange={({ target: { value: content } }) => {
          setFieldValue(name, { ...value, other_content: content });
        }}
        value={value.other_content}
        data-testid="other-textarea"
        placeholder="Let us know here..."
        maxLength={name === 'allergies' ? 255 : 500}
      />
    </Card>
  );

  const containerClassName = newVersion ? 'flex flex-col gap-4' : 'mb16';

  return (
    <div className={containerClassName}>
      {noneOfTheAbovePosition === 'top' &&
        showNoneOfTheAbove &&
        !!noneOfTheAboveText &&
        (newVersion ? (
          <IntakeCheckbox
            onChange={() => noneOfTheAboveClicked()}
            label={noneOfTheAboveText}
            testid="checkbox_none_of_the_above"
            checked={value?.none_of_the_above}
            disabled={isSubmitting}
          />
        ) : (
          <CheckboxComponent
            onChange={() => noneOfTheAboveClicked()}
            label={noneOfTheAboveText}
            testId="checkbox_none_of_the_above"
            checked={value?.none_of_the_above}
          />
        ))}
      {questionOptions}
      {noneOfTheAbovePosition !== 'top' &&
        showNoneOfTheAbove &&
        !!noneOfTheAboveText &&
        (newVersion ? (
          <IntakeCheckbox
            onChange={() => noneOfTheAboveClicked()}
            label={noneOfTheAboveText}
            testid="checkbox_none_of_the_above"
            checked={value?.none_of_the_above}
            disabled={isSubmitting}
          />
        ) : (
          <CheckboxComponent
            onChange={() => noneOfTheAboveClicked()}
            label={noneOfTheAboveText}
            testId="checkbox_none_of_the_above"
            checked={value?.none_of_the_above}
          />
        ))}
      {showOther &&
        !!otherTextContent &&
        (newVersion ? (
          <IntakeCheckbox
            label={otherTextContent}
            checked={value?.other}
            testid="checkbox_other"
            onChange={() => otherClicked()}
            disabled={isSubmitting}
          />
        ) : (
          <CheckboxComponent
            label={otherTextContent}
            checked={value?.other}
            testId="checkbox_other"
            onChange={() => otherClicked()}
          />
        ))}
      {showOther && value?.other && textarea}
    </div>
  );
};

export default MultiSelectQuestion;
