import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { selectCurrentIntake, selectCustomer } from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { apiRequestUserCommand } from 'app/actions/customer';
import { Formik } from 'formik';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import { selectCustomerProduct } from 'app/selectors/customer';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';
import DiscountBanner from '../DiscountBanner';
import { selectFurthestProductStepPath } from 'app/selectors/customer';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { PageWrapper } from 'mui';
import cx from 'classnames';
import { useExperiment } from 'app/utils/useExperiment';
import ProductRecommendationStep from 'app/components/customer/steps/Payment/king/ProductRecommendationStep';

const SubProductSelectionStep = () => {
  const recommendationFlowV2 =
    useExperiment('recommendation_flow_v2', 'recommendation_flow_v2_variation_0') ===
    'recommendation_flow_v2_variation_1';
  if (recommendationFlowV2) return <ProductRecommendationStep />;

  const newVersion = useRedesign();
  const intake = useAppSelector(selectCurrentIntake);
  const dispatch = useAppDispatch();
  const customer = useAppSelector(selectCustomer);
  const productName = intake.get('product_name');
  const product = useAppSelector((state) => selectCustomerProduct(state, productName));

  const checkoutHelper = createCheckoutHelper(intake, customer, product, true);

  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectFurthestProductStepPath({ customer: stateAfterSuccess }, productName));

  const initialValues = checkoutHelper.productSpecificInitialValues();
  const onSubmit = (params: any) => {
    const action = apiRequestUserCommand({
      cmdType: checkoutHelper.updateSubProductCommandName,
      params: { ...params, intake: intake.get('name') },
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  useEffect(() => {
    checkoutHelper.trackRecommendationGenerated();
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, values }) =>
        newVersion ? (
          <PageWrapper
            wrapperClassName={cx('flex flex-col gap-y-6 lg:gap-y-8', {
              'mb-60': checkoutHelper.recommendationSystemEnabled() && checkoutHelper.subProductName(values),
            })}
          >
            <div className="ml-10 sm:ml-5">{checkoutHelper.renderProductStepHeader(values)}</div>
            {checkoutHelper.renderProductStep(true, handleSubmit)}
          </PageWrapper>
        ) : (
          <div className="centered updated-design payments mb24">
            <DiscountBanner />
            {checkoutHelper.renderProductStepHeader(values)}
            <div className="content">
              {checkoutHelper.renderProductStep(true, handleSubmit)}
              {checkoutHelper.showProductSelectionStepNextButton() && (
                <BackNextFooter
                  noBack={true}
                  onNext={handleSubmit}
                  testId="submit-product-selection"
                  horizontalPadding={false}
                />
              )}
            </div>
          </div>
        )
      }
    </Formik>
  );
};

export default SubProductSelectionStep;
