import React from 'react';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import CrownLogo from '../../../images/Crown-Logo-Black.svg';
import './css/Footer.scss';

const Footer = () => {
  const newTheme = useRedesign();
  return newTheme ? null : (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__content__logo">
          <img src={CrownLogo} />
          <div>&copy; {new Date().getFullYear()} MAXIMUS</div>
        </div>
        <div className="footer__content__links">
          <a href="/accessibility-statement" target="_blank">
            ACCESSIBILITY
          </a>
          <a href="/terms-of-use" target="_blank">
            TERMS
          </a>
          <a rel="noreferrer" href="/privacy-policy" target="_blank">
            POLICY
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
