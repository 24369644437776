import React from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import { Action, Dispatch } from 'redux';
import { CallHistoryMethodAction } from 'connected-react-router';

import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import TitleWithLine from 'app/components/common/Typography/TitleWithLine';
import * as selectors from 'app/selectors/customer';
import * as actions from 'app/actions/customer';
import { P } from 'app/components/common/Typography';
import { PrimaryButton, SecondaryButton } from 'app/components/common/Button';
import { Button } from 'mui';
import { Title } from 'app/components/common/Typography';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer, selectPreviousStepPath } from 'app/selectors/customer';
import { LabSelection } from 'app/constants/Routes';
import { getCSRF } from 'app/api';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

const useLabActions = () => {
  type AppAction = Action<string> | CallHistoryMethodAction<[string, any?]>;
  type AppDispatch = Dispatch<AppAction>;
  const dispatch = useDispatch<AppDispatch>();
  const reviewLabsAction = push(useAppSelector(selectPreviousStepPath));
  const labSelectionAction = push(LabSelection);
  const customer = useAppSelector(selectCustomer);
  const lab_orders = customer?.get('lab_orders')?.toArray();
  const lab_order = lab_orders?.find((order) => order.get('intake_name') === 'onboarding');
  const intake = useAppSelector(selectors.selectCurrentIntake);
  const intakeName = intake.get('name');
  const photoPurpose = `${intake.get('product_name')}_onboarding_lab`;

  const removeLabAction = actions.apiRequestUserCommand({
    cmdType: 'remove_lab_order',
    params: { lab_order_id: lab_order?.get('order_id') },
    context: { onSuccessAction: labSelectionAction },
  });

  const onCancelOwnlabs = async () => {
    await axios.post(
      '/api/commands',
      {
        user_id: customer.get('id'),
        intakeName,
        photo_purpose: photoPurpose,
        cmdType: 'delete_own_lab',
        type: 'delete_own_lab',
      },
      { headers: { 'X-CSRF-Token': getCSRF() } },
    );
    dispatch(removeLabAction);
  };

  const onReview = () => {
    dispatch(reviewLabsAction);
  };

  return { onCancelOwnlabs, onReview };
};

const OwnLabFileUploadedSection = () => {
  const { onCancelOwnlabs, onReview } = useLabActions();
  const newVersion = useRedesign();

  return newVersion ? (
    <>
      <h5 className="mb-3">Lab Testing</h5>
      <h6>Labs uploaded</h6>
      <P>
        A member of the Maximus clinical team will review your lab work within 2 business days to ensure lab criteria
        are met. If lab criteria are not met you will be contacted by a member of the concierge team.
      </P>
      <Button onClick={onReview}>Review Labs</Button>
      <Button variant="text" onClick={onCancelOwnlabs}>
        Cancel Using Own Labs
      </Button>
    </>
  ) : (
    <>
      <TitleWithLine className="mb16 mt48" size="xl">
        Lab Testing
      </TitleWithLine>
      <PaymentCard>
        <Title className="mb20 center-align" size="l">
          Labs uploaded
        </Title>
        <P className="mb24 lab_upload_paragraph_format">
          A member of the Maximus clinical team will review your lab work within 2 business days to ensure lab criteria
          are met. If lab criteria are not met you will be contacted by a member of the concierge team.
        </P>
        <div className="action_buttons">
          <PrimaryButton className="mb20" text="REVIEW LABS" onClick={onReview} />
          <SecondaryButton
            className="action_button cancel-no-background"
            text="Cancel Using Own Labs"
            onClick={onCancelOwnlabs}
          />
        </div>
      </PaymentCard>
    </>
  );
};

export default OwnLabFileUploadedSection;
