import {
  AvailableProducts,
  KingV2SubProducts,
  LoverSubProducts,
  MagicianSubProducts,
  WarriorSubProducts,
} from 'app/constants/Products';

const subProductNames = {
  [AvailableProducts.King]: {
    [KingV2SubProducts.EncloPregnolone]: 'Enclomiphene + Pregnenolone',
    [KingV2SubProducts.Enclo]: 'Enclomiphene',
    [KingV2SubProducts.OralTrt]: 'Oral TRT',
    [KingV2SubProducts.EncloPregnoloneTrt]: 'Oral TRT+',
    [KingV2SubProducts.EncloTrt]: 'Oral TRT + Enclomiphene',
    [KingV2SubProducts.Topical]: 'Topical TRT Gel',
    [KingV2SubProducts.EncloTopical]: 'TRT Gel + Enclomiphene',
    [KingV2SubProducts.Injectable]: 'Injectable TRT',
    [KingV2SubProducts.EncloInjectable]: 'Injectable TRT + Enclomiphene',
    [KingV2SubProducts.InjectableHCG]: 'Injectable TRT + hCG',
  },
  [AvailableProducts.Magician]: {
    [MagicianSubProducts.Semaglutide]: 'Semaglutide',
    [MagicianSubProducts.Tirzepatide]: 'Tirzepatide',
  },
  [AvailableProducts.Warrior]: {
    [WarriorSubProducts.OralMinoxidil]: 'Oral Minoxidil',
    [WarriorSubProducts.OralDutasteride]: 'Oral Dutasteride',
    [WarriorSubProducts.OralFinasteride]: 'Oral Finasteride',
    [WarriorSubProducts.TopicalMinoxidil]: 'Minoxidil+ Gel',
    [WarriorSubProducts.TopicalDutasteride]: 'Dutasteride+ Gel',
    [WarriorSubProducts.TopicalFourInOne]: '4-in-1 Gel',
    [WarriorSubProducts.OralDutasterideOralMinoxidil]: 'Oral Dutasteride with Oral Minoxidil',
    [WarriorSubProducts.OralMinoxidilTopicalDutasteride]: 'Oral Minoxidil with Dutasteride+ Gel',
    [WarriorSubProducts.OralDutasterideTopicalMinoxidil]: 'Oral Dutasteride with Minoxidil+ Gel',
  },
  [AvailableProducts.Lover]: {
    [LoverSubProducts['2_5_mg']]: 'Daily use - 2.5mg',
    [LoverSubProducts['5_mg']]: 'Daily use - 5mg',
    [LoverSubProducts['10_mg']]: 'Daily use - 10mg',
    ['4']: '10mg - 4 doses',
    ['8']: '10mg - 8 doses',
    ['12']: '10mg - 12 doses',
  },
};

export default subProductNames;
