import React, { useState, useMemo, FC } from 'react';
import { Formik, Form } from 'formik';
import Question from 'app/constants/Question';
import checkboxCheckedImage from 'images/CheckedBoxDefault.svg';
import checkboxUncheckedImage from 'images/UncheckedBoxDefault.svg';
import { getComponentForQuestion, getDefaultValue } from 'app/components/customer/questions/helpers';
import { getIntakeSchema } from 'app/helpers/validators';
import ProtocolHeader from 'app/components/common/Typography/ProtocolHeader';
import { AvailableProducts } from 'app/constants/Products';
import { Card, Badge, ProgressBar } from 'mui';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import protocolNames from 'app/utils/protocolNames';

const INITIAL_STEPS = ['sex', 'erection', 'ed_scenario', 'ed_start'];

const getNextStep = (steps, currentIndex, values) => {
  if (values['sex'] === 'f') return null;

  const nextStep = steps[currentIndex + 1];

  if (!nextStep) {
    return null;
  }

  if (values.erection === 'never' && ['ed_scenario', 'ed_start'].includes(nextStep)) {
    return getNextStep(steps, currentIndex + 1, values);
  }

  return nextStep;
};

const Step0 = ({ onSubmit }) => {
  const [currentStep, setCurrentStep] = useState(INITIAL_STEPS[0]);
  const questions = useMemo(
    () => INITIAL_STEPS.reduce<Map<any, any>>((o, q) => o.set(q, new Question(q)), new Map()),
    [INITIAL_STEPS],
  );
  const [initialValues, setInitialValues] = useState(
    INITIAL_STEPS.reduce((o, q) => ({ ...o, [q]: getDefaultValue({ question: questions.get(q) }) }), {}),
  );
  const position = INITIAL_STEPS.indexOf(currentStep);
  const total = questions.size;

  const question = questions.get(currentStep);
  const IntakeSchema = getIntakeSchema(question);
  const Component = getComponentForQuestion({ question }) as FC<{
    question: any;
    name: string;
    checkedImage: typeof checkboxCheckedImage;
    uncheckedImage: typeof checkboxUncheckedImage;
    contained: boolean;
  }>;

  const submitNextStep = (values) => {
    const nextStep = getNextStep(INITIAL_STEPS, position, values);
    if (!nextStep) {
      const clearValues = Object.entries(values).reduce((o, [k, v]) => {
        if (v !== '') {
          o[k] = v;
        }

        return o;
      }, {});
      onSubmit(clearValues);
    } else {
      setInitialValues(values);
      setCurrentStep(nextStep);
    }
  };
  const newVersion = useRedesign();

  return (
    <Formik initialValues={initialValues} onSubmit={submitNextStep} validationSchema={IntakeSchema} enableReinitialize>
      {() =>
        newVersion ? (
          <Form>
            <Card>
              <Card.Body>
                <ProgressBar progress={(100 * position) / total} />
                <div className="flex flex-row justify-between">
                  <Badge variant={AvailableProducts.Lover}>{protocolNames[AvailableProducts.Lover]}</Badge>
                  <span>
                    {position + 1} of {total}
                  </span>
                </div>
                <p>{question.sentence}</p>
                {question.data.description && (
                  <div>
                    {question.data.description.includes('Why are we asking this?') && <b>Why are we asking this?</b>}
                    <p className="pre-wrap text-sm">{question.data.description.split('\n\n').pop()}</p>
                  </div>
                )}
                <div className="component">
                  <Component
                    question={question}
                    name={currentStep}
                    checkedImage={checkboxCheckedImage}
                    uncheckedImage={checkboxUncheckedImage}
                    contained={false}
                  />
                </div>
              </Card.Body>
            </Card>
          </Form>
        ) : (
          <Form className="registration-flow registration-flow_lover">
            <ProtocolHeader protocol={AvailableProducts.Lover} />
            <h2 className="title title_sm">{question.sentence}</h2>
            {question.data.description && <p className="pre-wrap mt24 mb24">{question.data.description}</p>}
            <div className="component">
              <Component
                question={question}
                name={currentStep}
                checkedImage={checkboxCheckedImage}
                uncheckedImage={checkboxUncheckedImage}
                contained={false}
              />
            </div>
          </Form>
        )
      }
    </Formik>
  );
};

export default Step0;
