import React from 'react';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

const GenericOnboardingServices = () => {
  const newVersion = useRedesign();

  return newVersion ? (
    <div className="border border-primary-200 rounded-xl p-4 lg:p-8">
      <p className="mb-2 lg:mb-4">
        <b>Plans include: </b>
      </p>
      <ul className="disc-list">
        <li>Medication</li>
        <li>Doctor messaging</li>
        <li>Treatment customization</li>
        <li>Concierge support & coaching</li>
        <li>Free shipping</li>
      </ul>
    </div>
  ) : (
    <>
      <div className="price_row mt24">
        <div>Unlimited Doctor Messaging</div>
        <div>Included</div>
      </div>
      <div className="price_row mt24">
        <div>Concierge Support</div>
        <div>Included</div>
      </div>
      <div className="price_row mt24">
        <div>Ongoing Treatment Customization</div>
        <div>Included</div>
      </div>
      <div className="price_row mt24">
        <div>Exclusive Content & Coaching</div>
        <div>Included</div>
      </div>
    </>
  );
};

export default GenericOnboardingServices;
