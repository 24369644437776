import { P } from 'app/components/common/Typography';
import React from 'react';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

export const TopicalTestosteroneInfo = ({ withoutTitle = false }) => {
  const newVersion = useRedesign();

  return newVersion ? (
    <>
      {!withoutTitle && <h6 className="underline mt-2 mb-4">Topical Testosterone</h6>}

      <P className="bold mb-2">What is Topical Testosterone?</P>
      <P className="mb-5">
        Topical testosterone is a prescription medicine applied to the skin to improve symptoms of hypogonadism.
      </P>
      <P className="bold mb-2">Who should not use Topical Testosterone?</P>
      <P className="mb12">
        Do not use topical testosterone if you:
        <ul className="disc-list">
          <li>Are allergic to testosterone or any of the ingredients in the topical solution.</li>
          <li>Have or might have prostate cancer.</li>
          <li>Are under 18 years of age.</li>
        </ul>
      </P>

      <P className="bold mb-2">What should I tell my healthcare provider before using Topical Testosterone?</P>
      <P className="mb12">
        Before you use topical testosterone, tell your healthcare provider if you:
        <ul className="disc-list">
          <li>Have heart, kidney, or liver problems.</li>
          <li>Have skin conditions or allergies.</li>
          <li>Have high red blood cell counts (hematocrit).</li>
          <li>Have urinary problems due to an enlarged prostate.</li>
          <li>Have sleep apnea.</li>
          <li>Have any other medical conditions.</li>
          <li>
            Tell your healthcare provider about all the medicines you take, including prescription and over-the-counter
            medicines, vitamins, and herbal supplements.
          </li>
        </ul>
      </P>

      <P className="bold mb-2">How should I use Topical Testosterone?</P>
      <P className="mb12">
        <ul className="disc-list">
          <li>Use topical testosterone exactly as your healthcare provider tells you to use it.</li>
          <li>Apply the medication to clean, dry skin on the area instructed by your provider.</li>
          <li>Wash your hands thoroughly with soap and water after application.</li>
          <li>Avoid swimming, showering, or bathing for at least 2 hours after application.</li>
        </ul>
      </P>

      <P className="bold mb-2">What are the possible side effects of Topical Testosterone?</P>
      <P className="mb12">
        <ul className="disc-list">
          <li>Common side effects include:</li>
          <li>Skin irritation at the application site</li>
          <li>Acne</li>
          <li>Mood changes</li>
          <li>Hair loss</li>
          <li>Reduced fertility and testicular function</li>
        </ul>
      </P>

      <P className="bold mb-2">Risk of Transference:</P>
      <P className="mb12">
        <ul className="disc-list">
          <li>There is a risk of transferring the medication to others through close skin-to-skin contact.</li>
          <li>
            To reduce this risk, cover the application site with clothing and wash the area before any skin-to-skin
            contact.
          </li>
        </ul>
      </P>
      <P className="bold mb-2">Serious side effects include:</P>
      <P className="mb12">
        <ul className="disc-list">
          <li>Swelling of ankles or feet due to fluid retention</li>
          <li>Breast enlargement or tenderness (gynecomastia)</li>
          <li>Increased risk of prostate problems</li>
          <li>Blood clots</li>
          <li>Allergic reactions</li>
        </ul>
      </P>

      <P className="bold mb-2">Seek emergency medical attention if you experience:</P>
      <P className="mb12">
        <ul className="disc-list">
          <li>Signs of an allergic reaction</li>
          <li>Chest pain</li>
          <li>Shortness of breath</li>
          <li>Vision or speech problems</li>
        </ul>
      </P>

      <P className="bold mb-2">What else should I know about Topical Testosterone?</P>
      <P className="mb12">
        <ul className="disc-list">
          <li>Keep the medication away from, women, children and pets.</li>
          <li>Do not allow others to touch the unwashed or unclothed application site.</li>
          <li>Regular blood tests are needed to monitor your testosterone levels and other health indicators.</li>
        </ul>
      </P>

      <P className="bold mb-2">How should I store Topical Testosterone?</P>
      <P className="mb12">
        <ul className="disc-list">
          <li>Store at room temperature away from moisture and heat.</li>
          <li>Keep the medication in its original container.</li>
          <li>Dispose of used applicators safely.</li>
        </ul>
      </P>

      <P className="bold mb-2">General information about the safe and effective use of Topical Testosterone</P>
      <P className="mb12">
        Do not use topical testosterone for a condition for which it was not prescribed. Do not give topical
        testosterone to other people, even if they have the same symptoms you have. It may harm them.
      </P>
    </>
  ) : (
    <>
      {!withoutTitle && <h5 className="subtitle">Topical Testosterone</h5>}

      <P className="bold">What is Topical Testosterone?</P>
      <P className="mb12">
        Topical testosterone is a prescription medicine applied to the skin to improve symptoms of hypogonadism.
      </P>
      <P className="bold">Who should not use Topical Testosterone?</P>
      <P className="mb12">
        Do not use topical testosterone if you:
        <ul className="modal-list">
          <li>Are allergic to testosterone or any of the ingredients in the topical solution.</li>
          <li>Have or might have prostate cancer.</li>
          <li>Are under 18 years of age.</li>
        </ul>
      </P>

      <P className="bold">What should I tell my healthcare provider before using Topical Testosterone?</P>
      <P className="mb12">
        Before you use topical testosterone, tell your healthcare provider if you:
        <ul className="modal-list">
          <li>Have heart, kidney, or liver problems.</li>
          <li>Have skin conditions or allergies.</li>
          <li>Have high red blood cell counts (hematocrit).</li>
          <li>Have urinary problems due to an enlarged prostate.</li>
          <li>Have sleep apnea.</li>
          <li>Have any other medical conditions.</li>
          <li>
            Tell your healthcare provider about all the medicines you take, including prescription and over-the-counter
            medicines, vitamins, and herbal supplements.
          </li>
        </ul>
      </P>

      <P className="bold">How should I use Topical Testosterone?</P>
      <P className="mb12">
        <ul className="modal-list">
          <li>Use topical testosterone exactly as your healthcare provider tells you to use it.</li>
          <li>Apply the medication to clean, dry skin on the area instructed by your provider.</li>
          <li>Wash your hands thoroughly with soap and water after application.</li>
          <li>Avoid swimming, showering, or bathing for at least 2 hours after application.</li>
        </ul>
      </P>

      <P className="bold">What are the possible side effects of Topical Testosterone?</P>
      <P className="mb12">
        <ul className="modal-list">
          <li>Common side effects include:</li>
          <li>Skin irritation at the application site</li>
          <li>Acne</li>
          <li>Mood changes</li>
          <li>Hair loss</li>
          <li>Reduced fertility and testicular function</li>
        </ul>
      </P>

      <P className="bold">Risk of Transference:</P>
      <P className="mb12">
        <ul className="modal-list">
          <li>There is a risk of transferring the medication to others through close skin-to-skin contact.</li>
          <li>
            To reduce this risk, cover the application site with clothing and wash the area before any skin-to-skin
            contact.
          </li>
        </ul>
      </P>
      <P className="bold">Serious side effects include:</P>
      <P className="mb12">
        <ul className="modal-list">
          <li>Swelling of ankles or feet due to fluid retention</li>
          <li>Breast enlargement or tenderness (gynecomastia)</li>
          <li>Increased risk of prostate problems</li>
          <li>Blood clots</li>
          <li>Allergic reactions</li>
        </ul>
      </P>

      <P className="bold">Seek emergency medical attention if you experience:</P>
      <P className="mb12">
        <ul className="modal-list">
          <li>Signs of an allergic reaction</li>
          <li>Chest pain</li>
          <li>Shortness of breath</li>
          <li>Vision or speech problems</li>
        </ul>
      </P>

      <P className="bold">What else should I know about Topical Testosterone?</P>
      <P className="mb12">
        <ul className="modal-list">
          <li>Keep the medication away from, women, children and pets.</li>
          <li>Do not allow others to touch the unwashed or unclothed application site.</li>
          <li>Regular blood tests are needed to monitor your testosterone levels and other health indicators.</li>
        </ul>
      </P>

      <P className="bold">How should I store Topical Testosterone?</P>
      <P className="mb12">
        <ul className="modal-list">
          <li>Store at room temperature away from moisture and heat.</li>
          <li>Keep the medication in its original container.</li>
          <li>Dispose of used applicators safely.</li>
        </ul>
      </P>

      <P className="bold">General information about the safe and effective use of Topical Testosterone</P>
      <P className="mb12">
        Do not use topical testosterone for a condition for which it was not prescribed. Do not give topical
        testosterone to other people, even if they have the same symptoms you have. It may harm them.
      </P>
    </>
  );
};
