import React, { FC } from 'react';
import cx from 'classnames';

import { SecondaryButton, PrimaryButton } from 'app/components/common/Button';
import './css/BackNextFooter.scss';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Button as MxButton } from 'mui';

interface Props {
  className?: string;
  buttonClassName?: string;
  backDisabled?: boolean;
  onBack?: () => void;
  nextDisabled?: boolean;
  onNext?: () => void;
  noNext?: boolean;
  noBack?: boolean;
  horizontalPadding?: boolean;
  nextText?: string;
  testId?: string;
}

const BackNextFooter: FC<Props> = ({
  className,
  buttonClassName,
  backDisabled,
  nextDisabled,
  onNext,
  onBack,
  noNext,
  noBack = false,
  nextText = 'Next',
  testId = 'next-step',
  horizontalPadding = true,
}) => {
  const newVersion = useRedesign();

  const nextButton = newVersion ? (
    <MxButton className={buttonClassName} onClick={onNext} disabled={nextDisabled} testid={testId}>
      {nextText}
    </MxButton>
  ) : (
    <PrimaryButton
      className={buttonClassName}
      text={nextText}
      type="button"
      onClick={onNext}
      disabled={nextDisabled}
      data-testid={testId}
    />
  );

  const backButton = newVersion ? (
    <MxButton
      className={cx(buttonClassName, 'mt-4')}
      variant="text"
      onClick={onBack ?? (() => {})}
      disabled={backDisabled}
      testid={testId}
    >
      Back
    </MxButton>
  ) : (
    <SecondaryButton
      className={cx(buttonClassName, 'mt8 mb16')}
      text="Back"
      onClick={onBack ?? (() => {})}
      disabled={backDisabled || !onBack}
      data-testid="back-to-previous-step"
    />
  );

  const containerClassName = newVersion
    ? cx(className)
    : cx('back-next mt24', className, {
        mh16: horizontalPadding,
      });

  return (
    <div className={containerClassName}>
      {!noNext && nextButton}
      {!noBack && backButton}
    </div>
  );
};

export default BackNextFooter;
