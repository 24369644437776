import React, { useEffect } from 'react';
import './css/FullscreenModal.scss';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { createPortal } from 'react-dom';

export const FullscreenModal = ({ text, className, refModal }) => {
  const newVersion = useRedesign();

  useEffect(() => {
    const fullScreenModalHandler = (isOpen) => (document.documentElement.style.overflow = isOpen ? 'hidden' : 'auto');

    fullScreenModalHandler(true);

    return () => fullScreenModalHandler(false);
  }, []);

  return newVersion ? (
    <>
      {createPortal(
        <div className="fixed top-[56px] left-0 bg-white w-full h-full overflow-scroll">{text}</div>,
        document.body,
      )}
    </>
  ) : (
    <div className={`fullscreen-modal ${className}`} ref={refModal}>
      <div className="fullscreen-modal-body">{text}</div>
    </div>
  );
};
