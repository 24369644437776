import { Experiment } from '@amplitude/experiment-js-client';
import { AnalyticsBrowser } from '@segment/analytics-next';
import Cookies from 'js-cookie';
import { SEGMENT_WRITE_KEY } from './initializeAmplitudeSessionReplay';

export const AMPLITUDE_ENABLED = window.amplitudeEnabled;
export const AMPLITUDE_SDK_KEY = window.amplitudeSDKKey;

export const getAmplitudeExperiment = async (id, callback) => {
  if (AMPLITUDE_ENABLED !== '1') {
    return callback({ key: 'off' });
  }

  const segmentAnalytics = AnalyticsBrowser.load({
    writeKey: SEGMENT_WRITE_KEY,
  });

  const user = await segmentAnalytics.user();
  Cookies.set('device_id', String(user.anonymousId()), { domain: '.maximustribe.com' });

  const client = Experiment.initialize(AMPLITUDE_SDK_KEY);

  await client.fetch({ device_id: String(user.anonymousId()) });
  const variant = client.variant(id);
  await callback(variant);
};
