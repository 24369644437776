import { isString } from 'lodash';
import * as Yup from 'yup';

const PHONE_REGEX = /^\(\d{3}\)-\d{3}-\d{4}$/;
const POSTAL_CODE_REGEX = /^\d{5}$/;
const EMAIL_REGEX =
  /^(?!.*\.{2})(?!^\.)[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const PASSWORD_VALIDATION = Yup.string()
  .required('Required')
  .min(8, 'Password is too short - should be at least 8 characters')
  .max(72, 'Password is too long - should be at most 72 characters');
const PASSWORD_CONFIRMATION_VALIDATION = Yup.string()
  .required('Required')
  .oneOf([Yup.ref('password'), null], 'The passwords provided do not match');

export const OptInsSchema = Yup.object().shape({
  pc_opt_in: Yup.bool().oneOf([true], 'Field must be checked'),
  mso_opt_in: Yup.bool().oneOf([true], 'Field must be checked'),
});

export const TreatmentGoingSchema = Yup.object().shape({
  treatment_going: Yup.string().required('Required'),
});

export const PrescriptionV2Schema = Yup.object().shape({
  medication_id: Yup.string().required('Required'),
  quantity_unit: Yup.string().required('Required'),
  directions: Yup.string(),
  quantity: Yup.number().required('Required'),
  refills: Yup.number().required('Required'),
  days_supply: Yup.number().required('Required'),
});

export const RegistrationSchemaStep2 = Yup.object().shape({
  over_18: Yup.bool().oneOf([true], 'Required'),
  agree: Yup.bool().oneOf([true], 'Required'),
  state: Yup.string().required('Required'),
});

export const RegistrationSchemaStep3 = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
});

export const RegistrationSchemaStep4 = Yup.object().shape({
  email: Yup.string().required('Required').matches(EMAIL_REGEX, 'Incorrect format').trim(),
  phone_number: Yup.string().matches(PHONE_REGEX, 'Incorrect format').trim(),
  password: PASSWORD_VALIDATION,
  password_confirmation: PASSWORD_CONFIRMATION_VALIDATION,
});

export const RegistrationSchemaLabEmail = Yup.object().shape({
  email: Yup.string().required('Required').matches(EMAIL_REGEX, 'Incorrect format').trim(),
});

const IDENTITY_VALIDATION = {
  sex: Yup.string().required('Required'),
  date_of_birth: Yup.date()
    .max(new Date(Date.now() - 567648000000), 'You must be at least 18 years')
    .required('Required'),
};

export const RegistrationSchemaLabAccountDetails = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  ...IDENTITY_VALIDATION,
  password: PASSWORD_VALIDATION,
  password_confirmation: PASSWORD_CONFIRMATION_VALIDATION,
});

export const IdentitySchema = Yup.object().shape({
  ...IDENTITY_VALIDATION,
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

export const LoginWithPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

export const PasswordUpdateIfExistSchema = Yup.object().shape({
  password_current: PASSWORD_VALIDATION,
  password: PASSWORD_VALIDATION,
  password_confirmation: PASSWORD_CONFIRMATION_VALIDATION,
});

export const PasswordUpdateIfNewSchema = Yup.object().shape({
  password: PASSWORD_VALIDATION,
  password_confirmation: PASSWORD_CONFIRMATION_VALIDATION,
});

export const getIntakeSchema = (question) => {
  const { name } = question;
  const getValidator = (question) => {
    const { type } = question;
    if (type === 'numeric') {
      const {
        data: { min, max },
      } = question;

      return Yup.lazy((v) => {
        if (v && isString(v) && v.match(/\.$/)) {
          return Yup.string().matches(/[^\.]$/, 'Wrong format');
        }
        return Yup.number().min(min).max(max).required('Required');
      });
    }
    if (type === 'text_area') {
      let val = Yup.string();

      if (question.data?.validators?.includes('present')) {
        val = val.trim().required();
      }

      return val;
    }

    if (type === 'multi_select') {
      const schema = {} as any;
      const noneOfTheAboveText = question.data.none_of_the_above?.text;
      if (noneOfTheAboveText) {
        schema.none_of_the_above = Yup.bool();
      }
      if (!question.data.no_other) {
        schema.other_content = Yup.string().when('other', {
          is: true,
          then: Yup.string().required('Must fill in other content'),
        });
        if (['current_medications', 'follow_up_current_medications'].includes(question.name)) {
          schema.other_content = Yup.string().when('none_of_the_above', {
            is: false,
            then: Yup.string().required('Must fill in other content'),
          });
        }
        schema.other = Yup.bool();
      }
      return Yup.object()
        .shape(schema)
        .test('test', 'err', (value) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { none_of_the_above, other_content, ...rest } = value || {};
          const restValues = Object.values(rest);
          const anyTrue = restValues.some((val) => val);

          return none_of_the_above ? !anyTrue : anyTrue;
        });
    }
    if (type === 'height') {
      const {
        data: { min, max },
      } = question;

      return Yup.number().min(min).max(max).required('Required');
    }

    return Yup.string().required('Required');
  };

  return Yup.object().shape({
    [name]: getValidator(question),
  });
};

export const BillingSchema = Yup.object().shape({
  selfPay: Yup.bool().oneOf([true], 'Field must be checked'),
  updateAddress: Yup.bool(),
  updatePayment: Yup.bool(),
  number: Yup.bool().when('updatePayment', {
    is: true,
    then: Yup.bool().oneOf([true], 'Field invalid').required(),
  }),
  cvc: Yup.bool().when('updatePayment', {
    is: true,
    then: Yup.bool().oneOf([true], 'Field invalid').required(),
  }),
  expiry: Yup.bool().when('updatePayment', {
    is: true,
    then: Yup.bool().oneOf([true], 'Field invalid').required(),
  }),
  address_line_1: Yup.string()
    .trim()
    .when('updateAddress', {
      is: true,
      then: Yup.string().trim().required('Required'),
    }),
  address_line_2: Yup.string(),
  city: Yup.string()
    .trim()
    .when('updateAddress', {
      is: true,
      then: Yup.string().trim().required('Required'),
    }),
  state: Yup.string()
    .trim()
    .when('updateAddress', {
      is: true,
      then: Yup.string().trim().required('Required'),
    }),
  postal_code: Yup.string()
    .trim()
    .when('updateAddress', {
      is: true,
      then: Yup.string().trim().required('Required').matches(POSTAL_CODE_REGEX, 'Incorrect format'),
    }),
});

export const ShippingSchema = Yup.object().shape({
  full_name: Yup.string().trim().required('Required'),
  address_line_1: Yup.string().trim().required('Required'),
  address_line_2: Yup.string(),
  city: Yup.string().trim().required('Required'),
  state: Yup.string().trim().required('Required'),
  postal_code: Yup.string().trim().required('Required').matches(POSTAL_CODE_REGEX, 'Incorrect format'),
  phone_number: Yup.string().trim().required('Required').matches(PHONE_REGEX, 'Incorrect format'),
});

export const ShippingSchemaWithoutFullName = Yup.object().shape({
  address_line_1: Yup.string().trim().required('Required'),
  address_line_2: Yup.string(),
  city: Yup.string().trim().required('Required'),
  state: Yup.string().trim().required('Required'),
  postal_code: Yup.string().trim().required('Required').matches(POSTAL_CODE_REGEX, 'Incorrect format'),
});

export const PharmacySchema = Yup.object().shape({
  choice: Yup.string().required('Required'),
  pharmacy_name: Yup.string().when('choice', {
    is: 'other',
    then: Yup.string().required('Required'),
  }),
  address_line_1: Yup.string()
    .trim()
    .when('choice', {
      is: 'other',
      then: Yup.string().required('Required'),
    }),
  state: Yup.string()
    .trim()
    .when('choice', {
      is: 'other',
      then: Yup.string().required('Required'),
    }),
  city: Yup.string()
    .trim()
    .when('choice', {
      is: 'other',
      then: Yup.string().required('Required'),
    }),
  postal_code: Yup.string()
    .trim()
    .when('choice', {
      is: 'other',
      then: Yup.string().required('Required').matches(POSTAL_CODE_REGEX, 'Incorrect format'),
    }),
  phone_number: Yup.string()
    .trim()
    .when('choice', {
      is: 'other',
      then: Yup.string().required('Required').matches(PHONE_REGEX, 'Incorrect format'),
    }),
});
export const PrivateMessageSchema = Yup.object().shape({
  body: Yup.string().trim().required('Required'),
});
export const TassoApiTestingSchema = Yup.object().shape({
  data: Yup.string().trim().required('Required'),
});

export const NewElationPhysicianSchema = Yup.object().shape({
  elation_id: Yup.string().trim().required('Required').typeError('Required'),
  weight: Yup.string().trim().required('Required').typeError('Required'),
  npi: Yup.string().trim().required('Required').typeError('Required'),
  timezone: Yup.string().trim().required('Required').typeError('Required'),
});
export const ExistingElationPhysicianSchema = Yup.object().shape({
  weight: Yup.string().trim().required('Required').typeError('Required'),
  npi: Yup.string().trim().required('Required').typeError('Required'),
  timezone: Yup.string().trim().required('Required').typeError('Required'),
});

export const RecordPharmacySchema = Yup.array().of(
  Yup.object().shape({
    date_shipped: Yup.date().required('Required').meta({ format: 'MM/DD/YYYY' }),
    order_number: Yup.string().required('Required'),
    tracking_number: Yup.string().required('Required'),
    // intake_name: Yup.string().required('Required'),
  }),
);

export const CreatePrescriptionOrderSchema = Yup.array().of(
  Yup.object().shape({
    date_shipped: Yup.date().required('Required').meta({ format: 'MM/DD/YYYY' }),
    tracking_number: Yup.string().required('Required'),
    order_number: Yup.string().required('Required'),
    carrier: Yup.string().required('Required'),
  }),
);
export const KitProcessedSchema = Yup.object().shape({
  date: Yup.date().required('Required'),
});
export const UpdateShippingAddressSchema = Yup.object().shape({
  full_name: Yup.string().required('Required'),
  address_line_1: Yup.string().trim().required('Required'),
  address_line_2: Yup.string(),
  city: Yup.string().trim().required('Required'),
  state: Yup.string().trim().required('Required'),
  postal_code: Yup.string()
    .trim()
    .required('Required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
  // phone_number: Yup.string().required('Required'),
});
export const KitShippedSchema = Yup.object().shape({
  tracking_no: Yup.string().required('Required'),
  date: Yup.date().required('Required'),
});
export const EmptySchema = Yup.object().shape({});

export const AdminUpdatePaymentIntentSchema = Yup.object().shape({
  id: Yup.string().required('Required'),
  intake_name: Yup.string().required('Required'),
  purpose: Yup.string().oneOf(['dose_adjustment', 'lab_adjustment']).required('Required'),
  amount_cents: Yup.number().required('Required'),
});

export const RecordRefundSchema = Yup.object().shape({
  intake_name: Yup.string().required('Required'),
  payment_intent_id: Yup.string().required('Required'),
  refund_time: Yup.string().required('Required'),
  amount_cents: Yup.number().required('Required'),
  pc_amount_cents: Yup.number().required('Required'),
  mso_amount_cents: Yup.number().required('Required'),
  lab_kit_amount_cents: Yup.number().required('Required'),
  lab_process_amount_cents: Yup.number().required('Required'),
  pharma_amount_cents: Yup.number().required('Required'),
});

export { SetUserEmailSchema } from './validators/SetUserEmailSchema';

export const DriverLicenseSchema = Yup.object().shape({
  king_v2_id_number_confirmation: Yup.string().required('Required').max(30, "Driver's License Number is too long"),
  king_v2_id_state_confirmation: Yup.string().required('Required'),
});
