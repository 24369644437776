import React, { useMemo } from 'react';

import { P } from 'app/components/common/Typography';
import { push } from 'connected-react-router/immutable';

import './css/RefillComplete.scss';
import { connect } from 'react-redux';

import { Formik } from 'formik';
import * as Routes from 'app/constants/Routes';
import { PrimaryButton, SecondaryButton } from 'app/components/common/Button';
import TextArea from 'app/components/common/TextArea';
import { apiRequestUserCommand } from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { TreatmentGoingSchema } from 'app/helpers/validators';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Badge, Card as MxCard, PageWrapper, ProgressBar as MxPb, Button as MxButton } from 'mui';
import protocolNames from 'app/utils/protocolNames';
import Question from 'app/constants/Question';
import { SanitizeHTML } from 'app/utils';
import MxTextArea from 'app/components/common/formik/TextArea';
// import IconSuccess from 'images/Icon-Success.svg'

const RefillCheckpoint = ({ dispatch, intake, onSuccessAction, position, total, productName }) => {
  const onSubmit = (params) => {
    const name = 'treatment_going';
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = apiRequestUserCommand({
      cmdType: 'answer_questions',
      params,
      context: { onSuccessAction, form: name },
    });
    dispatch(action);
  };
  const initialValues = useMemo(() => ({ treatment_going: '' }), []);
  const refillCheckpointQuestion = useMemo(() => new Question('freeform_on_follow_up'), []);

  const newVersion = useRedesign();

  return newVersion ? (
    <PageWrapper>
      <MxCard>
        <MxCard.Body>
          <MxPb progress={(100 * position) / total} />
          <div className="flex flex-row justify-between">
            <Badge variant={productName}>{protocolNames[productName]}</Badge>
          </div>
          <p>Optional question or comment</p>
          <p>
            <strong>{refillCheckpointQuestion.sentence}</strong>
          </p>
          {!!refillCheckpointQuestion.data.description && (
            <p className="text-sm">
              <SanitizeHTML html={refillCheckpointQuestion.data.description} />
            </p>
          )}
          <Formik validationSchema={TreatmentGoingSchema} initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, setFieldValue, isValid, dirty }) => (
              <>
                <MxTextArea
                  data-testid="freeform_on_follow_up"
                  name="treatment_going"
                  id="treatment_going"
                  placeholder="Add your note to the doctor here..."
                  onChange={({ currentTarget: { value: content } }) => {
                    setFieldValue('treatment_going', content);
                  }}
                />
                <div className="centered">
                  <MxButton onClick={() => handleSubmit()} disabled={!isValid || !dirty} testid="next-step">
                    Submit
                  </MxButton>
                  <MxButton
                    variant="text"
                    testid="cancel"
                    onClick={() => onSubmit(initialValues)}
                    className="mt16 mt-4"
                  >
                    Skip
                  </MxButton>
                </div>
              </>
            )}
          </Formik>
        </MxCard.Body>
      </MxCard>
    </PageWrapper>
  ) : (
    <div className="refill_complete centered">
      {/* <div className="refill_complete__success">
        <div className="flex">
          <img src={IconSuccess} />
          <H6>Refill Intake Complete!</H6>
        </div>
        <P className="refill_complete__info">We will send a notification once your medication has been shipped!</P>
        <PrimaryButton onClick={() => dispatch(push(Routes.Dashboard))} text="return to dashboard" />
      </div> */}

      <div className="mh16 mv24">
        <P className="gold mb12 refill_complete__heading">OPTIONAL QUESTION OR COMMENT</P>

        <div className="refill_complete__questions mb24">
          <P className="mb12">
            <strong>Please describe for your doctor how treatment has been going so far.</strong>
          </P>
          <ul className="ml24">
            <li>
              <P className="mb8">Have you gotten closer to your goals?</P>
            </li>
            <li>
              <P className="mb8">Have you experienced any side effects or health issues you think are related?</P>
            </li>
            <li>
              <P className="mb8">Do you have any specific questions for your doctor?</P>
            </li>
          </ul>
          <P className="mt16">Provide as much detail as possible so your doctor can get a full picture.</P>
        </div>
      </div>
      <Formik validationSchema={TreatmentGoingSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, setFieldValue, isValid, dirty }) => (
          <>
            <TextArea
              testId="freeform_on_follow_up"
              placeholder="Add your note to the doctor here..."
              onChange={({ currentTarget: { value: content } }) => {
                setFieldValue('treatment_going', content);
              }}
            />
            <div className="mh16 mt24">
              <PrimaryButton
                text="submit"
                data-testid="next-step"
                onClick={() => handleSubmit()}
                disabled={!isValid || !dirty}
              />
              <SecondaryButton
                type="button"
                data-testid="cancel"
                text="Skip"
                onClick={() => onSubmit(initialValues)}
                className="mt16"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  const productName = selectors.selectCurrentIntakeProduct(state);
  const onSuccessAction = push(Routes.ProductDashboard(productName));
  const previousStepAction = push(selectors.selectPreviousStepPath(state));

  return {
    intake: selectors.selectCurrentIntake(state),
    onSuccessAction,
    previousStepAction,
    productName,
  };
};

export default connect(mapStateToProps)(RefillCheckpoint);
