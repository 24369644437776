import React, { ComponentProps, FC, forwardRef } from 'react';
import { useField } from 'formik';
import { FormCheckbox as FCB } from 'mui';
import CommonInput from 'app/components/common/Input';

export const FormCheckbox: FC<
  {
    id?: string;
    name: string;
  } & ComponentProps<typeof CommonInput>
> = forwardRef(({ id, name, ...restProps }, ref) => {
  const [field, { error }] = useField({ id, name });

  return <FCB ref={ref} valid={!error && Boolean(!error)} testId={id} {...field} {...restProps} />;
});

export default FormCheckbox;
