import React, { useContext, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { Accordion, Card } from 'mui';
import TitleWithIcon from 'app/components/customer/ProgressTracker/common/TitleWithIcon';
import OPTIONS from 'app/constants/option-groups.json';
import ExpandButton from 'app/components/common/ExpandButton';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { ProductContext } from '../Product';
import IconLamp from 'images/progress-tracker/Lamp.svg';
import './css/FAQ.scss';

const ProgressTrackerFAQ = () => {
  const productCtx = useContext(ProductContext);
  const faqItemsProduct = window.faqItems?.filter((i) => i.product === productCtx?.selectedProduct);

  const availableStatesList = useMemo(
    () =>
      OPTIONS.states
        .filter((state: { label: string; value: string }) => window.signupStates.includes(state.value))
        .map((state) => state.label)
        .join(', '),
    [window.signupStates],
  );

  const parseAnswer = (answer: string) => answer.replace('[AVAILABLE_STATES]', availableStatesList);

  if (!faqItemsProduct?.length) {
    return null;
  }

  const oldDesignSystem = () => (
    <div className="progress_tracker__faq">
      <div className="progress_tracker__content">
        <TitleWithIcon icon={<ReactSVG src={IconLamp} className="svg-icon" />}>
          Frequently Asked Questions
        </TitleWithIcon>
        <div className="progress_tracker__faq__questions">
          {faqItemsProduct.map((item) => (
            <div key={item.id} className="question">
              <ExpandButton text={item.question}>
                <p dangerouslySetInnerHTML={{ __html: parseAnswer(item.answer) }} />
              </ExpandButton>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const accordionItems = () => {
    return faqItemsProduct.map((item) => ({
      content: <p dangerouslySetInnerHTML={{ __html: parseAnswer(item.answer) }} />,
      open: false,
      title: item.question,
    }));
  };

  const newDesignSystem = () => (
    <div className="centered mt-12 lg:mt-16">
      <h3 className="ml-10 sm:ml-5">F.A.Q.</h3>
      <Card className="customer-card-content mt-4 lg:mt-6 py-6 lg:py-8">
        <Accordion items={accordionItems()} />
      </Card>
    </div>
  );
  const newTheme = useRedesign();

  return <>{newTheme ? newDesignSystem() : oldDesignSystem()}</>;
};
export default ProgressTrackerFAQ;
