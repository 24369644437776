import React from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { useExperiment } from 'app/utils/useExperiment';
import { selectCurrentIntake, selectCurrentIntakeProduct } from 'app/selectors/customer';
import './css/DiscountBanner.scss';

const DiscountBanner = () => {
  const isLabsFree =
    useExperiment('testosterone_discount_labs_free', 'testosterone_discount_labs_free_variation_0') ===
    'testosterone_discount_labs_free_variation_1';

  const currentIntake = useAppSelector(selectCurrentIntake);
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);

  if (!isLabsFree) return null;

  const isOnboarding = currentIntake.get('type') === 'onboarding';
  const isKingProtocol = currentProductName === 'king';

  if (!(isOnboarding && isKingProtocol)) return null;

  return (
    <div className="discount-banner-exp">
      {isLabsFree && (
        <p>
          Limited time offer: Take <span>$100 off</span> your first purchase and get a <span>free lab test</span> ($99+
          value).
        </p>
      )}
    </div>
  );
};

export default DiscountBanner;
