import { P } from 'app/components/common/Typography';
import { Checkbox } from 'app/components/common/formik/Checkbox';
import React from 'react';
import './css/Shipping.scss';

interface SmsConsentProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const SmsConsent: React.FC<SmsConsentProps> = ({ onChange, checked }) => {
  return (
    <Checkbox
      topAligned
      label={
        <P className="sms-consent">
          I consent to receive text/SMS messages from Maximus. Reply STOP to stop these messages. Reply HELP for help.
          Message frequency varies. I understand message and data rates may apply.
          <br />
          <br />
          Please refer to our{' '}
          <a href="/terms-of-use" target="_blank">
            Terms and Privacy Policy
          </a>
        </P>
      }
      testId="receive_sms"
      name="receive_sms"
      onChange={onChange}
      checked={checked}
    />
  );
};

export default SmsConsent;
