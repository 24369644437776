import React, { useEffect, useState, useMemo } from 'react';
import Cal, { getCalApi } from '@calcom/embed-react';
import PageHeader from '../../common/PageHeader';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from '../../../selectors/customer';
import { apiRequestUserCommand } from '../../../actions/customer';
import { AvailableProducts } from '../../../constants/Products';
import { CallHistoryMethodAction } from 'connected-react-router';
import { List } from 'immutable';
import Spinner from 'app/components/customer/Spinner';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { PageWrapper } from 'mui';

type Props = {
  onSuccessActionCreator?: (_: any, stateAfterSuccess: any) => CallHistoryMethodAction<[string, any?]>;
  setRescheduleCompleted?: any;
};

const ScheduleAppointmentForm = ({ onSuccessActionCreator, setRescheduleCompleted }: Props) => {
  const customer = useAppSelector(selectCustomer);
  const physician = useMemo(() => customer.get('physician')?.toJS(), [customer]);
  const firstName = customer.get('first_name');
  const email = customer.get('email');
  const elation_patient_id = customer.get('elation_patient_id');
  const dispatch = useAppDispatch();
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const isUpdatesPending = useMemo(
    () => customer.get('flags', List()).includes('sensitive_updates_pending'),
    [customer],
  );

  const onSubmitSuccess = (_, stateAfterSuccess) => {
    setSubmitInProgress(false);
    if (onSuccessActionCreator) {
      onSuccessActionCreator(_, stateAfterSuccess);
    }
  };

  const resetBookingListener = async () => {
    const cal = await getCalApi();
    cal('on', {
      action: 'bookingSuccessful',
      callback: (e) => {
        if (submitInProgress) return;

        setSubmitInProgress(true);
        // `data` is properties for the event.
        // `type` is the name of the action
        // `namespace` is the Cal namespace for which the event is fired

        const { data } = e.detail;
        handleSubmit(data);
      },
    });
    cal('on', {
      action: 'rescheduleBookingSuccessful',
      callback: (e) => {
        if (submitInProgress) return;

        setSubmitInProgress(true);

        const { data } = e.detail;
        handleSubmit(data);
        setRescheduleCompleted(true);
      },
    });
  };

  const currentAppointment = customer.get('current_appointment');
  const currentAppointmentId = currentAppointment?.get('booking_uid');

  const cmdType = currentAppointmentId ? 'reschedule_appointment' : 'create_appointment';

  useEffect(() => {
    if (isUpdatesPending) return;
    resetBookingListener();
  }, [isUpdatesPending, currentAppointmentId]);

  const handleSubmit = (appointmentData) => {
    if (currentAppointmentId) {
      appointmentData.booking_uid = currentAppointmentId;
    }
    const action = apiRequestUserCommand({
      cmdType: cmdType,
      params: {
        product_name: AvailableProducts.King,
        data: JSON.stringify(appointmentData),
      },
      context: { onSubmitSuccess },
    });

    dispatch(action);
  };

  const caldotcomUrl = currentAppointmentId ? `reschedule/${currentAppointmentId}` : physician?.caldotcom_url;
  const newVersion = useRedesign();

  return newVersion ? (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <h3 className="ml-10 sm:ml-5">{currentAppointmentId ? 'Reschedule Appointment' : 'Schedule Appointment'}</h3>
      <div className="content">
        {isUpdatesPending || !caldotcomUrl || !elation_patient_id ? (
          <Spinner isCenter />
        ) : (
          <Cal
            calLink={caldotcomUrl}
            style={{ width: '100%', height: '100%', overflow: 'scroll' }}
            config={{
              layout: 'month_view',
              name: firstName,
              email: email,
            }}
          />
        )}
      </div>
    </PageWrapper>
  ) : (
    <div className="centered mb24 updated-design payments">
      <PageHeader
        title={currentAppointmentId ? 'Reschedule Appointment' : 'Schedule Appointment'}
        className="treatment_plan__header mb24 -edged"
      />
      <div className="content">
        {isUpdatesPending || !caldotcomUrl || !elation_patient_id ? (
          <Spinner isCenter />
        ) : (
          <Cal
            calLink={caldotcomUrl}
            style={{ width: '100%', height: '100%', overflow: 'scroll' }}
            config={{
              layout: 'month_view',
              name: firstName,
              email: email,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ScheduleAppointmentForm;
