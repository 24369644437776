import { P } from 'app/components/common/Typography';
import React from 'react';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

export const OralTestosteroneInfo = ({ withoutTitle = false }) => {
  const newVersion = useRedesign();

  return newVersion ? (
    <>
      {!withoutTitle && <h6 className="underline mt-2 mb-4">Oral Testosterone</h6>}

      <P className="bold">What is Oral Testosterone?</P>
      <P className="mb12">
        Oral TRT is a compounded form of native testosterone taken orally to improve symptoms of hypogonadism.
      </P>
      <P className="bold">Who should not take Oral Testosterone?</P>
      <P className="mb12">
        Do not take oral testosterone if you:
        <ul className="disc-list">
          <li>Are allergic to testosterone or any of the ingredients in oral testosterone.</li>
          <li>Have or might have prostate cancer.</li>
          <li>Have breast cancer.</li>
          <li>Are under 18 years of age.</li>
        </ul>
      </P>

      <P className="bold">What should I tell my healthcare provider before taking Oral Testosterone?</P>
      <P className="mb12">
        Before you take oral testosterone, tell your healthcare provider if you:
        <ul className="disc-list">
          <li>Have heart problems.</li>
          <li>Have kidney or liver problems.</li>
          <li>Have high red blood cell counts (hematocrit).</li>
          <li>Have urinary problems due to an enlarged prostate.</li>
          <li>Have a history of blood clots.</li>
          <li>Have sleep apnea.</li>
          <li>Have any other medical conditions.</li>
        </ul>
        Tell your healthcare provider about all the medicines you take, including prescription and over-the-counter
        medicines, vitamins, and herbal supplements.
      </P>

      <P className="bold">How should I take Oral Testosterone?</P>
      <P className="mb12">
        <ul className="disc-list">
          <li>Take oral testosterone exactly as your healthcare provider tells you to take it.</li>
          <li>Take the medication with fat to help your body absorb it properly.</li>
          <li>Do not change your dose without talking to your healthcare provider.</li>
          <li>
            If you miss a dose, take it as soon as you remember. If it's almost time for your next dose, skip the missed
            dose.
          </li>
        </ul>
      </P>

      <P className="bold">What are the possible side effects of Oral Testosterone?</P>
      <P className="mb12">
        <ul className="disc-list">
          <li>Common side effects include:</li>
          <li>Acne</li>
          <li>
            A slight increase in red blood cell count (hematocrit), although less than with other forms of testosterone
          </li>
          <li>Reduced fertility and testicular function, if not combined with enclomiphene</li>
          <li>Hair loss</li>
          <li>Weight gain</li>
        </ul>
        <ul className="disc-list">
          <li>Less Common side effects include:</li>
          <li>Mood changes, including irritability or aggression</li>
          <li>Swelling of ankles or feet due to fluid retention</li>
          <li>Breast enlargement or tenderness (gynecomastia)</li>
          <li>Sleep disturbances, including sleep apnea</li>
        </ul>
        <ul className="disc-list">
          <li>Serious side effects include:</li>
          <li>Blood clots in the legs or lungs</li>
          <li>Allergic reactions, such as rash, itching, or swelling</li>
        </ul>
      </P>

      <P className="bold">Seek emergency medical attention if you experience:</P>
      <P className="mb12">
        <ul className="disc-list">
          <li>
            Signs of an allergic reaction: hives, difficulty breathing, swelling of the face, lips, tongue, or throat
          </li>
          <li>Chest pain or pressure</li>
          <li>Shortness of breath</li>
          <li>Sudden weakness or numbness on one side of the body</li>
          <li>Vision or speech problems</li>
        </ul>
      </P>

      <P className="bold">What else should I know about Oral Testosterone?</P>
      <P className="mb12">
        <ul className="disc-list">
          <li>Store at room temperature between 59°F to 86°F (15°C to 30°C).</li>
          <li>Keep the medication in its original container, tightly closed, and protect it from moisture.</li>
          <li>Keep out of reach of children.</li>
        </ul>
      </P>

      <P className="bold">General information about the safe and effective use of Oral Testosterone</P>
      <P className="mb12">
        Medicines are sometimes prescribed for purposes other than those listed in this patient information leaflet. Do
        not use oral testosterone for a condition for which it was not prescribed. Do not give oral testosterone to
        other people, even if they have the same symptoms you have. It may harm them.
      </P>
    </>
  ) : (
    <>
      {!withoutTitle && <h5 className="subtitle">Oral Testosterone</h5>}

      <P className="bold">What is Oral Testosterone?</P>
      <P className="mb12">
        Oral TRT is a compounded form of native testosterone taken orally to improve symptoms of hypogonadism.
      </P>
      <P className="bold">Who should not take Oral Testosterone?</P>
      <P className="mb12">
        Do not take oral testosterone if you:
        <ul className="modal-list">
          <li>Are allergic to testosterone or any of the ingredients in oral testosterone.</li>
          <li>Have or might have prostate cancer.</li>
          <li>Have breast cancer.</li>
          <li>Are under 18 years of age.</li>
        </ul>
      </P>

      <P className="bold">What should I tell my healthcare provider before taking Oral Testosterone?</P>
      <P className="mb12">
        Before you take oral testosterone, tell your healthcare provider if you:
        <ul className="modal-list">
          <li>Have heart problems.</li>
          <li>Have kidney or liver problems.</li>
          <li>Have high red blood cell counts (hematocrit).</li>
          <li>Have urinary problems due to an enlarged prostate.</li>
          <li>Have a history of blood clots.</li>
          <li>Have sleep apnea.</li>
          <li>Have any other medical conditions.</li>
        </ul>
        Tell your healthcare provider about all the medicines you take, including prescription and over-the-counter
        medicines, vitamins, and herbal supplements.
      </P>

      <P className="bold">How should I take Oral Testosterone?</P>
      <P className="mb12">
        <ul className="modal-list">
          <li>Take oral testosterone exactly as your healthcare provider tells you to take it.</li>
          <li>Take the medication with fat to help your body absorb it properly.</li>
          <li>Do not change your dose without talking to your healthcare provider.</li>
          <li>
            If you miss a dose, take it as soon as you remember. If it's almost time for your next dose, skip the missed
            dose.
          </li>
        </ul>
      </P>

      <P className="bold">What are the possible side effects of Oral Testosterone?</P>
      <P className="mb12">
        <ul className="modal-list">
          <li>Common side effects include:</li>
          <li>Acne</li>
          <li>
            A slight increase in red blood cell count (hematocrit), although less than with other forms of testosterone
          </li>
          <li>Reduced fertility and testicular function, if not combined with enclomiphene</li>
          <li>Hair loss</li>
          <li>Weight gain</li>
        </ul>
        <ul className="modal-list">
          <li>Less Common side effects include:</li>
          <li>Mood changes, including irritability or aggression</li>
          <li>Swelling of ankles or feet due to fluid retention</li>
          <li>Breast enlargement or tenderness (gynecomastia)</li>
          <li>Sleep disturbances, including sleep apnea</li>
        </ul>
        <ul className="modal-list">
          <li>Serious side effects include:</li>
          <li>Blood clots in the legs or lungs</li>
          <li>Allergic reactions, such as rash, itching, or swelling</li>
        </ul>
      </P>

      <P className="bold">Seek emergency medical attention if you experience:</P>
      <P className="mb12">
        <ul className="modal-list">
          <li>
            Signs of an allergic reaction: hives, difficulty breathing, swelling of the face, lips, tongue, or throat
          </li>
          <li>Chest pain or pressure</li>
          <li>Shortness of breath</li>
          <li>Sudden weakness or numbness on one side of the body</li>
          <li>Vision or speech problems</li>
        </ul>
      </P>

      <P className="bold">What else should I know about Oral Testosterone?</P>
      <P className="mb12">
        <ul className="modal-list">
          <li>Store at room temperature between 59°F to 86°F (15°C to 30°C).</li>
          <li>Keep the medication in its original container, tightly closed, and protect it from moisture.</li>
          <li>Keep out of reach of children.</li>
        </ul>
      </P>

      <P className="bold">General information about the safe and effective use of Oral Testosterone</P>
      <P className="mb12">
        Medicines are sometimes prescribed for purposes other than those listed in this patient information leaflet. Do
        not use oral testosterone for a condition for which it was not prescribed. Do not give oral testosterone to
        other people, even if they have the same symptoms you have. It may harm them.
      </P>
    </>
  );
};
