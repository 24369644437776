import { trackEvent as SegmentTrackEvent } from 'app/utils/useSegmentTracking';
import { trackEvent as BrazeTrackEvent } from 'app/utils/useBrazeTracking';

export const trackEvent = (eventName: string, properties?: Record<string, any>) => {
  SegmentTrackEvent(eventName, {
    ...properties,
  });
  BrazeTrackEvent(eventName, {
    ...properties,
  });
};
