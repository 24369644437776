import React, { FC } from 'react';

import type { FieldProps } from 'formik';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import cx from 'classnames';

const FieldError: FC<FieldProps<any>> = ({ field, form }) => {
  const newVersion = useRedesign();

  if (!form.errors[field.name] || !form.touched[field.name]) {
    return null;
  }

  return <div className={cx({ 'hs2-text-input__error medium': !newVersion })}>{form.errors[field.name]}</div>;
};

export default FieldError;
