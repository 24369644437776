import React, { useContext, useEffect, useState } from 'react';
import { Map } from 'immutable';
import TitleWithLine from 'app/components/common/Typography/TitleWithLine';
import CreditsIcon from 'images/credit-cards.svg';
import CreditCheckIcon from 'images/credit-card-check.svg';
import { P, S } from 'app/components/common/Typography';
import Checkbox from 'app/components/common/Checkbox';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import { PrimaryButton, SecondaryButton } from 'app/components/common/Button';
import { NOOP, reportBrowserProblem } from 'app/actions/customer';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { Formik } from 'formik';
import Alert from 'app/components/common/Alert';
import * as actions from 'app/actions/customer';
import WhiteButton from 'app/components/common/WhiteButton';
import { ShippingSchemaWithoutFullName } from 'app/helpers/validators';
import {
  selectCustomer,
  selectCustomerBillingAddress,
  selectCustomerFlags,
  selectCustomerShippingAddress,
} from 'app/selectors/customer';
import BillingIcon from 'images/BillingPapers.svg';
import Divider from '@setproduct-ui/core/Divider';
import { ShippingFields } from 'app/components/customer/steps/Shipping';
import CardInput from './CardInput';
import { PaymentContext, PaymentContextUserInfo } from './PaymentContext/context';
import ApplePayIcon from 'app/components/customer/steps/Payment/ApplePay/ApplePayIcon';

import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Button } from 'mui';
import FormCheckbox from 'app/components/common/formik/FormCheckbox';
import CardsIcons from 'images/payment/cards-icons.svg';

const cmdType = 'add_stripe_payment_method';

const DumbPaymentMethodNew = ({ isOnboarding = false }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [showBillingUpdatedAlert, setShowBillingUpdatedAlert] = useState(false);
  const customer = useAppSelector(selectCustomer);
  const currentPaymentMethod = customer.get('payment_method');
  const [expanded, setExpanded] = useState(!currentPaymentMethod);
  const billingString = customer.get('billing_address_string');
  const shippingString = customer.get('shipping_address_string');
  const billingDifferent = billingString !== shippingString && !!billingString;
  const paymentErrorMessage = customer.get('latest_payment_method_error_message');
  const [errorMessage, setErrorMessage] = useState(paymentErrorMessage);
  const dispatch = useAppDispatch();
  const shippingAddress = useAppSelector(selectCustomerShippingAddress);
  const billingAddress = useAppSelector(selectCustomerBillingAddress);
  let targetAddress =
    billingAddress && !billingAddress.isEmpty() && billingString !== shippingString ? billingAddress : shippingAddress;
  if (!targetAddress) {
    targetAddress = Map();
  }
  const flags = useAppSelector(selectCustomerFlags);
  const pending = flags.includes('pending_payment');
  const { city = '', address_line_1 = '', address_line_2 = '', state = '', postal_code = '' } = targetAddress.toJS();
  const paymentContext = useContext(PaymentContext);
  const initialValues = targetAddress
    .merge({
      address_line_1,
      address_line_2,
      city,
      updateAddress: billingDifferent,
      state,
      postal_code,
    })
    .delete('full_name');
  useEffect(() => {
    if (paymentErrorMessage) {
      setErrorMessage(paymentErrorMessage);
    }
  }, [paymentErrorMessage]);
  const onSubmit = async (data, { setFieldValue }) => {
    const { city, state, address_line_1: line1, address_line_2: line2, postal_code: postalCode } = data;

    // We don't need name for this command type
    const params = {
      address_line_1: line1,
      address_line_2: line2,
      city,
      state,
      postal_code: postalCode,
    };
    if (expanded) {
      try {
        // We need `full_name` for creating the new payment method
        const result = await paymentContext?.createPaymentMethod({
          ...params,
          full_name: `${customer.get('first_name')} ${customer.get('last_name')}`,
        } as PaymentContextUserInfo);
        const action = actions.apiRequestUserCommand({
          cmdType,
          params: {
            ...params,
            card_brand: result?.cardBrand,
            card_last4: result?.cardLastFour,
            payment_method_id: result?.paymentMethodId,
            payment_method_source: paymentContext?.paymentProvider,
            payment_method_type: 'credit_card',
          },
          context: {},
        });
        dispatch(action);
        setShowAlert(true);
        setErrorMessage('');
      } catch (e) {
        dispatch(
          reportBrowserProblem({
            error: { code: 1 },
            problemType: `${paymentContext?.paymentProvider}_failed`,
            details: e,
          }),
        );
        setErrorMessage(e instanceof Error ? e.message : 'Something went wrong. Please try again.');
      }
    } else {
      // Send only billing info
      const action = actions.apiRequestUserCommand({
        cmdType,
        params,
        context: {
          onSuccessActionCreator: () => {
            setFieldValue('updateAddress', billingDifferent);
            setExpandBillingAddressForm(!billingDifferent);

            return { type: NOOP };
          },
        },
      });
      dispatch(action);
      setShowBillingUpdatedAlert(true);
    }
    setExpanded(false);
  };

  const newVersion = useRedesign();
  const [expandBillingAddressForm, setExpandBillingAddressForm] = useState(false);

  return newVersion ? (
    <Formik initialValues={initialValues.toJS()} onSubmit={onSubmit} validationSchema={ShippingSchemaWithoutFullName}>
      {({ handleSubmit, handleReset, values, setValues, isValid }) => (
        <>
          {!!currentPaymentMethod && (
            <>
              {currentPaymentMethod?.get('payment_method_type') === 'credit_card' && (
                <address className="not-italic">
                  <p>
                    <span className="uppercase">{currentPaymentMethod?.get('card_brand')}</span> **** **** ****{' '}
                    {currentPaymentMethod?.get('card_last4')}
                  </p>
                </address>
              )}
              {currentPaymentMethod?.get('payment_method_type') === 'apple_pay' && (
                <address>
                  <ApplePayIcon
                    style={{
                      width: 32 * 1.6,
                      height: 13.15 * 1.6,
                    }}
                  />
                </address>
              )}
            </>
          )}
          {!expanded && (
            <Button onClick={() => setExpanded(true)} variant={'outline'}>
              Update Payment Method
            </Button>
          )}
          {expanded && (
            <>
              <div>
                <CardInput />
                <div className="payment_method__accept">
                  <div>
                    <img src={CardsIcons} />
                  </div>
                  <div className="mt8">Including HSA/FSA cards</div>
                </div>
              </div>
              <div className="flex flex-col gap-y-2 lg:gap-y-4">
                <Button
                  onClick={() => {
                    setExpanded(false);
                    handleSubmit();
                  }}
                >
                  Update
                </Button>
                <Button variant="text" onClick={() => setExpanded(false)}>
                  Cancel
                </Button>
              </div>
            </>
          )}
          <FormCheckbox
            name="billing_address_different"
            label="Billing address different from shipping"
            labelClassName="inline-block pt-[2px]"
            disabled={false}
            checked={values.updateAddress}
            onChange={(e) => {
              if (e.target.checked) {
                handleReset();
              } else {
                setExpandBillingAddressForm(e.target.checked);
              }
              setValues({ ...values, updateAddress: e.target.checked });
            }}
          />
          {!expandBillingAddressForm && values.updateAddress && (
            <>
              <h5>
                <span className="font-bold">Billing address</span>
              </h5>
              <address>
                <p>{address_line_1}</p>
                {address_line_2 && <p>{address_line_2}</p>}
                <p>
                  {city}, {state} {postal_code}
                </p>
              </address>
              <div className="flex flex-col gap-y-2">
                <Button variant={'outline'} onClick={() => setExpandBillingAddressForm(true)}>
                  Update Billing Address
                </Button>
              </div>
            </>
          )}
          {expandBillingAddressForm && (
            <>
              <ShippingFields />
              <div className="flex flex-col gap-y-2">
                <Button
                  variant={'primary'}
                  onClick={() => {
                    handleSubmit();
                    isValid && setExpandBillingAddressForm(false);
                  }}
                >
                  Update
                </Button>
                <Button variant={'text'} onClick={() => setExpandBillingAddressForm(false)}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </Formik>
  ) : (
    <Formik initialValues={initialValues.toJS()} onSubmit={onSubmit} validationSchema={ShippingSchemaWithoutFullName}>
      {({ setFieldValue, handleSubmit, isValid, isSubmitting, handleReset, values, setValues }) => (
        <>
          <TitleWithLine className="mt48 mb16" size="xl">
            Payment Method
          </TitleWithLine>
          {showAlert && (
            <Alert onClose={() => setShowAlert(false)} className="mb24">
              Payment method updated successfully
            </Alert>
          )}
          {showBillingUpdatedAlert && (
            <Alert onClose={() => setShowBillingUpdatedAlert(false)} className="mb24">
              Billing address updated successfully
            </Alert>
          )}
          {errorMessage && (
            <Alert noClose type="error" className="mb24">
              {errorMessage}
            </Alert>
          )}
          <PaymentCard>
            <div className="payment_method_info">
              {!!currentPaymentMethod && (
                <div className="flex">
                  <div>
                    <img src={CreditsIcon} />
                  </div>
                  <div className="flex1">
                    <P>Your Card:</P>
                    <div className="card_info">
                      {currentPaymentMethod?.get('payment_method_type') === 'apple_pay' && (
                        <div className="type">
                          <ApplePayIcon
                            style={{
                              width: 32 * 1.6,
                              height: 13.15 * 1.6,
                            }}
                          />
                        </div>
                      )}
                      {currentPaymentMethod?.get('payment_method_type') === 'credit_card' && (
                        <>
                          <div className="type">{currentPaymentMethod?.get('card_brand')}</div>
                          <div>**** **** **** {currentPaymentMethod?.get('card_last4')}</div>
                        </>
                      )}
                      <div>
                        <img src={CreditCheckIcon} alt="" />
                      </div>
                    </div>
                    {!expanded && (
                      <WhiteButton
                        data-testid="update-payment-method"
                        onClick={() => setExpanded(true)}
                        className="payment_button mt24"
                        text="update card info"
                      />
                    )}
                  </div>
                </div>
              )}

              {expanded && (
                <>
                  {!isOnboarding && <hr />}
                  <CardInput />
                </>
              )}
              <hr />
              <div className="payment_method__billing_address new mb24">
                <div className="flex">
                  <div>
                    <img src={BillingIcon} />
                  </div>
                  <div className="flex1">
                    <Checkbox
                      className="flexAlignCenter"
                      label={<S small>Billing address different from shipping</S>}
                      checked={values.updateAddress}
                      onChange={(e) => {
                        if (values.updateAddress && !e.target.checked) {
                          handleReset();
                        }
                        setValues({ ...values, updateAddress: e.target.checked });
                      }}
                    />
                  </div>
                </div>
                {values.updateAddress && (
                  <>
                    <Divider />
                    <ShippingFields />
                  </>
                )}
                <hr />
              </div>
              {(expanded || values.updateAddress) && (
                <div className="flex">
                  <SecondaryButton text="Cancel" onClick={() => setExpanded(false)} />
                  <PrimaryButton
                    data-testid="submit"
                    onClick={() => {
                      setFieldValue('updateAddress', false);
                      handleSubmit();
                    }}
                    disabled={!isValid || isSubmitting || pending}
                    text="SUBMIT"
                  />
                </div>
              )}
            </div>
          </PaymentCard>
        </>
      )}
    </Formik>
  );
};

export default DumbPaymentMethodNew;
