import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import Card from '@setproduct-ui/core/Card';

import { Formik } from 'formik';
import ProgressBar from 'app/components/customer/questions/ProgressBar';
import RadioQuestion from 'app/components/customer/questions/Radio';
import { BlackTitle } from 'app/components/customer/questions/Title';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import * as selectors from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { apiRequestUserCommand } from 'app/actions/customer';
import Question from 'app/constants/Question';
import { useRedesign } from '../../../utils/redesign/RedesignProvider';
import { Badge, Card as MxCard, PageWrapper, ProgressBar as MxPb } from 'mui';
import protocolNames from 'app/utils/protocolNames';
import TextArea from 'app/components/common/formik/TextArea';

const RefillConditions = ({
  dispatch,
  previousStepAction,
  position,
  total,
  intake,
  hideBackButton,
  onSuccessActionCreator,
  productName,
}) => {
  const redirectPrevious = () => dispatch(previousStepAction);
  const onSubmit = (params) => {
    const name = 'conditions_and_medications';
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = apiRequestUserCommand({
      cmdType: 'answer_questions',
      params,
      context: { onSuccessActionCreator, form: name },
    });
    dispatch(action);
  };
  const refillConditionsQuestion = useMemo(() => new Question('refill_conditions'), []);

  const newVersion = useRedesign();

  return newVersion ? (
    <PageWrapper>
      <MxCard>
        <MxCard.Body>
          <MxPb progress={(100 * position) / total} />
          <div className="flex flex-row justify-between">
            <Badge variant={productName}>{protocolNames[productName]}</Badge>
            <span>
              {position + 1} of {total}
            </span>
          </div>
          <p>{refillConditionsQuestion.sentence}</p>
          <Formik initialValues={{ refill_conditions: null }} onSubmit={onSubmit}>
            {({ handleSubmit, setFieldValue, values }) => (
              <>
                <div className="flex flex-col gap-y-4 lg:gap-y-6">
                  <RadioQuestion
                    name="refill_conditions"
                    submit={(current_answer: string) => {
                      return current_answer.toLowerCase() != 'yes';
                    }}
                  />
                </div>
                {values.refill_conditions === 'yes' && (
                  <div className="text-area-question" key={refillConditionsQuestion.name}>
                    <TextArea
                      onChange={({ target: { value: content } }) => {
                        setFieldValue('refill_conditions_description', content);
                      }}
                      data-testid="other-textarea"
                      name="refill_conditions_description"
                      id="refill_conditions_description"
                      placeholder="Let us know here..."
                    />
                  </div>
                )}
                <BackNextFooter
                  noBack={hideBackButton}
                  onNext={handleSubmit}
                  onBack={redirectPrevious}
                  testId="submit-medical-conditions"
                />
              </>
            )}
          </Formik>
        </MxCard.Body>
      </MxCard>
    </PageWrapper>
  ) : (
    <>
      <ProgressBar title="Refill Conditions" position={position} total={total} />
      <Formik initialValues={{ refill_conditions: null }} onSubmit={onSubmit}>
        {({ handleSubmit, setFieldValue, values }) => (
          <div className="mb16">
            <BlackTitle question={refillConditionsQuestion} position={position} />
            <RadioQuestion
              name="refill_conditions"
              submit={(current_answer: string) => {
                return current_answer.toLowerCase() != 'yes';
              }}
            />
            {values.refill_conditions === 'yes' && (
              <Card view="raised" className="checkbox-component multi-select-question__other_card">
                <textarea
                  className="multi-select-question__other_textarea"
                  style={{ width: '100%' }}
                  onChange={({ target: { value: content } }) => {
                    setFieldValue('refill_conditions_description', content);
                  }}
                  data-testid="other-textarea"
                  placeholder="Let us know here..."
                />
              </Card>
            )}
            <BackNextFooter
              noBack={hideBackButton}
              onNext={handleSubmit}
              onBack={redirectPrevious}
              testId="submit-medical-conditions"
            />
          </div>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  const previousStepAction = push(selectors.selectPreviousStepPath(state));
  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectors.selectFurthestStepPath({ customer: stateAfterSuccess }));
  const prevStep = selectors.selectPreviousStep(state)?.toJS();
  const hideBackButton = !prevStep?.revisitable;
  const currentStrictlyIntakeSteps = selectors.selectStrictlyIntakeStepsByLastIntake(state);
  const stepObject = selectors.selectCurrentStepObject(state);
  const position = currentStrictlyIntakeSteps.indexOf(stepObject) + 1;
  const total = currentStrictlyIntakeSteps.size;
  const productName = selectors.selectCurrentIntakeProduct(state);

  return {
    position,
    total,
    intake: selectors.selectCurrentIntake(state),
    previousStepAction,
    hideBackButton,
    onSuccessActionCreator,
    productName,
  };
};

export default connect(mapStateToProps)(RefillConditions);
