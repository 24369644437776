import React from 'react';
import cx from 'classnames';
import { Button } from 'mui';
import WhiteButton from 'app/components/common/WhiteButton';
import { AvailableProducts, LabKitTypes } from 'app/constants/Products';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { P } from 'app/components/common/Typography';

type Props = {
  setOpenModalAtHomeLab: (openModalAtHomeLab: string | boolean) => void;
  openModalAtHomeLab?: string | null | boolean;
  productName?: string;
  kingV2OralTrt?: boolean;
};
const ModalBodyAtHomeLab = ({
  setOpenModalAtHomeLab,
  openModalAtHomeLab = null,
  productName,
  kingV2OralTrt = false,
}: Props) => {
  const newVersion = useRedesign();

  const encloPregAtHomeModalBody = (
    <>
      <P className={cx({ mb12: !newVersion })}>Baseline and follow-up lab testing is required in months 1 and 2.</P>
      <P className={cx({ mb12: !newVersion })}>
        If you choose to use Maximus’ at-home lab testing, the baseline lab charge occurs when you submit your payment
        information during your onboarding medical questionnaire. It costs $99.99.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        The follow-up lab charge occurs automatically 14 days after your first prescription ships. It costs $99.99.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        At-home lab kits are automatically ordered and delivered directly to your shipping address.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        Additional lab testing may be required by your doctor for ongoing treatment.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        Otherwise, you will be required to provide updated lab work on an annual basis thereafter.
      </P>
    </>
  );

  const encloPregOwnLabModalBody = (
    <>
      <P className={cx({ mb12: !newVersion })}>Baseline and follow-up lab testing is required in months 1 and 2.</P>

      <P className={cx({ mb12: !newVersion })}>
        If you choose to provide your own lab results (versus using Maximus’ at-home lab tests), you will need to do so
        during your onboarding medical questionnaire (baseline) and again three weeks into treatment (follow-up).
      </P>

      <P className={cx({ mb12: !newVersion })}>
        Additional lab testing may be required by your doctor for ongoing treatment.
      </P>

      <P className={cx({ mb12: !newVersion })}>
        Otherwise, you will be required to provide updated lab work on an annual basis thereafter.
      </P>
    </>
  );

  const oralTrtAtHomeModalBody = (
    <>
      <P className={cx({ mb12: !newVersion })}>
        Baseline and follow-up lab testing is required in month 1, month 2, and month 3
      </P>

      <P className={cx({ mb12: !newVersion })}>
        If you choose to use Maximus’ at-home lab testing, the baseline lab charge occurs when you submit your payment
        information during your onboarding medical questionnaire. It costs $149.99.
      </P>

      <P className={cx({ mb12: !newVersion })}>
        The first follow-up lab charge occurs automatically 14 days after your first prescription ships. It costs
        $99.99.
      </P>

      <P className={cx({ mb12: !newVersion })}>
        The second follow-up lab charge occurs automatically 14 days after your second prescription ships. It costs
        $149.99.
      </P>

      <P className={cx({ mb12: !newVersion })}>
        At-home lab kits are automatically ordered and delivered directly to your shipping address.
      </P>

      <P className={cx({ mb12: !newVersion })}>
        Additional lab testing may be required by your doctor for ongoing treatment.
      </P>

      <P className={cx({ mb12: !newVersion })}>
        Otherwise, you will be required to provide updated lab work on an annual basis thereafter.
      </P>
    </>
  );

  const oralTrtOwnLabModalBody = (
    <>
      <P className={cx({ mb12: !newVersion })}>
        Baseline and follow-up lab testing is required in month 1, month 2, and month 3
      </P>

      <P className={cx({ mb12: !newVersion })}>
        If you choose to provide your own lab results (versus using Maximus’ at-home lab tests), you will need to do so
        during your onboarding medical questionnaire (baseline), again three weeks into treatment (first follow-up), and
        once more seven weeks into treatment (second follow-up).
      </P>

      <P className={cx({ mb12: !newVersion })}>
        Additional lab testing may be required by your doctor for ongoing treatment.
      </P>

      <P className={cx({ mb12: !newVersion })}>
        Otherwise, you will be required to provide updated lab work on an annual basis thereafter.
      </P>
    </>
  );

  const magicianAtHomeModalBody = (
    <>
      <P className={cx({ mb12: !newVersion })}>Baseline and follow-up lab testing is required in months 1 and 3.</P>

      <P className={cx({ mb12: !newVersion })}>
        If you choose to use Maximus’ at-home lab testing, the baseline lab charge occurs when you submit your payment
        information during your onboarding medical questionnaire. It costs $65.00.
      </P>

      <P className={cx({ mb12: !newVersion })}>
        The follow-up lab charge occurs automatically 14 days after your third prescription ships. It costs $65.00.
      </P>

      <P className={cx({ mb12: !newVersion })}>
        At-home lab kits are automatically ordered and delivered directly to your shipping address.
      </P>

      <P className={cx({ mb12: !newVersion })}>
        Additional lab testing may be required by your doctor for ongoing treatment.
      </P>
    </>
  );

  const magicianOwnLabModalBody = (
    <>
      <P className={cx({ mb12: !newVersion })}>Baseline and follow-up lab testing is required in months 1 and 3.</P>

      <P className={cx({ mb12: !newVersion })}>
        If you choose to provide your own lab results (versus using Maximus’ at-home lab tests), you will need to do so
        during your onboarding medical questionnaire (baseline), and again eleven weeks into treatment (follow-up).
      </P>

      <P className={cx({ mb12: !newVersion })}>
        Additional lab testing may be required by your doctor for ongoing treatment.
      </P>
    </>
  );

  const kingWalkInModalBody = (
    <>
      <P className={cx({ mb12: !newVersion })}>
        A baseline lab test is required to begin treatment. A follow-up lab test is also required after the first month
        of treatment.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        The <b className="bold">baseline lab charge</b> for walk-in lab testing from Quest is due today when you submit
        your payment at checkout.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        The <b className="bold">follow-up lab charge</b> ($109.99) occurs automatically 14 days after your first
        prescription ships.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        You will be emailed a requisition form after you are successfully charged, which you will be able to take to a
        Quest location of your choosing to facilitate the test.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        Additional lab testing may be required by your doctor for ongoing treatment.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        Otherwise, you will be required to provide updated lab work on an annual basis thereafter.
      </P>
    </>
  );

  const kingOralTrtInModalBody = (
    <>
      <P className={cx({ mb12: !newVersion })}>
        A baseline lab test is required to begin treatment. Follow-up lab tests are also required after the first and
        second months of treatment.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        The <b className="bold">baseline lab charge</b> for walk-in lab testing from Quest is due today when you submit
        your payment at checkout.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        The <b className="bold">follow-up lab charges</b> ($109.99) occur automatically 14 days after your first and
        second prescriptions ship, respectively.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        You will be emailed a requisition form after you are successfully charged, which you will be able to take to a
        Quest location of your choosing to facilitate the test.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        Additional lab testing may be required by your doctor for ongoing treatment.
      </P>
      <P className={cx({ mb12: !newVersion })}>
        Otherwise, you will be required to provide updated lab work on an annual basis thereafter.
      </P>
    </>
  );

  const renderModalBody = () => {
    if (productName === AvailableProducts.King) {
      if (openModalAtHomeLab === LabKitTypes.AtHomeLabKit) {
        if (kingV2OralTrt) {
          return oralTrtAtHomeModalBody;
        } else {
          return encloPregAtHomeModalBody;
        }
      } else if (openModalAtHomeLab === LabKitTypes.WalkIn) {
        if (kingV2OralTrt) {
          return kingOralTrtInModalBody;
        } else {
          return kingWalkInModalBody;
        }
      } else {
        if (kingV2OralTrt) {
          return oralTrtOwnLabModalBody;
        } else {
          return encloPregOwnLabModalBody;
        }
      }
    } else {
      if (openModalAtHomeLab === LabKitTypes.AtHomeLabKit) {
        return magicianAtHomeModalBody;
      } else {
        return magicianOwnLabModalBody;
      }
    }
  };

  return (
    <>
      {renderModalBody()}
      {newVersion ? (
        <>
          <hr />
          <Button onClick={() => setOpenModalAtHomeLab(false)}>Ok</Button>
        </>
      ) : (
        <WhiteButton text="Close" onClick={() => setOpenModalAtHomeLab(false)} />
      )}
    </>
  );
};

export default ModalBodyAtHomeLab;
