import React, { useState } from 'react';
import ScheduleAppointmentForm from 'app/components/customer/steps/ScheduleAppointmentForm';
import { Redirect, useLocation } from 'react-router';
import { Root } from 'app/constants/Routes';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';
import * as Routes from 'app/constants/Routes';

const AppointmentPage = () => {
  const customer = useAppSelector(selectCustomer);
  const pendingAppointmentSchedule = customer.get('pending_appointment_schedule');
  const [rescheduleCompleted, setRescheduleCompleted] = useState(false);
  const location = useLocation();
  const isReschedule = location.pathname === Routes.ReScheduleAppointment;

  return (
    <>
      {!pendingAppointmentSchedule && (!isReschedule || rescheduleCompleted) && <Redirect to={Root} />}
      <ScheduleAppointmentForm setRescheduleCompleted={setRescheduleCompleted} />
    </>
  );
};

export default AppointmentPage;
