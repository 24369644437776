import { KingV2SubProducts } from 'app/constants/Products';

export const KingSubProductsConfig = {
  [KingV2SubProducts.Enclo]: {
    recommended_for:
      'Increasing your natural testosterone production without the use of synthetic hormones. No injections, creams, dependence, or fertility risk.',
    desc_list: [
      '1.5-2.5X your testosterone',
      'Simple, convenient once daily pill',
      'Boost testosterone naturally while maintaining fertility markers',
    ],
    active_ingredients: [
      {
        name: 'Enclomiphene',
        description: 'Selective estrogen receptor modulator (SERM) that stimulates natural testosterone production',
      },
    ],
    side_effects:
      'Some men may experience mild side effects such as headache, nausea, mood changes, or acne, but these are generally manageable and improve with continued use.',
  },
  [KingV2SubProducts.OralTrt]: {
    recommended_for:
      'Achieving peak testosterone levels without injections. These pills are designed to bypass the liver’s first-pass metabolism, reducing liver-related risks.',
    desc_list: [
      '2-5X your testosterone',
      'Once daily tablet taken with 30g of fat',
      'Boost testosterone without creams or needles',
    ],
    active_ingredients: [
      {
        name: 'Native Testosterone',
        description: 'unesterified bioidentical testosterone that mimics natural testosterone',
      },
    ],
    side_effects:
      'Some men may experience mild side effects such as acne, a slight increase in hematocrit (less than with other forms of TRT), reduced fertility if not paired with enclomiphene, hair loss, or weight gain. These effects are generally manageable and can be monitored during treatment.',
  },
  [KingV2SubProducts.EncloTrt]: {
    recommended_for:
      'Achieving peak testosterone levels without injections. This advanced oral treatment helps maintain fertility markers, and avoids dependency. These pills are designed to bypass the liver’s first-pass metabolism, reducing liver-related risks.',
    addon_title: 'Enclomiphene',
    addon_text:
      'Add Enclomiphene to preserve natural testosterone production, reduce risk of fertility issues, and minimize common TRT side effects.',
    active_ingredients: [
      {
        name: 'Native Testosterone',
        description: 'unesterified bioidentical testosterone that mimics natural testosterone',
      },
      {
        name: 'Enclomiphene',
        description:
          'SERM that maintains testicular function and fertility markers when combined with testosterone therapy',
      },
    ],
    side_effects:
      'Some men may experience mild side effects such as acne, a slight increase in hematocrit (less than with other forms of TRT), reduced fertility if not paired with enclomiphene, hair loss, or weight gain. These effects are generally manageable and can be monitored during treatment.',
  },
  [KingV2SubProducts.Topical]: {
    recommended_for:
      'Achieving peak testosterone levels without injections or pills. Simple topical cream to boost your levels effectively and conveniently.',
    desc_list: [
      '2-5X your testosterone',
      'Apply quickly and painlessly to the skin once daily',
      'Steady T levels without needles or oral tablets',
    ],
    active_ingredients: [
      {
        name: 'Native Testosterone',
        description: 'unesterified bioidentical testosterone that mimics natural testosterone',
      },
    ],
    side_effects:
      "Some men may experience mild skin irritation at the application site. There's also a minimal risk of transferring the medication to others through close skin contact, which can be managed by following proper application instructions. Additionally, topical TRT may suppress fertility and testicular function, but this effect can be mitigated by pairing it with enclomiphene. These effects are generally manageable and can be monitored during treatment.",
  },
  [KingV2SubProducts.EncloTopical]: {
    recommended_for:
      'Achieving peak testosterone levels without injections or pills. This advanced topical cream helps maintain fertility markers and avoids dependency, providing a convenient boost to your levels.',
    addon_title: 'Enclomiphene',
    addon_text:
      'Add Enclomiphene to preserve natural testosterone production, reduce risk of fertility issues, and minimize common TRT side effects.',
    active_ingredients: [
      {
        name: 'Native Testosterone',
        description: 'unesterified bioidentical testosterone that mimics natural testosterone',
      },
      {
        name: 'Enclomiphene',
        description:
          'SERM that maintains testicular function and fertility markers when combined with testosterone therapy',
      },
    ],
    side_effects:
      "Some men may experience mild skin irritation at the application site. There's also a minimal risk of transferring the medication to others through close skin contact, which can be managed by following proper application instructions. Additionally, topical TRT may suppress fertility and testicular function, but this effect can be mitigated by pairing it with enclomiphene. These effects are generally manageable and can be monitored during treatment.",
  },
  [KingV2SubProducts.Injectable]: {
    recommended_for: 'Achieving peak testosterone levels with traditional testosterone replacement.',
    desc_list: [
      '2-5X your testosterone',
      'Twice weekly pain-friendly, subcutaneous injection',
      'Achieve the highest testosterone boost available',
    ],
    active_ingredients: [
      {
        name: 'Esterified Testosterone',
        description: 'bioidentical testosterone paired with the cypionate ester for prolonged release',
      },
    ],
    side_effects:
      'Some men may experience mild side effects such as increased hematocrit (red blood cell count), acne, hair loss, mood changes, or slight fluid retention. Injectable TRT may also suppress fertility and testicular function. These effects are generally manageable and can be monitored during treatment.',
  },
  [KingV2SubProducts.EncloInjectable]: {
    recommended_for: 'Achieving peak testosterone levels with traditional testosterone replacement.',
    active_ingredients: [
      {
        name: 'Esterified Testosterone',
        description: 'bioidentical testosterone paired with the cypionate ester for prolonged release',
      },
      {
        name: 'Enclomiphene',
        description: 'SERM that may preserve fertility when combined with testosterone therapy',
      },
    ],
    side_effects:
      'Some men may experience mild side effects such as increased hematocrit (red blood cell count), acne, hair loss, mood changes, or slight fluid retention. These effects are generally manageable and can be monitored during treatment. Enclomiphene may help preserve fertility and testicular function that would otherwise be impacted with injectable TRT monotherapy. While enclomiphene may improve fertility outcomes, it doesn’t guarantee fertility, so monitoring and adjustments may be needed for optimal results.',
  },
  [KingV2SubProducts.InjectableHCG]: {
    recommended_for: 'Achieving peak testosterone levels with traditional testosterone replacement.',
    addon_title: 'hCG',
    addon_text:
      'Add HCG to maintain natural testosterone production, support fertility, and reduce testicular shrinkage.',
    active_ingredients: [
      {
        name: 'Esterified Testosterone',
        description: 'bioidentical testosterone paired with the cypionate ester for prolonged release',
      },
      {
        name: 'HCG',
        description:
          'Mimics LH to stimulate testosterone production and support testicular function and spermatogenesis when combined with testosterone therapy.',
      },
    ],
    side_effects:
      'Some men may experience mild side effects with TRT and hCG, including increased hematocrit (red blood cell count), acne, mood fluctuations, or fluid retention. These effects are typically manageable and can be monitored throughout treatment. hCG helps maintain testicular function and intratesticular testosterone levels, supporting fertility that may be impacted with TRT alone. While hCG can improve fertility outcomes, it doesn’t guarantee fertility, so regular monitoring and adjustments may be necessary for optimal results.',
  },
};
