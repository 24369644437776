import React, { ComponentProps, FC } from 'react';
import { useField } from 'formik';
import { FormCheckbox } from 'mui';
import CommonCheckbox from 'app/components/common/Checkbox';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

export const Checkbox: FC<
  {
    id?: string;
    name: string;
  } & ComponentProps<typeof CommonCheckbox>
> = ({ id, name, ...restProps }) => {
  const [{ value, onChange, onBlur }] = useField({ id, name });
  const newVersion = useRedesign();

  return newVersion ? (
    <FormCheckbox
      id={id}
      name={name}
      testid={id}
      checked={value}
      onChange={onChange}
      onFocus={onBlur}
      onBlur={onBlur}
      {...restProps}
    />
  ) : (
    <div>
      <CommonCheckbox
        id={id}
        name={name}
        testId={id}
        checked={value}
        onChange={onChange}
        onFocus={onBlur}
        onBlur={onBlur}
        {...restProps}
      />
    </div>
  );
};
