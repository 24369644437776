import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Login } from 'app/constants/Routes';
import { useAppDispatch } from 'app/helpers/hooks';
import { LoginSchema } from 'app/helpers/validators';
import { Formik, Form } from 'formik';
import { SanitizedInput } from 'app/components/common/formik/SanitizedInput';
import { PrimaryButton } from 'app/components/common/Button';
import Cookies from 'js-cookie';
import Header from 'app/components/customer/Header';
import Footer from 'app/components/customer/Footer';
import Logo from 'images/Crown-Logo.svg';
import { passwordReset, NOOP } from 'app/actions/customer';
import './css/Login.scss';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Card, Button, Drawer, NavBar, PageWrapper } from 'mui';
import DrawerMenu from 'app/components/common/DrawerMenu';
import { LoginHeader } from './Login';

const PasswordResetForm = () => {
  const newVersion = useRedesign();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values, form) => {
    const action = passwordReset({
      params: values,
      context: {
        onSuccessActionCreator: () => {
          setSuccess(true);

          return { type: NOOP };
        },
        onFailureActionCreator: () => {
          form.setErrors({ api: 'Internal error' });

          return { type: NOOP };
        },
      },
    });
    await dispatch(action);
  };

  return (
    <Formik initialValues={{ email: Cookies.get('email') || '' }} validationSchema={LoginSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors }) =>
        newVersion ? (
          <Form className="flex flex-col gap-y-6 lg:gap-y-8">
            <SanitizedInput
              id="email"
              name="email"
              placeholder="Enter email"
              label="Email address"
              className="text-field"
              disabled={success}
            />
            {errors?.api && <p className="text-center text-main-danger">{errors.api}</p>}
            {success && <p className="text-center">A reset link has been sent to you via e-mail.</p>}
            <div className="flex flex-col gap-y-2">
              <Button type="submit" data-testid="reset-password-submit" disabled={isSubmitting || success}>
                Reset Password
              </Button>
              <Button variant="text" onClick={() => history.push(Login)}>
                Back to Member Login
              </Button>
            </div>
          </Form>
        ) : (
          <Form>
            <div className="login-field">
              <SanitizedInput id="email" name="email" placeholder="E-mail" className="text-field" disabled={success} />
            </div>
            {success && <p className="login-options__text">A reset link has been sent to you via e-mail.</p>}
            <PrimaryButton
              type="submit"
              data-testid="reset-password-submit"
              text="reset password"
              disabled={isSubmitting || success}
            />
            {errors && <p className="login-options__text login-options__text-error">{errors.api}</p>}
          </Form>
        )
      }
    </Formik>
  );
};

const PasswordReset = () => {
  const newVersion = useRedesign();

  return newVersion ? (
    <Drawer sideContent={<DrawerMenu />}>
      <NavBar />
      <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
        <LoginHeader />
        <Card>
          <Card.Body>
            <h4>Reset Password</h4>
            <p>Enter your email and we’ll send you a link to reset your password.</p>
            <PasswordResetForm />
          </Card.Body>
        </Card>
      </PageWrapper>
    </Drawer>
  ) : (
    <div className="grid-container">
      <Header />
      <div className="login">
        <div className="login__wrap">
          <div className="login-image" />
          <div className="login-form">
            <div className="login-form__wrap">
              <img src={Logo} className="login-form__icon" />
              <p className="login-form__title">
                Better Men
                <br />
                in Mind and Body
              </p>
              <p className="login-form__info">
                Maximus is a men’s health program using the latest psychology and medicine to optimize men’s health and
                performance.
              </p>
              <PasswordResetForm />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordReset;
