import React from 'react';
import { Card, Button } from 'mui';
import './css/ResourcesSlide.scss';

const ResourcesSlide = ({ title, description, btnText, btnLink, imageSrc, imageAlt = 'Resource Image' }) => {
  return (
    <Card className="slide-card-width mx-2 lg:mx-4">
      <img className="slide-card-image" src={imageSrc} alt={imageAlt} />
      <div className="p-5 lg:p-10">
        <h5 className="mb-2 lg:mb-4">{title}</h5>
        <p className="mb-4 lg:mb-8 min-h-[130px] sm:min-h-[100px] md:min-h-[40px] lg:min-h-0">{description}</p>
        <Button variant="primary">
          <a className="slide-button" target="_blank" href={btnLink} rel="noreferrer">
            {btnText}
          </a>
        </Button>
      </div>
    </Card>
  );
};

export default ResourcesSlide;
