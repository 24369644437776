/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, ReactNode, useCallback, useState } from 'react';

import Question from 'app/constants/Question';
import { useField, useFormikContext } from 'formik';
import Radio from 'app/components/common/Radio';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { IntakeRadio } from 'mui';

export const SingleRadioField: FC<{
  testId?: string;
  label: ReactNode;
  radioValue: string;
  className?: string;
  name: string;
}> = ({ testId, label, radioValue, className, name }) => {
  const [{ value }] = useField({ name });
  const { setFieldValue } = useFormikContext();

  return (
    <Radio
      checked={value === radioValue}
      label={label}
      testId={testId}
      className={className}
      onChange={() => {
        setFieldValue(name, radioValue);
      }}
    />
  );
};

type SubmitType = boolean | ((current_answer: string) => boolean);

const RadioQuestion = ({
  name,
  submit = true,
  question = null,
}: {
  name: string;
  submit?: SubmitType;
  question?: Question | null;
}) => {
  const questionObj = question ?? new Question(name);
  const [field] = useField({ id: questionObj.name, name: questionObj.name });
  const { value } = field;
  const { setFieldValue, submitForm, isSubmitting } = useFormikContext();
  const [disabled, setDisabled] = useState(false);
  const onSubmit = useCallback(() => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
      submitForm();
    }, 300);
  }, []);

  const newVersion = useRedesign();

  return newVersion ? (
    <div className="flex flex-col gap-y-4 lg:gap-y-6">
      {questionObj.options?.map((opt) => (
        <IntakeRadio
          key={opt.value as string}
          checked={opt.value === value}
          label={opt.label}
          disabled={isSubmitting}
          onClick={() => {
            if (isSubmitting || disabled) {
              return;
            }
            setFieldValue(questionObj.name, opt.value);

            const resSubmit = submit instanceof Function ? submit(opt.value as string) : submit;

            if (resSubmit) {
              onSubmit();
            }
          }}
          name={questionObj.name}
          testId={`question_${questionObj.name}_option_${opt.value}`}
        />
      ))}
    </div>
  ) : (
    <div>
      {questionObj.options?.map((opt) => (
        <div
          key={opt.value as string}
          className="mh16 pointer"
          onClick={() => {
            if (isSubmitting || disabled) {
              return;
            }
            setFieldValue(questionObj.name, opt.value);

            const resSubmit = submit instanceof Function ? submit(opt.value as string) : submit;

            if (resSubmit) {
              onSubmit();
            }
          }}
        >
          <Radio
            checked={opt.value === value}
            label={opt.label}
            disabled={isSubmitting}
            testId={`question_${questionObj.name}_option_${opt.value}`}
          />
        </div>
      ))}
    </div>
  );
};

export default RadioQuestion;
