import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router/immutable';
import { LoginSchema, LoginWithPasswordSchema } from 'app/helpers/validators';
import { Formik, Form } from 'formik';
import { selectRouterQueryNotice } from 'app/selectors/router';
import Input from 'app/components/common/formik/Input';
import { SanitizedInput } from 'app/components/common/formik/SanitizedInput';
import { PrimaryButton } from 'app/components/common/Button';
import * as Routes from 'app/constants/Routes';
import Cookies from 'js-cookie';
import Header from 'app/components/customer/Header';
import Footer from 'app/components/customer/Footer';
import Logo from 'images/Crown-Logo.svg';
import { loginWithEmail, loginWithPassword, hardRedirectRequested, NOOP } from 'app/actions/customer';
import './css/Login.scss';
import cx from 'classnames';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Button, Card, Drawer, NavBar, PageWrapper } from 'mui';
import DrawerMenu from 'app/components/common/DrawerMenu';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import TrustPilotPlaceholder from 'images/trustpilot_placeholder.svg';

export const LoginHeader = () => (
  <div className="flex flex-col gap-y-4 lg:gap-y-6">
    <h4>Unlock your peak performance</h4>
    <div className="flex flex-row items-center gap-x-2 text-xs">
      <img src={TrustPilotPlaceholder} className="h-6" />
      <span>300+ reviews</span>
    </div>
  </div>
);

const LoginWithLinkForm = ({ dispatch, notice, userEmail, setUserEmail, setLinkOption }) => {
  const newVersion = useRedesign();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values, form) => {
    const action = loginWithEmail({
      params: {
        ...values,
        anonymous_id: window.analytics?.user?.()?.anonymousId() || null,
      },
      context: {
        onSuccessActionCreator: () => {
          setSuccess(true);

          return { type: NOOP };
        },
        onFailureActionCreator: ({ json: { errors } }) => {
          if (errors) {
            for (const [key, value] of Object.entries(errors)) {
              form.setFieldError(key, value);
            }
          } else {
            form.setErrors({ api: 'Internal error' });
          }

          return { type: NOOP };
        },
      },
    });
    await dispatch(action);
  };

  const onChange = (e) => {
    if (e.target.name === 'email') {
      setUserEmail(e.target.value);
    }
  };

  return (
    <Formik initialValues={{ email: userEmail }} validationSchema={LoginSchema} onSubmit={onSubmit}>
      {({ isSubmitting, errors }) =>
        newVersion ? (
          <Form onChange={onChange} className="flex flex-col gap-y-6">
            <p>Enter your email and we’ll send you a login link so you can log in without using your password.</p>
            <SanitizedInput
              id="email"
              name="email"
              label="Email address"
              placeholder="E-mail"
              className="text-field"
              disabled={success}
              onInputChange={onChange}
            />
            {notice && !success && (
              <p className="login-options__text">{decodeURIComponent(notice.replace(/\+/g, '%20'))}</p>
            )}
            {success && <p className="text-center">A login link has been sent to you via e-mail.</p>}
            <div className="flex flex-col gap-y-2">
              <Button type="submit" data-testid="request-login-link-submit" disabled={isSubmitting || success}>
                Send Login Link
              </Button>
              <Button variant="text" onClick={() => setLinkOption(false)}>
                Login with Password
              </Button>
              <Button variant="text" onClick={() => dispatch(push(Routes.PasswordReset))}>
                Reset Password
              </Button>
            </div>
          </Form>
        ) : (
          <Form onChange={onChange}>
            <div className="login-field">
              <SanitizedInput
                id="email"
                name="email"
                placeholder="E-mail"
                className="text-field"
                disabled={success}
                onInputChange={onChange}
              />
            </div>
            {notice && !success && (
              <p className="login-options__text">{decodeURIComponent(notice.replace(/\+/g, '%20'))}</p>
            )}
            {success && <p className="login-options__text">A login link has been sent to you via e-mail.</p>}
            <PrimaryButton
              type="submit"
              data-testid="request-login-link-submit"
              text="Request login link"
              disabled={isSubmitting || success}
            />
            {errors && <p className="login-options__text login-options__text-error">{errors.api}</p>}
            {!success && (
              <p className="login-options__text">We’ll email you a login link for a password-free log in.</p>
            )}
          </Form>
        )
      }
    </Formik>
  );
};

const LoginWithPasswordForm = ({ dispatch, userEmail, setUserEmail, setLinkOption }) => {
  const newVersion = useRedesign();
  const onSubmit = async (values, form) => {
    const action = loginWithPassword({
      params: {
        ...values,
        anonymous_id: window.analytics?.user?.()?.anonymousId() || null,
      },
      context: {
        onSuccessActionCreator: ({ json: { path } }) => hardRedirectRequested(path),
        onFailureActionCreator: ({ json: { error } }) => {
          if (error === 'invalid') {
            form.setFieldError('email', ' ');
            form.setFieldError('password', 'Invalid e-mail or password.');
          } else {
            form.setErrors({ api: 'Internal error' });
          }

          return { type: NOOP };
        },
      },
    });
    await dispatch(action);
  };

  const onChange = (e) => {
    if (e.target.name === 'email') {
      setUserEmail(e.target.value);
    }
  };

  return (
    <Formik
      initialValues={{ email: userEmail, password: '' }}
      validationSchema={LoginWithPasswordSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors }) =>
        newVersion ? (
          <Form onChange={onChange} className="flex flex-col gap-y-6">
            <div className="flex flex-col gap-y-4">
              <SanitizedInput
                id="email"
                name="email"
                placeholder="E-mail"
                className="text-field"
                onInputChange={onChange}
                label="Email address"
              />
              <Input id="password" name="password" type="password" placeholder="Password" label="Password" />
              {errors?.api && <p className="login-options__text login-options__text-error">{errors.api}</p>}
            </div>
            <div className="flex flex-col gap-y-2">
              <Button type="submit" data-testid="log-in-submit" disabled={isSubmitting}>
                Log In
              </Button>
              <Button variant="text" onClick={() => setLinkOption(true)}>
                Use Password-Free Login Link
              </Button>
              <Button variant="text" onClick={() => dispatch(push(Routes.PasswordReset))}>
                Reset Password
              </Button>
            </div>
          </Form>
        ) : (
          <Form onChange={onChange}>
            <div className="login-field">
              <SanitizedInput
                id="email"
                name="email"
                placeholder="E-mail"
                className="text-field"
                onInputChange={onChange}
              />
            </div>
            <div className="login-field">
              <Input id="password" name="password" type="password" placeholder="Password" className="text-field" />
            </div>
            <PrimaryButton type="submit" data-testid="log-in-submit" text="Log in" disabled={isSubmitting} />
            {errors && <p className="login-options__text login-options__text-error">{errors.api}</p>}
            <p className="login-options__text">
              Forgot your password? <Link to={Routes.PasswordReset}>Click here</Link>.
            </p>
          </Form>
        )
      }
    </Formik>
  );
};

const LoginOptions = ({ linkOption, changeLinkOption }) => (
  <div className="login-options">
    {linkOption ? (
      <div className="login-options__item">
        <p className="login-options__text">You can log in using your email and password.</p>
        <PrimaryButton onClick={changeLinkOption} text="Use password instead" />
        <p className="login-options__text">
          Forgot your password? <Link to={Routes.PasswordReset}>Click here</Link>.
        </p>
      </div>
    ) : (
      <div className="login-options__item">
        <PrimaryButton onClick={changeLinkOption} text="Use login link instead" />
        <p className="login-options__text">We’ll email you a login link for a password-free log in.</p>
      </div>
    )}
  </div>
);

const TestErrorComponent = () => {
  if (true) {
    throw new Error('does not compute in render');
  }

  return <h1>component with error</h1>;
};

const TestErrorButtons = () => {
  const [showComponentWithError, setShowComponentWithError] = useState(false);

  return (
    <div style={{ display: 'none' }}>
      <button
        onClick={() => {
          throw new Error('does not compute');
        }}
      >
        error button (try to call undefined method)
      </button>
      <button onClick={() => setShowComponentWithError(true)}>error button (try to render component with error)</button>
      {showComponentWithError && <TestErrorComponent />}
    </div>
  );
};

const Login = () => {
  const dispatch = useAppDispatch();
  const notice = useAppSelector(selectRouterQueryNotice);
  const newVersion = useRedesign();
  const [linkOption, setLinkOption] = useState(!newVersion);
  const [userEmail, setUserEmail] = useState(Cookies.get('email') || '');

  const changeLinkOption = () => {
    setLinkOption(!linkOption);
  };

  return newVersion ? (
    <Drawer sideContent={<DrawerMenu />}>
      <NavBar />
      <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
        <LoginHeader />
        <Card>
          <Card.Body>
            <h4>Member Login</h4>
            {linkOption ? (
              <LoginWithLinkForm
                dispatch={dispatch}
                notice={notice}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                setLinkOption={setLinkOption}
              />
            ) : (
              <LoginWithPasswordForm
                dispatch={dispatch}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                setLinkOption={setLinkOption}
              />
            )}
          </Card.Body>
        </Card>
      </PageWrapper>
    </Drawer>
  ) : (
    <div className="grid-container">
      <Header />
      <div className="login">
        <div className="login__wrap">
          <div className="login-image" />
          <div className="login-form">
            <div className="login-form__wrap">
              <img src={Logo} className="login-form__icon" />
              <p className={cx('login-form__title', { 'leading-[4rem]': newVersion })}>
                Better Men
                <br />
                in Mind and Body
              </p>
              <p className="login-form__info">
                Maximus is a men’s health program using the latest psychology and medicine to optimize men’s health and
                performance.
              </p>
              {linkOption ? (
                <LoginWithLinkForm
                  dispatch={dispatch}
                  notice={notice}
                  userEmail={userEmail}
                  setUserEmail={setUserEmail}
                />
              ) : (
                <LoginWithPasswordForm dispatch={dispatch} userEmail={userEmail} setUserEmail={setUserEmail} />
              )}
              <div className="login-or">
                <span>OR</span>
              </div>
              <LoginOptions linkOption={linkOption} changeLinkOption={changeLinkOption} />
              <TestErrorButtons />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
