import React, { useContext, useMemo } from 'react';
import { Card } from 'mui';
import { last } from 'lodash';
import { ReactSVG } from 'react-svg';
import { useKeenSlider } from 'keen-slider/react';
import TitleWithIcon from 'app/components/customer/ProgressTracker/common/TitleWithIcon';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import protocolNames from 'app/utils/protocolNames';
import Carousel from 'app/components/carousel/Carousel';
import ResourcesSlide from 'app/components/customer/ProgressTracker/common/ResourcesSlide';
import * as selectors from 'app/selectors/customer';
import { useAppSelector } from 'app/helpers/hooks';
import { AvailableProducts } from 'app/constants/Products';
import { ProductContext } from 'app/components/customer/Product';
import { selectPharmacyChoice, selectCustomerProducts } from 'app/selectors/customer';
import { RootState } from 'app/configureStore';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import IconLamp from 'images/progress-tracker/Lamp.svg';
import IconArrowRight from 'images/progress-tracker/arrow-right.svg';
import ImageLabKit from 'images/progress-tracker/Ebook.png';
import KingGuide from 'images/progress-tracker/king_guide.png';
import MagicianGuide from 'images/progress-tracker/magician_guide.png';
import LoverGuide from 'images/progress-tracker/lover_guide_1.png';
import WarriorGuide from 'images/progress-tracker/warrior_guide.png';
import ImageDiscord from 'images/progress-tracker/Discord.png';
import './css/Resources.scss';

const ProgressTrackerResources = () => {
  const productCtx = useContext(ProductContext);
  const customerProducts = useAppSelector(selectCustomerProducts);
  const labsInfo = useAppSelector(selectors.selectCustomerLabOrders).toJS();
  const lastLabOrder = last(labsInfo);
  const pharmacy = useAppSelector((state: RootState) => selectPharmacyChoice(state, productCtx?.selectedProduct));

  const [refCallback] = useKeenSlider({
    slides: {
      perView: 1.2,
      spacing: 25,
    },
  });

  const getSliderRef = () => {
    if (window.innerWidth < 480) {
      return refCallback;
    }

    return null;
  };

  const kingGuideLink = useMemo(() => {
    const currentSubfriendlyName = customerProducts?.get('king')?.get('current_subscription')?.get('friendly_name');
    const nextSubfriendlyName = customerProducts.get('king')?.get('pending_subscription')?.get('friendly_name');
    // Check if either current or next subscription name includes 'Oral TRT'
    const hasOralTRT = nextSubfriendlyName?.includes('Oral TRT') || currentSubfriendlyName?.includes('Oral TRT');
    // Directly return based on conditions, prioritizing Oral TRT URL
    return productCtx?.selectedProduct === AvailableProducts.King
      ? hasOralTRT
        ? window.QUICKSTARTGUIDE_ORAL_TRT_URL // Oral TRT URL has the highest priority
        : lastLabOrder?.kit_id?.match('TASSO')
          ? window.QUICKSTARTGUIDE_TASSO_URL // Next priority is TASSO URL
          : window.QUICKSTARTGUIDE_URL // Default URL for King product
      : undefined; // Return undefined or another value if not the King product
  }, [productCtx?.selectedProduct, labsInfo]);

  const loverMedInstructionsLink = useMemo(() => {
    if (productCtx?.selectedProduct === AvailableProducts.Lover) {
      const urls = {
        tailormade: 'https://drive.google.com/file/d/1rOcayKCmCnuvlJuviCbhYdgEBrq99NSV/view',
        belmar: 'https://maximustribe.com/tadalfil-vardenafil-instructions',
        precision: 'https://maximustribe.com/tadalfil-vardenafil-instructions',
      };

      return urls[pharmacy] || urls.precision;
    }
  }, [productCtx?.selectedProduct, pharmacy]);

  const showGuideSlide = false; // TODO: remove this once we have the guide ready

  const oldDesignSystem = () => (
    <div className="progress_tracker__resources">
      <div className="progress_tracker__content">
        <TitleWithIcon icon={<ReactSVG src={IconLamp} className="svg-icon" />}>Resources</TitleWithIcon>
        <div ref={getSliderRef()} className="progress_tracker__resources__flex keen-slider">
          <div className="resource keen-slider__slide">
            <img src={ImageLabKit} />
            <div className="resource-content">
              <div className="resource__heading">The Maximus E-Book</div>
              <p className="resource__description">
                To learn more about testosterone and the steps you can take to maximize your masculinity, check out the
                Maximus E-Book.
              </p>
              <HeadingWithIcon icon={<ReactSVG src={IconArrowRight} className="svg-icon" />}>
                <a target="_blank" href={window.EBOOK_URL} rel="noreferrer">
                  VIEW E-BOOK
                </a>
              </HeadingWithIcon>
            </div>
          </div>
          {showGuideSlide && (
            <div className="resource keen-slider__slide">
              {productCtx?.selectedProduct === AvailableProducts.King && (
                <>
                  <img src={KingGuide} />
                  <div className="resource-content">
                    <div className="resource__heading">Quickstart Guide</div>
                    <p className="resource__description">
                      Get all the information you need about the {protocolNames['king']} Protocol: how to complete your
                      labs, when to take your medication, and getting started with coaching.
                    </p>
                    <HeadingWithIcon icon={<ReactSVG src={IconArrowRight} className="svg-icon" />}>
                      <a target="_blank" href={kingGuideLink} rel="noreferrer">
                        VIEW GUIDE
                      </a>
                    </HeadingWithIcon>
                  </div>
                </>
              )}
              {productCtx?.selectedProduct === AvailableProducts.Lover && (
                <>
                  <img src={LoverGuide} />
                  <div className="resource-content">
                    <div className="resource__heading">{protocolNames['lover']} Protocol Instructions</div>
                    <p className="resource__description">
                      Get all the information you need about the {protocolNames['lover']} Protocol: when to take your
                      medication, what results to expect, and more.
                    </p>
                    <HeadingWithIcon icon={<ReactSVG src={IconArrowRight} className="svg-icon" />}>
                      <a target="_blank" href={loverMedInstructionsLink} rel="noreferrer">
                        VIEW INSTRUCTIONS
                      </a>
                    </HeadingWithIcon>
                  </div>
                </>
              )}
              {productCtx?.selectedProduct === AvailableProducts.Warrior && (
                <>
                  <img src={WarriorGuide} />
                  <div className="resource-content">
                    <div className="resource__heading">{protocolNames['warrior']} Protocol Instructions</div>
                    <p className="resource__description">
                      Get all the information you need about the {protocolNames['warrior']} Protocol, such as when and
                      how to apply your medication.
                    </p>
                    <HeadingWithIcon icon={<ReactSVG src={IconArrowRight} className="svg-icon" />}>
                      <a target="_blank" href="https://maximustribe.com/warrior-instructions" rel="noreferrer">
                        VIEW INSTRUCTIONS
                      </a>
                    </HeadingWithIcon>
                  </div>
                </>
              )}
              {productCtx?.selectedProduct === AvailableProducts.Magician && (
                <>
                  <img src={MagicianGuide} />
                  <div className="resource-content">
                    <div className="resource__heading">{protocolNames['magician']} Protocol Instructions</div>
                    <p className="resource__description">
                      Get all the information you need about the {protocolNames['magician']} Protocol, such as when and
                      how to apply your medication.
                    </p>
                    <HeadingWithIcon icon={<ReactSVG src={IconArrowRight} className="svg-icon" />}>
                      <a target="_blank" href="https://maximustribe.com/weight-loss-guide" rel="noreferrer">
                        VIEW GUIDE
                      </a>
                    </HeadingWithIcon>
                  </div>
                </>
              )}
            </div>
          )}
          <div className="resource keen-slider__slide">
            <img src={ImageDiscord} />
            <div className="resource-content">
              <div className="resource__heading">Discord Community</div>
              <p className="resource__description">
                Discord is the social platform we use to offer coaching and peer discussions. Join the tribe today.
              </p>
              <div className="flex">
                <HeadingWithIcon icon={<ReactSVG src={IconArrowRight} className="svg-icon" />}>
                  <a target="_blank" className="hide-mobile" href={window.DISCORD_URL} rel="noreferrer">
                    JOIN DISCORD
                  </a>
                  <a target="_blank" className="hide-desktop" href={window.DISCORD_URL} rel="noreferrer">
                    JOIN
                  </a>
                </HeadingWithIcon>
                <HeadingWithIcon icon={<ReactSVG src={IconArrowRight} className="svg-icon" />}>
                  <a target="_blank" className="hide-mobile" href={window.DISCORD_DOWNLOAD_URL} rel="noreferrer">
                    DOWNLOAD APP
                  </a>
                  <a target="_blank" className="hide-desktop" href={window.DISCORD_DOWNLOAD_URL} rel="noreferrer">
                    DOWNLOAD
                  </a>
                </HeadingWithIcon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const newTheme = useRedesign();

  const newDesignSystem = () => {
    const slides = [
      <ResourcesSlide
        key="The Maximus E-Book"
        title="The Maximus E-Book"
        description="To learn more about testosterone and the steps you can take to maximize your masculinity, check out the
            Maximus E-Book."
        btnText="VIEW E-BOOK"
        btnLink={window.EBOOK_URL}
        imageSrc={ImageLabKit}
      />,
      ...(showGuideSlide
        ? [
            productCtx?.selectedProduct === AvailableProducts.Lover && (
              <ResourcesSlide
                key={`${protocolNames['lover']} Protocol Instructions`}
                title="Quickstart Guide"
                description={`Get all the information you need about the ${protocolNames['lover']} Protocol: when to take your medication, what
        results to expect, and more.`}
                btnText="VIEW INSTRUCTIONS"
                btnLink={loverMedInstructionsLink}
                imageSrc={LoverGuide}
              />
            ),
            productCtx?.selectedProduct === AvailableProducts.King && (
              <ResourcesSlide
                key="Quickstart Guide"
                title="Quickstart Guide"
                description={`Get all the information you need about the ${protocolNames['king']} Protocol: how to complete your
    labs, when to take your medication, and getting started with coaching`}
                btnText="VIEW GUIDE"
                btnLink={kingGuideLink}
                imageSrc={KingGuide}
              />
            ),
            productCtx?.selectedProduct === AvailableProducts.Warrior && (
              <ResourcesSlide
                key={`${protocolNames['warrior']} Protocol Instructions`}
                title={`${protocolNames['warrior']} Protocol Instructions`}
                description={`Get all the information you need about the ${protocolNames['warrior']} Protocol, such as when and
      how to apply your medication.`}
                btnText="VIEW INSTRUCTIONS"
                btnLink={'https://maximustribe.com/warrior-instructions'}
                imageSrc={WarriorGuide}
              />
            ),
            productCtx?.selectedProduct === AvailableProducts.Magician && (
              <ResourcesSlide
                key={`${protocolNames['magician']} Protocol Instructions`}
                title={`${protocolNames['magician']} Protocol Instructions`}
                description={`Get all the information you need about the ${protocolNames['magician']} Protocol, such as when and
                      how to apply your medication.`}
                btnText="VIEW INSTRUCTIONS"
                btnLink={'https://maximustribe.com/weight-loss-guide'}
                imageSrc={MagicianGuide}
              />
            ),
          ]
        : []),
      <ResourcesSlide
        key="Discord Community"
        title="Discord Community"
        description="Discord is the social platform we use to offer coaching and peer discussions. Join the tribe today."
        btnText="JOIN DISCORD"
        btnLink={window.DISCORD_URL}
        imageSrc={ImageDiscord}
      />,
    ].filter(Boolean);

    return (
      <div className="centered mt-12 lg:mt-16">
        <h3 className="ml-10 sm:ml-5">Resources</h3>
        <Card className="resources-card mt-4 lg:mt-6">
          <Carousel items={slides} />
        </Card>
      </div>
    );
  };

  return <>{newTheme ? newDesignSystem() : oldDesignSystem()}</>;
};
export default ProgressTrackerResources;
