import React from 'react';

import { useFormikContext } from 'formik';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import { ImmutableList } from 'app/types/admin';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import { SecondaryButton } from 'app/components/common/Button';
import { KingSubProductsConfig } from 'app/components/customer/steps/Payment/king/KingSubProductsConfig';
import { P } from 'app/components/common/Typography';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import subProductNames from 'app/utils/subProductNames';
import { kingMonthlyPriceExperiment } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import { Badge, Button, Card } from 'mui';
import Divider from '@setproduct-ui/core/Divider';
import cx from 'classnames';
import { useExperiment } from 'app/utils/useExperiment';

type Props = {
  availableSubProducts: ImmutableList<KingV2SubProducts> | null;
  onProtocolChosen: (product: string) => void;
};

const KingSubProductsList = ({ availableSubProducts, onProtocolChosen }: Props) => {
  const { setFieldValue } = useFormikContext();
  const newVersion = useRedesign();
  const experimentVariation = useExperiment('testosterone_pricing', 'testosterone_pricing_variation_0');

  const renderProductCard = (subProductName: any) => {
    const subProduct = KingSubProductsConfig[subProductName];

    return newVersion ? (
      <Card>
        <Card.Body>
          <figure className="h-[180px] lg:h-[240px] mb-4">
            <img className="h-full w-auto" src={ImagesMapping[AvailableProducts.King][subProductName]} alt="king v2" />
          </figure>
          <div className="text-center">
            <Badge variant={AvailableProducts.King} className="mb-2">
              {protocolNames[AvailableProducts.King]}
            </Badge>
            <h4>{subProductNames[AvailableProducts.King][subProductName]}</h4>
          </div>
          <P>
            <b className="bold">Recommended for: </b>
            {subProduct.recommended_for}
          </P>
          <Divider />
          <P className="text-center flex flex-row flex-center gap-x-1 mb-2">
            Plans starting at
            <PriceFormatter
              period="mo"
              size="small"
              price={kingMonthlyPriceExperiment(experimentVariation)[subProductName][12]}
            />
          </P>
          <Button
            data-testid="own-lab-modal-close"
            onClick={() => setFieldValue('selected_king_v2_product', subProductName)}
          >
            Continue
          </Button>
        </Card.Body>
      </Card>
    ) : (
      <PaymentCard className="product-selection mb32">
        <ProductHeader
          productName={AvailableProducts.King}
          subProductName={subProductName}
          cardStyle={false}
          smallImage={true}
        />
        <div className="price_row mb16">
          <h4 className="subtitle mt8">{subProductNames[AvailableProducts.King][subProductName]}</h4>
          <PriceFormatter price={kingMonthlyPriceExperiment(experimentVariation)[subProductName][12]} from={true} />
        </div>
        <P>
          <b className="bold">Recommended for: </b>
          {subProduct.recommended_for}
        </P>
        <SecondaryButton
          text="Continue"
          className="mt16"
          data-testid="own-lab-modal-close"
          onClick={() => {
            setFieldValue('selected_king_v2_product', subProductName);
            if (onProtocolChosen) {
              onProtocolChosen(subProductName);
            }
          }}
        />
      </PaymentCard>
    );
  };

  if (!availableSubProducts) return null;

  return (
    <div
      className={cx({
        'choose_product': !newVersion,
        'flex flex-col gap-y-6 lg:gap-y-8': newVersion,
      })}
    >
      {availableSubProducts.map((subProductName) => renderProductCard(subProductName))}
    </div>
  );
};

export default KingSubProductsList;
