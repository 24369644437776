import React from 'react';
import { CustomerUserImm, KingIntake, KingProduct } from 'app/types/admin/customerUser';
import { ImmutableMap } from 'app/types/admin';
import updateSubscriptionSteps from 'app/utils/updateSubscriptionSteps';
import { AvailableProducts, KingV2SubProducts, LabKitTypes } from 'app/constants/Products';
import BaseCheckoutHelper from 'app/components/customer/steps/Payment/Generic/BaseCheckoutHelper';
import * as Routes from 'app/constants/Routes';
import KingMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/king/KingMultimonthPlanSelectionForm';
import PriceCalculator from 'app/helpers/priceCalculator';
import KingPriceCalculator, {
  kingMonthlyPriceExperiment,
} from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import GenericSubscriptionSummary from 'app/components/customer/steps/Payment/Generic/GenericSubscriptionSummary';
import GenericSubscriptionSummaryKingV2 from 'app/components/customer/steps/Payment/Generic/GenericSubscriptionSummaryKingV2';
import PlanSelection from './PlanSelection';
import PageHeader from 'app/components/common/PageHeader';
import KingProductSelectionForm from 'app/components/customer/steps/Payment/king/KingProductSelectionForm';
import { push } from 'connected-react-router/immutable';

class KingCheckoutHelper extends BaseCheckoutHelper {
  intake: ImmutableMap<KingIntake>;
  product: ImmutableMap<KingProduct>;
  query_params: any;

  constructor(
    customer: CustomerUserImm,
    intake: ImmutableMap<KingIntake>,
    product: ImmutableMap<KingProduct>,
    isOnboarding: boolean,
    query_params: any,
  ) {
    super(customer, intake, isOnboarding);

    this.intake = intake;
    this.query_params = query_params;
    this.product = product;
  }

  currentProductName = () => AvailableProducts.King;

  recommendedSubProduct = () => this.intake.get('recommended_sub_product');

  recommendationSystemEnabled = () => true;

  kingV2OralTrt = () => this.isKingV2OralTrt(this.currentSubscriptionProduct());

  firstStep = () => (this.kingV2Offered() ? updateSubscriptionSteps.productStep : updateSubscriptionSteps.planStep);
  productSpecificInitialValues() {
    return {
      selected_king_v2_product:
        this.initiallySelectedKingV2Product() || this.recommendedSubProduct() || KingV2SubProducts.EncloPregnolone,
      multimonth_plan: this.product?.get('opt_in_choice')?.get('multimonth_plan') || 12,
      lab_kit_type: LabKitTypes.AtHomeLabKit,
      own_lab_file_uploaded: this.customer.get('own_lab_file_uploaded'),
    };
  }

  multimonthEnabled = () => true;
  updateSubProductCommandName = 'select_king_v2_product';

  showLabAndDueTodaySection = (params) => this.productChangedToProductTrt(params.selected_king_v2_product);

  redirectTo = (params) =>
    this.productChangedToProductTrt(params.selected_king_v2_product)
      ? Routes.ScheduleAppointment
      : Routes.ManageSubscription;

  renderProductStep = (firstTimeChoice: boolean = false, handleSubmit: any = null) => (
    <KingProductSelectionForm
      firstTimeChoice={firstTimeChoice}
      handleSubmit={handleSubmit}
      availableSubProducts={this.intake.get('available_sub_products')}
    />
  );

  renderProductStepHeader = (values): any => {
    if (values.selected_king_v2_product) {
      if (values.selected_king_v2_product == this.recommendedSubProduct()) {
        return <PageHeader title="Recommended Protocol" className="treatment_plan__header mb24 -edged" />;
      } else {
        return null;
      }
    }

    return <PageHeader title="Choose a Protocol" className="treatment_plan__header mb24 -edged" />;
  };

  renderMultimonthStep = (handleChangeProduct) => (
    <KingMultimonthPlanSelectionForm handleChangeProduct={handleChangeProduct} intake={this.intake} />
  );

  renderPlanSelection = (onMultimonthUpdate, subProductName, priceCalculator) => (
    <PlanSelection
      onMultimonthUpdate={onMultimonthUpdate}
      subProductName={subProductName}
      priceCalculator={priceCalculator}
    />
  );

  priceCalculator = (values: any, subscription_paid: boolean = false, lab_paid: boolean = false) => {
    const kingPriceCalculator = new KingPriceCalculator(
      values.include_supplement,
      this.supplementsOnAnotherIntake(),
      values.selected_king_v2_product,
      values.multimonth_plan,
      values.use_own_lab,
      this.intake.get('name'),
      this.oralTrtLabNumber(),
      this.crlFeatureEnabledEncloPregWeightLoss(),
      values.lab_kit_type,
      this.isOnboarding,
      subscription_paid,
      lab_paid,
      this.productChangedToProductTrt(values.selected_king_v2_product),
      this.customer.getIn(['experiments']),
      this.only_lab(),
    );

    const discounts = this.product.get('discounts');

    return new PriceCalculator(kingPriceCalculator, discounts);
  };

  specialDisabledConditions = (values, params) =>
    this.productChangedToProductTrt(values.selected_king_v2_product) &&
    values.use_own_lab &&
    (!values.own_lab_file_uploaded || !params.byolFormSubmitted);

  productChanged = (values) => this.currentSubscriptionProduct() !== values.selected_king_v2_product;

  subscriptionSummaryComponent = () =>
    this.kingV2Offered() ? GenericSubscriptionSummaryKingV2 : GenericSubscriptionSummary;

  selectProductStepPath = () => 'king_v2_product_selection';
  selectPlanStepPath = () => 'multimonth_plan_selection';

  checkoutBreadcrumbs = (dispatch, onPlanChange: any = null, onProductChange: any = null): any => {
    onProductChange ||= () => dispatch(push(this.selectProductStepPath()));
    onPlanChange ||= () => dispatch(push(this.selectPlanStepPath()));

    return (
      <p>
        {this.kingV2Offered() && (
          <a data-testid="change-product" onClick={onProductChange}>
            Change Product
          </a>
        )}
        {this.kingV2Offered() && ' | '}
        <a data-testid="change-plan" onClick={onPlanChange}>
          Change Plan
        </a>
      </p>
    );
  };

  currentSubscriptionOptInChoices = () => this.intake?.get('current_opt_in_choices');

  currentSubscriptionProduct = () => this.currentSubscriptionOptInChoices()?.get('selected_king_v2_product');

  isKingV2OralTrt = (subProductName) =>
    [KingV2SubProducts.OralTrt, KingV2SubProducts.EncloPregnoloneTrt].includes(subProductName);

  initiallySelectedKingV2Product = () =>
    this.query_params?.get('oral_trt') ? KingV2SubProducts.OralTrt : this.currentSubscriptionProduct();

  oralTrtLabNumber = () => this.intake.get('oral_trt_lab_number');

  only_lab = () => this.customer.get('only_lab');

  kingV2Offered = () => this.intake?.get('king_v2_offered');

  productChangedToProductTrt = (selectedSubProduct) =>
    this.isKingV2OralTrt(selectedSubProduct) && !this.kingV2OralTrt();

  subProductName = (values) => values.selected_king_v2_product;

  subProductNameForIntakeCheckout = () =>
    (this.kingV2Offered() && this.product?.get('opt_in_choice')?.get('selected_king_v2_product')) ||
    KingV2SubProducts.Enclo;

  showLabKitOnboarding = () => this.isOnboarding;

  showChangeProductButton = () => this.kingV2Offered();

  getExperimentVariation = (key: string) => {
    const experiment = this.customer.getIn(['experiments']).find((experiment) => experiment['experiment_id'] === key);
    return experiment && experiment['variation_value'];
  };

  multimonthPlansOptions = (subProductName) => {
    return [
      {
        value: 1,
        label: 'Monthly Plan',
      },
      {
        value: 3,
        label: 'Quarterly Plan',
      },
      {
        value: 12,
        label: 'Yearly Plan',
      },
    ].filter((o) =>
      Object.keys(
        kingMonthlyPriceExperiment(this.getExperimentVariation('testosterone_pricing'))[subProductName] ||
          kingMonthlyPriceExperiment(this.getExperimentVariation('testosterone_pricing'))[KingV2SubProducts.Enclo],
      ).includes(String(o.value)),
    );
  };

  subscriptionPaidSuccessfuly = () => this.intake.get('customer_subscription_successful');

  showOwnLabSection = () => this.product?.get('lab_kit_type') === LabKitTypes.UseYourOwnLab;

  useOwnLab = () => !!this.customer.get('use_own_lab');

  showProductSelectionStepNextButton = () => false;

  trackRecommendationGenerated = () => {
    if (this.recommendedSubProduct()) {
      this.trackEvent('Recommendation Generated', {
        protocol: this.currentProductName(),
        product: this.recommendedSubProduct(),
      });
    }
  };
}

export default KingCheckoutHelper;
