import React, { ComponentProps, FC, forwardRef } from 'react';
import { useField } from 'formik';
import { FormField } from 'mui';
import CommonInput from 'app/components/common/Input';

export const FormInput: FC<
  {
    id?: string;
    name: string;
  } & ComponentProps<typeof CommonInput>
> = forwardRef(({ id, name, ...restProps }, ref) => {
  const [field, { touched, error }] = useField({ id, name });

  return (
    <FormField
      ref={ref}
      errorText={(touched && error) || undefined}
      valid={!touched || (!error && Boolean(!error))}
      testId={id}
      {...field}
      {...restProps}
    />
  );
});

export default FormInput;
