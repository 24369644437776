import { PrimaryButton } from 'app/components/common/Button';
import MoneyBackImage from 'images/having-troubles-modal-bg.jpg';
import React from 'react';
import { P } from 'app/components/common/Typography';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Button } from 'mui';

const ModalHavingTrouble: React.FC<{ setHavingTroubleModalOpen: React.SetStateAction<any> }> = ({
  setHavingTroubleModalOpen,
}) => {
  const newVersion = useRedesign();

  return newVersion ? (
    <div className="referral-source-modal__body">
      <div className="referral-source-modal__content">
        <h5 className="mb-4">I'm having trouble taking or uploading a picture</h5>
        <P className="mb16">
          If you started your visit from within Facebook, Instagram or another application, it is sometimes not possible
          to access the camera to take a photo for your visit. Try resuming your visit in another browser like Safari or
          Chrome, or on your desktop computer.
        </P>

        <P className="mb16">
          If you have tried to submit your photos and it seems like nothing is happening, this can sometimes come down
          to the small print. Our website will quickly guide you through the process and request several photos in a
          row. Please read each page carefully, as after every refresh we will ask you for a different photo.
        </P>

        <P>
          If the problem persists please contact our concierge team by emailing{' '}
          <a href="mailto:support@maximustribe.com">support@maximustribe.com</a> or using the blue chat box on the
          bottom right of your screen.
        </P>
        <Button className="mt-8" onClick={() => setHavingTroubleModalOpen(false)}>
          Close
        </Button>
      </div>
      <img src={MoneyBackImage} className="referral-source-modal__image" />
    </div>
  ) : (
    <div className="referral-source-modal__body">
      <div className="referral-source-modal__content">
        <h2 className="money-back-modal-title">I'm having trouble taking or uploading a picture</h2>
        <P className="mb16">
          If you started your visit from within Facebook, Instagram or another application, it is sometimes not possible
          to access the camera to take a photo for your visit. Try resuming your visit in another browser like Safari or
          Chrome, or on your desktop computer.
        </P>

        <P className="mb16">
          If you have tried to submit your photos and it seems like nothing is happening, this can sometimes come down
          to the small print. Our website will quickly guide you through the process and request several photos in a
          row. Please read each page carefully, as after every refresh we will ask you for a different photo.
        </P>

        <P>
          If the problem persists please contact our concierge team by emailing{' '}
          <a href="mailto:support@maximustribe.com">support@maximustribe.com</a> or using the blue chat box on the
          bottom right of your screen.
        </P>
        <PrimaryButton onClick={() => setHavingTroubleModalOpen(false)} className="mt48" text="Close" />
      </div>
      <img src={MoneyBackImage} className="referral-source-modal__image" />
    </div>
  );
};

export default ModalHavingTrouble;
