import React, { ComponentProps, FC, forwardRef } from 'react';
import cx from 'classnames';
import { FormField } from 'mui';
import TextInput from 'app/components/common/TextInput';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import './css/InputMask.scss';
import './css/Input.scss';

export const InputMask: FC<
  {
    placeholder?: string;
    displayFormatter: (value: string) => string;
    mask: string;
    onChange?: (string: string) => void;
    value?: string;
  } & ComponentProps<typeof Input>
> = ({ placeholder, displayFormatter, mask, value, onChange: valueChange = () => {}, ...restProps }) => {
  const onChange = (e) => valueChange(displayFormatter(e.target.value));
  const needsMask = !placeholder || (placeholder && value !== '');
  const newVersion = useRedesign();

  return (
    <div className="input_mask__shell">
      <Input
        insideElement={
          <span
            aria-hidden="true"
            className={cx('hs2-text-input__input', restProps.className, restProps.size, {
              hidden: newVersion,
              input_mask__span: !newVersion,
            })}
          >
            <i>{value}</i>
            {needsMask && mask.substr(value?.length || 0)}
          </span>
        }
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        {...restProps}
      />
    </div>
  );
};

const Input: FC<
  {
    testId?: string;
    size?: string;
    className?: string;
    success?: boolean;
  } & ComponentProps<typeof TextInput>
> = forwardRef(({ testId, size = 'medium', className, success, ...restProps }, ref) => {
  const newVersion = useRedesign();

  return newVersion ? (
    <FormField
      ref={ref}
      type="text"
      data-testid={testId}
      className={cx('form-field-input', className)}
      valid={success || !restProps?.error}
      {...restProps}
    />
  ) : (
    <TextInput
      ref={ref}
      className={cx('input', className, size)}
      active
      view="raised"
      color="default"
      valid={success}
      data-testid={testId}
      {...restProps}
    />
  );
});

export default Input;
