import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';
import { CustomerUserImm } from 'app/types/admin/customerUser';

export function useExperiment(key: string, defaultValue: string, customer: CustomerUserImm | null = null): string {
  customer ||= useAppSelector(selectCustomer);
  if (!customer) return defaultValue;
  const experiments = customer.get('experiments');
  const currentExperiment = experiments.find((e) => e.get('experiment_id') === key);
  const variationValue = currentExperiment && currentExperiment.get('variation_value');

  return variationValue ?? defaultValue;
}
