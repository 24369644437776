import TitleWithLine from 'app/components/common/Typography/TitleWithLine';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import LabOnboardingWidget from './LabOnboardingWidget';
import PriceFormatter from 'app/components/common/PriceFormatter';
import React from 'react';
import Divider from '@setproduct-ui/core/Divider';
import PriceCalculator from 'app/helpers/priceCalculator';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

type Props = {
  setOpenModalAtHomeLab: any;
  use_own_lab: boolean | null;
  lab_kit_type?: string;
  setFieldValue: any;
  own_lab_file_uploaded: any;
  inputFile: any;
  setOwnLabFile: any;
  setOpenModalOwnLab: any;
  intake: any;
  priceCalculator?: PriceCalculator;
  kingV2OralTrt?: any;
  byolFormSubmitted?: any;
  labSelectionStep?: any;
};
const LabWidget = ({
  setOpenModalAtHomeLab,
  use_own_lab,
  lab_kit_type = '',
  setFieldValue,
  own_lab_file_uploaded,
  inputFile,
  byolFormSubmitted = null,
  setOwnLabFile,
  setOpenModalOwnLab,
  intake,
  priceCalculator,
  kingV2OralTrt,
  labSelectionStep,
}: Props) => {
  let totalLabPrice = 0;
  if (priceCalculator) {
    totalLabPrice = priceCalculator.labCost() / 100;
  }

  const newVersion = useRedesign();

  return newVersion ? (
    <>
      <h3>{labSelectionStep || kingV2OralTrt ? 'Lab Testing' : 'Baseline At-Home Lab Kit'}</h3>
      {(labSelectionStep || kingV2OralTrt) && (
        <p>
          Your Maximus doctor requires lab testing to get started and again after a month of treatment in order to
          customize your treatment plan.
        </p>
      )}

      <LabOnboardingWidget
        product_name={intake.get('product_name')}
        setOpenModalAtHomeLab={setOpenModalAtHomeLab}
        use_own_lab={use_own_lab}
        lab_kit_type={lab_kit_type}
        priceCalculator={priceCalculator}
        setFieldValue={setFieldValue}
        own_lab_file_uploaded={own_lab_file_uploaded}
        inputFile={inputFile}
        setOwnLabFile={setOwnLabFile}
        setOpenModalOwnLab={setOpenModalOwnLab}
        byolFormSubmitted={byolFormSubmitted}
        kingV2OralTrt={kingV2OralTrt}
        labSelectionStep={labSelectionStep}
      />
    </>
  ) : (
    <>
      <TitleWithLine className={labSelectionStep ? 'mt32 mb16' : 'mt52'} size="l">
        {labSelectionStep || kingV2OralTrt ? 'Lab Testing' : 'Baseline At-Home Lab Kit'}
      </TitleWithLine>
      <PaymentCard>
        <LabOnboardingWidget
          product_name={intake.get('product_name')}
          setOpenModalAtHomeLab={setOpenModalAtHomeLab}
          use_own_lab={use_own_lab}
          lab_kit_type={lab_kit_type}
          priceCalculator={priceCalculator}
          setFieldValue={setFieldValue}
          own_lab_file_uploaded={own_lab_file_uploaded}
          inputFile={inputFile}
          setOwnLabFile={setOwnLabFile}
          setOpenModalOwnLab={setOpenModalOwnLab}
          byolFormSubmitted={byolFormSubmitted}
          kingV2OralTrt={kingV2OralTrt}
          labSelectionStep={labSelectionStep}
        />
        {!labSelectionStep && (
          <>
            <Divider className="mt32 mb24" />
            <div className="price_row">
              <div>LAB COST:</div>
              <PriceFormatter price={totalLabPrice.toFixed(2)} />
            </div>
          </>
        )}
      </PaymentCard>
    </>
  );
};

export default LabWidget;
