import React, { FC, ReactNode, useEffect } from 'react';
import 'app/components/customer/steps/Payment/css/MultimonthPlanSelection.scss';
import Radio from 'app/components/common/Radio';
import { P } from 'app/components/common/Typography';
import KingCrown from 'images/king-crown.png';
import { useFormikContext } from 'formik';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Badge, Card, PlansList } from 'mui';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import subProductNames from 'app/utils/subProductNames';
import { AvailableProducts } from 'app/constants/Products';

const PriceFormatter = ({ price, className = '' }) => {
  const showCents = (price / 100) % 1 > 0;
  return (
    <span className={className}>
      {parseInt(String(price / 100))}
      {showCents && <sup>{((price / 100) % 1).toFixed(2).replace('0.', '')}</sup>}
      <span className="per-month">/mo</span>
    </span>
  );
};

const RadioLabel: FC<{
  title: ReactNode;
  subheader?: ReactNode;
  savings?: ReactNode;
  hint?: ReactNode;
  drugsImage?: string;
  specialLabel?: string;
  count?: number;
  totalPrice?: number;
}> = ({ title, subheader, specialLabel, count, savings, hint, drugsImage, totalPrice }) => {
  return (
    <div className="multimonth_treatment_plan__option relative">
      {specialLabel && (
        <div className="special-label">
          <img src={KingCrown} /> <span>{specialLabel}</span>
        </div>
      )}
      <div className="card-content">
        <div style={{ display: 'flex' }}>
          <div className="card-image">
            <img src={drugsImage} />
            {count && <span>x {count}</span>}
          </div>
          <div className="titles">
            <div className="title">{title}</div>
            <P className="subtitle">{subheader}</P>
            {savings && <P className="savings">{savings}</P>}
            {hint && <P className="hint">{hint}</P>}
          </div>
        </div>
        <div className="price">
          <div className="prive-value">
            <span className="dollar-sign">$</span>
            <span className="amount">
              <PriceFormatter price={totalPrice} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface GenericMultimonthPlanSelectionFormProps {
  multimonthConfig: any[];
  priceConfig: Record<string, Record<number, number>>;
  productName: string;
  selectedSubProduct: string;
  handleChangeProduct: () => void;
  drugsImage: string;
  showBreadcrumbs?: boolean;
  description?: React.ReactNode;
  productHeader?: React.ReactNode;
}

const GenericMultimonthPlanSelectionForm: FC<GenericMultimonthPlanSelectionFormProps> = ({
  multimonthConfig,
  priceConfig,
  productName,
  selectedSubProduct,
  handleChangeProduct,
  drugsImage,
  showBreadcrumbs = false,
  description = null,
  productHeader,
}) => {
  const newVersion = useRedesign();
  const { values, setFieldValue } = useFormikContext<{
    multimonth_plan: number;
  }>();

  const breadcrumbs = showBreadcrumbs ? (
    <div onClick={handleChangeProduct} className="generic_payment_page__change_subs">
      Change Product
    </div>
  ) : null;

  const currentPlanValue = values.multimonth_plan;

  useEffect(() => {
    if (currentPlanValue && !priceConfig[selectedSubProduct][currentPlanValue]) {
      setFieldValue('multimonth_plan', Object.keys(priceConfig[selectedSubProduct])[0]);
    }
  });

  const renderRadio = (count: number, specialLabel: string, savings: string, subheader: string, title: string) => {
    const totalPrice = priceConfig[selectedSubProduct][count];

    if (!totalPrice) return null;

    return (
      <Radio
        testId={`select-plan-${count}`}
        onChange={() => setFieldValue('multimonth_plan', count)}
        checked={count === values.multimonth_plan}
        label={
          <RadioLabel
            count={count}
            specialLabel={specialLabel}
            savings={savings}
            subheader={subheader}
            title={title}
            totalPrice={priceConfig[selectedSubProduct][count]}
            drugsImage={drugsImage}
          />
        }
      />
    );
  };

  const renderPlan = (config) => {
    const totalPrice = priceConfig[selectedSubProduct][config.value];

    if (!totalPrice) return null;

    return {
      title: config.title,
      checked: config.value === values.multimonth_plan,
      priceUnit: parseInt(String(priceConfig[selectedSubProduct][config.value] / 100)),
      priceFraction: ((priceConfig[selectedSubProduct][config.value] / 100) % 1).toFixed(2).replace('0.', ''),
      text: (
        <>
          <div>{config.subheader}</div>
          <div>{config.savings}</div>
        </>
      ),
      testid: `select-plan-${config.value}`,
      value: config.value,
      onChange: () => setFieldValue('multimonth_plan', config.value),
    };
  };

  return newVersion ? (
    <Card>
      <Card.Body>
        <figure className="h-[180px] lg:h-[240px] mb-4">
          <img
            className="h-full w-auto"
            src={
              productName == AvailableProducts.Lover
                ? ImagesMapping[productName]
                : ImagesMapping[productName][selectedSubProduct]
            }
            alt={productName}
          />
        </figure>
        <div className="text-center">
          <Badge variant={productName} className="mb-2">
            {protocolNames[productName]}
          </Badge>
          {productName == AvailableProducts.Lover && <h4>Vardenafil + Tadalafil</h4>}
          <h4>{subProductNames[productName][selectedSubProduct]}</h4>
        </div>
        <PlansList
          items={multimonthConfig.map((config) => renderPlan(config)).filter((x) => x !== null)}
          radioName="multimonth_plan"
        />
      </Card.Body>
    </Card>
  ) : (
    <div>
      <div>
        {description && (
          <div key="1" className="multimonth_treatment_plan_options__card-description">
            {description}
          </div>
        )}
        {productHeader || (
          <ProductHeader
            productName={productName}
            subProductName={selectedSubProduct}
            breadcrumbs={breadcrumbs}
            showTitle={true}
            cardStyle={true}
          />
        )}
        <div className="mt24 mb24 font-medium multimonth_treatment_plan_options relative">
          {multimonthConfig.map((config) =>
            renderRadio(config.value, config.specialLabel, config.savings, config.subheader, config.title),
          )}
        </div>
      </div>
    </div>
  );
};

export default GenericMultimonthPlanSelectionForm;
