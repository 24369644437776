import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import { H6 } from 'app/components/common/Typography';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import './css/PageHeader.scss';

const PageHeader: FC<{
  title: ReactNode;
  className?: string;
}> = ({ title, className, children }) => {
  const newVersion = useRedesign();
  return newVersion ? (
    <>
      {!title && children}
      {title && <h3>{title}</h3>}
    </>
  ) : (
    <div className={cx('page-header', className)}>
      {!title && children}
      {title && <H6>{title}</H6>}
    </div>
  );
};

export default PageHeader;
