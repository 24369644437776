import React from 'react';
import { useFormikContext } from 'formik';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import Radio from 'app/components/common/Radio';
import { kingMonthlyPriceExperiment } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import '../css/PlanSelection.scss';
import PriceCalculator from 'app/helpers/priceCalculator';
import { useExperiment } from 'app/utils/useExperiment';

type Props = {
  onMultimonthUpdate: (e: any) => void;
  subProductName: string;
  priceCalculator: PriceCalculator;
};

const PlanSelection = ({ onMultimonthUpdate, subProductName, priceCalculator }: Props) => {
  const experimentVariation = useExperiment('testosterone_pricing', 'testosterone_pricing_variation_0');
  const { values } = useFormikContext<{
    multimonth_plan: number;
  }>();

  const MultimonthConfig = [
    {
      value: 1,
      subheader: `$${Number(priceCalculator.montlyPayment()) / 100} every 30 days`,
      title: 'Monthly Plan',
    },
    {
      value: 3,
      subheader: `$${priceCalculator.quarterlyPayment() / 100} every 90 days`,
      title: 'Quarterly Plan',
    },
    {
      value: 12,
      subheader: `$${priceCalculator.yearlyPayment() / 100} every 360 days`,
      title: 'Yearly Plan',
    },
  ];

  const renderRadio = (value: number, subheader: string, title: string) => {
    return (
      <Radio
        onChange={() => onMultimonthUpdate(value)}
        checked={values.multimonth_plan === value}
        className="plan_box p12"
        label={
          <div className="">
            <div className="price_row">
              <div>{title}</div>
              <PriceFormatter
                price={kingMonthlyPriceExperiment(experimentVariation)[subProductName][value]}
                period="mon"
              />
            </div>
            <p className="plan_box_sub_header">{subheader}</p>
          </div>
        }
      />
    );
  };

  return (
    <div className="plan_selection multimonth_treatment_plan_options relative">
      {MultimonthConfig.map(({ value, subheader, title }) => renderRadio(value, subheader, title))}
    </div>
  );
};

export default PlanSelection;
