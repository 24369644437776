import React, { useMemo } from 'react';
import Typography from '@setproduct-ui/core/Typography';
import { S, P } from 'app/components/common/Typography';

import './css/Title.scss';
import Question from 'app/constants/Question';
import { SanitizeHTML } from 'app/utils';

export const BlackTitle = ({
  question,
  position,
}: {
  question:
    | {
        name?: string;
        sentence: string;
        data: { description?: string; description_alert?: string; description_underlined?: string };
      }
    | Question;
  position: number;
}) => {
  const number = useMemo(() => position, []);
  const isRawHTML = question?.name?.includes('freeform');

  return (
    <>
      <Typography className="mh16 mt24 mb12 question-label" tagName="h2" colorStep={80}>
        <S color="primary" type="body_bold" large>
          {number}.
        </S>{' '}
        <span className="bold">{question.sentence}</span>
      </Typography>
      {(!!question.data.description || !!question.data.description_alert || !!question.data.description_underlined) && (
        <P large className="mh16 mb24 question-label">
          {question.data.description_underlined && (
            <span className="pre-wrap underlined">{question.data.description_underlined}</span>
          )}
          {question.data.description && (
            <span className="pre-wrap">
              {isRawHTML ? <SanitizeHTML html={question.data.description} /> : question.data.description}
            </span>
          )}
          {question.data.description_alert && (
            <span className="pre-wrap description-alert">{question.data.description_alert}</span>
          )}
        </P>
      )}
    </>
  );
};
