import React, { useContext, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import cx from 'classnames';
import { ONBOARDING_TYPES, selectPharmacyChoice } from 'app/selectors/customer';
import { PrimaryButton } from 'app/components/common/Button';
import TitleWithIcon from 'app/components/customer/ProgressTracker/common/TitleWithIcon';
import IconAttention from 'images/progress-tracker/Attention.svg';
import type { Record as ImmutableRecord } from 'immutable';
import { isArray } from 'lodash';
import { AvailableProducts } from 'app/constants/Products';
import IconBottle from 'images/progress-tracker/bottle.svg';
import IconHospital from 'images/progress-tracker/hospital.svg';
import IconMeds from 'images/progress-tracker/meds.svg';

import { getTracking } from 'app/utils/tracking';
import { useAppSelector } from 'app/helpers/hooks';
import { ProductContext } from '../../Product';
import './css/TrackerNotification.scss';
import { Button } from 'mui';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

export type LabOrderResult = ImmutableRecord<{
  date: string;
  file: ImmutableRecord<{
    blob_id: string;
  }>;
}>;

export enum NotificationType {
  LabInfo = 'labInfo',
  LabOrderResult = 'labOrderResult',
  ErxShipmentDate = 'erxShipmentDate',
}

interface BaseNotification {
  date: string;
  dismissed: boolean;
}
interface LabInfoNotification extends BaseNotification {
  type: NotificationType.LabInfo;
  obj: {
    kit_id?: string;
    shipped_at?: string | null;
    intake?: string;
    tracking_numbers?: string[][];
  };
}
interface LabOrderResultNotification extends BaseNotification {
  type: NotificationType.LabOrderResult;
  obj: LabOrderResult;
}
interface ErxShipmentDateNotification extends BaseNotification {
  type: NotificationType.ErxShipmentDate;
  obj: {
    intakePharmacyShipment?: any;
    date: string;
    intake: string;
  };
}
export type Notification = LabInfoNotification | LabOrderResultNotification | ErxShipmentDateNotification;
export type TrackingNumberObject = { trackingNumber: string | undefined; courier: string | undefined } | null;

type Props = {
  customerId: string;
  currentIntake?: ImmutableRecord<{
    name: string;
    current_subscription_king_product: string;
  }>;
  notification: Notification;
  dismiss: (name: string) => void;
};

const buildLabResultDownloadUrl = (customerId: string, result: LabOrderResult) =>
  `/users/${customerId}/downloads/${result.get('file').get('blob_id')}`;
const resolveTrackingUrl = (trackingNumberObj: TrackingNumberObject) => {
  if (!trackingNumberObj) {
    return;
  }
  const tracking = getTracking(trackingNumberObj.trackingNumber, trackingNumberObj.courier);
  if (tracking?.trackingUrl) {
    return tracking.trackingUrl.replace('%s', tracking.trackingNumber);
  }
};

const INSTRUCTION_URLS = {
  king: {
    v1: 'https://maximustribe.com/testosterone-protocol-instructions-enclo-capsules',
    v1_5: 'https://maximustribe.com/testosterone-protocol-instructions-enclo-preg-ODT',
    v2: 'https://maximustribe.com/oral-trt-guide',
    topical: 'https://maximustribe.com/top-trt-guide',
    injectable: 'https://maximustribe.com/inj-trt-guide',
  },
  lover: {
    tailormade: 'https://drive.google.com/file/d/1rOcayKCmCnuvlJuviCbhYdgEBrq99NSV/view',
    belmar: 'https://maximustribe.com/tadalfil-vardenafil-instructions',
    precision: 'https://maximustribe.com/tadalfil-vardenafil-instructions',
  },
  warrior: 'https://maximustribe.com/warrior-instructions',
};

function mapNotification(
  customerId: string,
  productName: string | null | undefined,
  currentIntakeName: string | undefined,
  notification: Notification,
  pharmacy: string,
  kingVersion: string,
): {
  type: NotificationType;
  className?: string;
  alarmText?: string | null;
  title: string;
  titleIcon: any;
  createdAt: string;
  name: string;
  text: string[];
  trackingUrl: string | undefined;
  children: React.ReactNode;
  notificationsLinks: React.ReactNode;
} | null {
  const { type } = notification;
  const isKing = productName === AvailableProducts.King;
  let instructionLink = productName ? INSTRUCTION_URLS[productName] : '';

  if (isKing) {
    instructionLink = INSTRUCTION_URLS[productName][kingVersion];
  }
  if (productName === AvailableProducts.Lover) {
    instructionLink = INSTRUCTION_URLS[productName][pharmacy] || INSTRUCTION_URLS[productName].precision;
  }

  switch (notification.type) {
    case NotificationType.LabInfo: {
      const isOnboarding = ONBOARDING_TYPES.includes(currentIntakeName ?? '');
      const guideLink = notification.obj.kit_id?.match('TASSO')
        ? window.QUICKSTARTGUIDE_TASSO_URL
        : window.QUICKSTARTGUIDE_URL;

      const tn =
        notification.obj.tracking_numbers && notification.obj.tracking_numbers.length > 0
          ? notification.obj.tracking_numbers[0]
          : null;
      let trackingUrl = '';
      if (isArray(tn) && tn.length) {
        const num = tn[tn.length - 1];
        const courier = tn[tn.length - 2];
        trackingUrl = resolveTrackingUrl({ trackingNumber: num, courier }) ?? '';
      }
      const text = trackingUrl
        ? [
            'Your lab kit is on the way! Below you can find a link to track your shipment plus instructions for how to complete your lab kit once it arrives.',
            '<i>When can I expect my results?</i>',
            'Once you ship your completed kit back, please allow <u>8-10 business days</u> for your kit to be processed by the laboratory. Once processed you will receive a notification that your results are ready.',
          ]
        : [
            'Your lab kit has been ordered. Labs kits are typically delivered within 2-3 business days.',
            'Below you can find instructions for how to complete your lab kit once it arrives.',
            '<i>When can I expect my results?</i>',
            'Once you ship your completed kit back, please allow <u>8-10 business days</u> for your kit to be processed by the laboratory. Once processed you will receive a notification that your results are ready.',
          ];

      return {
        type,
        createdAt: notification.date,
        name: `lab-shipped-${notification.obj.intake}`,
        title: 'Lab Shipped',
        alarmText: isOnboarding
          ? 'Important: Make sure to complete your first lab test before you start your medication.'
          : null,
        titleIcon: IconBottle,
        children: <PrimaryButton onClick={() => window.open(guideLink)} text="Lab Instructions" />,
        notificationsLinks: (
          <a className="links-format" onClick={() => window.open(guideLink)}>
            View Lab Instructions
          </a>
        ),
        trackingUrl,
        text,
      };
    }
    case NotificationType.LabOrderResult: {
      return {
        type,
        name: `lab-result-${notification.obj.get('file').get('blob_id')}`,
        createdAt: notification.obj.get('date'),
        title: 'Lab Results',
        titleIcon: IconBottle,
        text: ['Your labs have been processed! Click the button below to view your results.'],
        trackingUrl: '',
        children: (
          <PrimaryButton
            onClick={() => window.open(buildLabResultDownloadUrl(customerId, notification.obj))}
            className="bg_white"
            text="View Results"
          />
        ),
        notificationsLinks: (
          <a
            className="links-format"
            onClick={() => window.open(buildLabResultDownloadUrl(customerId, notification.obj))}
          >
            View Results
          </a>
        ),
      };
    }
    case NotificationType.ErxShipmentDate: {
      const trackingNumber = notification.obj?.intakePharmacyShipment?.tracking_number;
      const courier = notification.obj?.intakePharmacyShipment?.carrier;
      const trackingNumberObj = notification.obj.intakePharmacyShipment ? { trackingNumber, courier } : null;
      const trackingUrl = resolveTrackingUrl(trackingNumberObj);
      const text: string[] = [];

      text.push('Your medication is on the way!');
      if (trackingUrl) {
        text.push(
          'Your shipment tracking is available below, along with instructions for how to take your medication once it arrives.',
        );
      } else {
        text.push('Below you can find instructions for how to take your medication once it arrives.');
      }

      return {
        type,
        title: 'Meds Shipped',
        createdAt: notification.obj.date,
        className: isKing ? undefined : 'updated-design',
        name: `erx-${notification.obj.intake}`,
        titleIcon: isKing ? IconHospital : IconMeds,
        alarmText: null,
        text,
        trackingUrl,
        children: (
          <>
            {productName !== AvailableProducts.Magician && (
              <PrimaryButton
                onClick={() => window.open(instructionLink)}
                className="bg_white"
                text="MED INSTRUCTIONS"
              />
            )}
          </>
        ),
        notificationsLinks: (
          <>
            {productName !== AvailableProducts.Magician && (
              <a className="links-format" onClick={() => window.open(instructionLink)}>
                View Medication Instructions
              </a>
            )}
          </>
        ),
      };
    }
    default:
      return null;
  }
}

const TrackerNotification = ({ customerId, currentIntake, notification, dismiss }: Props) => {
  const productName = useContext(ProductContext)?.selectedProduct;
  const pharmacy = useAppSelector((state) => selectPharmacyChoice(state, productName));
  let kingVersion = 'v1';
  if (productName?.toLowerCase() === 'king') {
    const kingSubProduct = currentIntake?.get('current_subscription_king_product');

    if (kingSubProduct && kingSubProduct.includes('topical')) {
      kingVersion = 'topical';
    } else if (kingSubProduct && kingSubProduct.includes('injectable')) {
      kingVersion = 'injectable';
    } else if (kingSubProduct && kingSubProduct.includes('trt')) {
      kingVersion = 'v2';
    } else {
      kingVersion = 'v1_5';
    }
  }
  const mappedProps = useMemo(
    () => mapNotification(customerId, productName, currentIntake?.get('name'), notification, pharmacy, kingVersion),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId, notification, currentIntake?.get('name') || null],
  );

  if (!mappedProps) {
    return null;
  }
  const {
    title,
    name,
    titleIcon,
    alarmText,
    text,
    children,
    createdAt,
    trackingUrl,
    type,
    className,
    notificationsLinks,
  } = mappedProps;
  const { dismissed } = notification;
  const handleDismissClick = () => dismiss(name);
  const handleTrackingBtnClick = (e) => {
    e.preventDefault();

    return window.open(trackingUrl, '_blank');
  };

  const dateToFormat = new Date(createdAt);
  const formattedCreatedAt = dateToFormat.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const finalDateFormat = formattedCreatedAt.replace(/^[A-Za-z]+/, (month) => month.toUpperCase());

  const oldDesignSystem = () => (
    <div
      className={cx('tracker_notification', className, {
        dismissed,
        'bg-hospital': type === NotificationType.ErxShipmentDate,
      })}
    >
      <TitleWithIcon icon={<ReactSVG src={titleIcon} className="svg-icon" />}>{title}</TitleWithIcon>
      <span className="tracker_notification__date">{createdAt}</span>
      {text.map((section, i) => (
        <p key={i} className="tracker_notification__description" dangerouslySetInnerHTML={{ __html: section }} />
      ))}
      {!!alarmText && (
        <div className="tracker_notification_alarm">
          <div className="flex">
            <div>
              <img src={IconAttention} />
            </div>
            <div>{alarmText}</div>
          </div>
        </div>
      )}
      <div className="tracker_notification__buttons">
        {children}
        {!dismissed && <PrimaryButton text="Dismiss" onClick={handleDismissClick} />}
        {dismissed && trackingUrl && <PrimaryButton text="TRACK SHIPMENT" onClick={handleTrackingBtnClick} />}
      </div>
      {!dismissed && trackingUrl && (
        <div className="tracker_notification__buttons">
          <PrimaryButton text="TRACK SHIPMENT" onClick={handleTrackingBtnClick} />
        </div>
      )}
    </div>
  );

  const newDesignSystem = () => (
    <div className="new-design">
      <h4 className="date-format">{finalDateFormat}</h4>
      <h2 className="title-format">{title}</h2>
      {text.map((section, i) => (
        <p key={i} className="paragraph-format" dangerouslySetInnerHTML={{ __html: section }} />
      ))}
      <a>{notificationsLinks}</a>
      {trackingUrl && (
        <Button className="mt-6" variant="primary" onClick={handleTrackingBtnClick}>
          TRACK SHIPMENT
        </Button>
      )}
    </div>
  );

  const newTheme = useRedesign();

  return newTheme ? newDesignSystem() : oldDesignSystem();
};

export default React.memo(TrackerNotification);
