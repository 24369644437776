import React from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import {
  selectCurrentIntake,
  selectCustomer,
  selectCustomerProduct,
  selectFurthestProductStepPath,
} from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { apiRequestUserCommand } from 'app/actions/customer';
import { Formik } from 'formik';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import PageHeader from 'app/components/common/PageHeader';
import { useDispatch } from 'react-redux';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';
import DiscountBanner from '../DiscountBanner';
import { PageWrapper } from 'mui';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

const MultimonthPlanSelectionStep = () => {
  const intake = useAppSelector(selectCurrentIntake);
  const customer = useAppSelector(selectCustomer);
  const productName = intake.get('product_name');
  const product = useAppSelector((state) => selectCustomerProduct(state, productName));
  const dispatch = useDispatch();

  const checkoutHelper = createCheckoutHelper(intake, customer, product, true);

  const goToSelectProductPage = push(checkoutHelper.selectProductStepPath());

  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectFurthestProductStepPath({ customer: stateAfterSuccess }, productName));

  const initialValues = checkoutHelper.productSpecificInitialValues();
  const handleChangeProduct = () => dispatch(goToSelectProductPage);

  const onSubmit = (params: any) => {
    const action = apiRequestUserCommand({
      cmdType: 'select_multimonth_plan',
      params: { ...params, intake: intake.get('name') },
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  const newVersion = useRedesign();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) =>
        newVersion ? (
          <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
            <DiscountBanner />
            <h3 className="ml-10 sm:ml-5">Choose Your Plan</h3>
            {checkoutHelper.renderMultimonthStep(handleChangeProduct)}
            <BackNextFooter
              noBack={true}
              onNext={handleSubmit}
              testId="submit-plan-selection"
              horizontalPadding={false}
            />
          </PageWrapper>
        ) : (
          <div className="centered mb24 updated-design payments">
            <DiscountBanner />
            <PageHeader title="Choose Your Plan" className="treatment_plan__header mb24 -edged" />
            <div className="content">
              {checkoutHelper.renderMultimonthStep(handleChangeProduct)}
              <BackNextFooter
                noBack={true}
                onNext={handleSubmit}
                testId="submit-plan-selection"
                horizontalPadding={false}
              />
            </div>
          </div>
        )
      }
    </Formik>
  );
};

export default MultimonthPlanSelectionStep;
