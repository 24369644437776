import React, { FC } from 'react';
import cx from 'classnames';
import './css/SocialMedia.scss';

import SOCIALS from 'app/constants/socials.json';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

const SocialMedia: FC<{
  className?: string;
  socials?: Array<keyof typeof SOCIALS>;
}> = ({ className = '', socials = ['reddit', 'facebook', 'instagram', 'twitter'] }) => {
  const socialsList = socials.map((type) => ({ type, ...SOCIALS[type] }));
  const newTheme = useRedesign();

  const oldDesignSystem = () => (
    <section className={cx('social-media center-align', className)}>
      <p className="social-media__text mv16">
        Learn more and join our social community&nbsp;
        <a href="https://linktr.ee/maximustribe" rel="noreferrer" target="_blank">
          here
        </a>
        .
      </p>
      <nav className="social-media__list">
        {socialsList.map(({ type, name, url }) => (
          <a href={url} key={type} className="social-media__item" target="_blank" rel="noreferrer">
            <i className={cx('social-media__icon', `iconfont-${type}`)} aria-hidden="true" />
            <span className="ghost">{name}</span>
          </a>
        ))}
      </nav>
    </section>
  );

  const newDesignSystem = () => (
    <section className={cx('social-media center-align mt-8 lg:mt-12', className)}>
      <p className="social-media__text mv16">
        <a style={{ color: '#2E4CFF' }} href="https://linktr.ee/maximustribe" rel="noreferrer" target="_blank">
          Join our social community
        </a>
      </p>
      <nav className="social-media__list">
        {socialsList.map(({ type, name, url }) => (
          <a
            style={{ color: '#2E4CFF' }}
            href={url}
            key={type}
            className="social-media__item"
            target="_blank"
            rel="noreferrer"
          >
            <i className={cx('social-media__icon', `iconfont-${type}`)} aria-hidden="true" />
            <span className="ghost">{name}</span>
          </a>
        ))}
      </nav>
    </section>
  );

  return <>{newTheme ? newDesignSystem() : oldDesignSystem()}</>;
};

export default SocialMedia;
