import React, { FC, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { P2, H5 } from 'app/components/common/Typography';
import { apiRequestUserCommand, geolocationFailed } from 'app/actions/customer';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import { Loader } from '@googlemaps/js-api-loader';
import { Dispatch } from 'redux';
import { Record } from 'immutable';
import { selectCustomerShippingAddress } from 'app/selectors/customer';
import { CustomerShippingAddress } from 'app/types';

import GoogleMapsComponent from './GoogleMapsComponent';

import Spinner from '../../Spinner';
import { ImmutableMapping } from 'app/types/admin';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Card, Heading, PageWrapper } from 'mui';

type BrowserLocation = Record<{
  latitude: number;
  longitude: number;
}>;

interface Props {
  dispatch: Dispatch;
  redirectPrevious: () => void;
  browserLocation?: BrowserLocation;
  noBack?: boolean;
  shipping_address: ImmutableMapping<CustomerShippingAddress>;
}

const convertLocation = (loc?: BrowserLocation) =>
  loc && {
    lat: parseFloat(loc.get('latitude') as unknown as string),
    lng: parseFloat(loc.get('longitude') as unknown as string),
  };

const Confirm: FC<Props> = ({ dispatch, redirectPrevious, noBack = false, browserLocation, shipping_address }) => {
  const googleBrowserLocation = convertLocation(browserLocation);
  const [position, setPosition] = useState(googleBrowserLocation);
  const submitLocationAdjustment = useCallback(() => {
    const params = { self_reported_position: position };
    const action = apiRequestUserCommand({
      cmdType: 'answer_questions',
      params,
      context: undefined,
    });
    dispatch(action);
  }, [dispatch, position]);

  useEffect(() => {
    if (!browserLocation) {
      (async () => {
        const loader = new Loader({
          apiKey: (window as any).googleMapsAPIKey,
          libraries: ['places'],
        });
        const google = await loader.load();
        const geocoder = new google.maps.Geocoder();
        let response: any;
        try {
          response = await geocoder.geocode({
            address: `US, ${shipping_address.get('state')}, ${shipping_address.get('postal_code')}`,
          });
          if (!response.results.length) {
            throw new Error('Not detected geolocation by state and postal code');
          }
        } catch {
          response = await geocoder.geocode({
            address: `US, ${shipping_address.get('state')}`,
          });
        }
        if (response.results) {
          setPosition({
            lat: parseFloat(response.results[0].geometry.location.lat()),
            lng: parseFloat(response.results[0].geometry.location.lng()),
          });
        } else {
          throw new Error('Not detected geolocation by state');
        }
      })().catch((e) => {
        dispatch(geolocationFailed(e));
      });
    }
  }, [browserLocation, shipping_address, dispatch]);
  if (!position) {
    return <Spinner isCenter />;
  }

  // TODO: Is there a better way to test that we're in a local development environment?
  const isLocalDevelopment: boolean = window.location.host === 'localhost:3000';
  const newVersion = useRedesign();
  return newVersion ? (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <Heading headingLevel="p" label>
        Verify Location
      </Heading>
      <Card>
        <figure style={{ position: 'relative', height: '20em', borderRadius: '1rem 1rem 0 0', overflow: 'clip' }}>
          <GoogleMapsComponent initialPosition={position} onPositionChange={setPosition} />
        </figure>
        <Card.Body>
          <div>
            <h4 className="mb-2">Confirm Location</h4>
            <p>If this is not your current location, please move the map to where you currently are.</p>
          </div>
          <BackNextFooter
            noBack={noBack}
            nextText="Confirm"
            onBack={redirectPrevious}
            onNext={submitLocationAdjustment}
          />
        </Card.Body>
      </Card>
    </PageWrapper>
  ) : (
    <div>
      <div style={{ position: 'relative', height: '20em' }}>
        <GoogleMapsComponent initialPosition={position} onPositionChange={setPosition} />
      </div>
      <H5 className="mh24 mt24">Confirm Location</H5>
      <P2 className="flex1 mh24 mt8">
        {isLocalDevelopment ? (
          <>Hi developer! Click Next to continue with your location set as Florida.</>
        ) : (
          <>If this is not your current location, please tap on the map where you currently are.</>
        )}
      </P2>
      <BackNextFooter noBack={noBack} onBack={redirectPrevious} onNext={submitLocationAdjustment} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  shipping_address: selectCustomerShippingAddress(state),
});

export default connect(mapStateToProps)(Confirm);
