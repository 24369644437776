import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import { P } from 'app/components/common/Typography';
import React, { useState } from 'react';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import ModalBodySubscription from 'app/components/customer/Checkout/ModalBodySubscription';
import { AvailableProducts } from 'app/constants/Products';
import PriceCalculator from 'app/helpers/priceCalculator';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

const BILLED_EVERY_INFO = {
  1: 'month',
  3: '3 months',
  12: '12 months',
};

const BILLED_EVERY_INFO_SHORT = {
  1: 'mo',
  3: '3 mos',
  12: 'year',
};

const MODAL_DAYS_MAPPING = {
  magician: {
    1: 28,
    3: 84,
    6: 168,
    12: 336,
  },
  default: {
    1: 30,
    3: 90,
    12: 360,
  },
  warrior: 90,
};

const DEFAULT_BILLING_PERIOD = '30 days';

type Props = {
  multimonthPeriod: string;
  multimonthPlan: number;
  nextBillingCycle: string;
  currentProductName: string;
  isOnboarding: boolean;
  priceCalculator: PriceCalculator;
};

const GenericSubscriptionSummary = ({
  isOnboarding,
  nextBillingCycle,
  multimonthPeriod,
  multimonthPlan,
  priceCalculator,
  currentProductName,
}: Props) => {
  const [openModalSubscription, setOpenModalSubscription] = useState(false);

  const subscriptionPeriodDays = (): number => {
    if (MODAL_DAYS_MAPPING[currentProductName] && MODAL_DAYS_MAPPING[currentProductName][multimonthPlan]) {
      return MODAL_DAYS_MAPPING[currentProductName][multimonthPlan];
    }

    return MODAL_DAYS_MAPPING['default'][multimonthPlan];
  };

  const billingPeriod = () => {
    switch (currentProductName) {
      case AvailableProducts.Magician:
        return `${MODAL_DAYS_MAPPING[AvailableProducts.Magician][multimonthPlan]} days`;
      case AvailableProducts.King:
        return BILLED_EVERY_INFO[multimonthPlan];
      case AvailableProducts.Lover:
        if (multimonthPlan) {
          return `${MODAL_DAYS_MAPPING['default'][multimonthPlan]} days`;
        }
        return DEFAULT_BILLING_PERIOD;
      case AvailableProducts.Warrior:
        return `${MODAL_DAYS_MAPPING[AvailableProducts.Warrior]} days`;
      default:
        return DEFAULT_BILLING_PERIOD;
    }
  };

  const newVersion = useRedesign();

  return newVersion ? (
    <>
      <div className="bg-main-grey rounded-xl p-4 lg:p-8">
        <div className="flex flex-row justify-between font-medium mb-4 lg:mb-8 items-center">
          <div>
            <h5>Subscription Cost</h5>
          </div>
          <PriceFormatter price={priceCalculator.subscriptionCost()} period={BILLED_EVERY_INFO_SHORT[multimonthPlan]} />
        </div>
        {isOnboarding && (
          <div className="text-xs">
            <P className="pb-4">
              Your subscription bills upfront and auto-renews every {BILLED_EVERY_INFO[multimonthPlan]} (
              {subscriptionPeriodDays} days)* unless a cancelation request is placed before the end of the billing
              cycle.
            </P>
            <P>Medication ships monthly to allow for treatment customization.</P>
            <P>
              <label className="link" onClick={() => setOpenModalSubscription(true)}>
                *Full Details
              </label>
            </P>
          </div>
        )}
        {!isOnboarding && (
          <P className="mb12">
            Your new subscription cost will start on your{' '}
            <span className="bold">next billing cycle, {nextBillingCycle}</span>, and will auto-renew every{' '}
            {BILLED_EVERY_INFO[multimonthPlan]} ({subscriptionPeriodDays} days).
            <br />
            <a href="#" onClick={() => setOpenModalSubscription(true)}>
              Full subscription details
            </a>
            .
          </P>
        )}
      </div>
      <SetProductDialog
        isOpen={openModalSubscription}
        title="How Your Subscription Works"
        text={
          <ModalBodySubscription
            setOpenModalSubscription={setOpenModalSubscription}
            subscriptionPeriodDays={subscriptionPeriodDays()}
          />
        }
        onClose={() => setOpenModalSubscription(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </>
  ) : (
    <>
      <div className="price_row mt24">
        <div>
          <h2>
            Subscription Cost
            <span className="generic_payment_page__multi_month_variant"> ({multimonthPeriod.toLowerCase()})</span>
          </h2>
        </div>
        <PriceFormatter price={priceCalculator.subscriptionCost()} />
      </div>
      <div className="generic_payment_page__plan_summary plan_summary">
        {isOnboarding && (
          <P>
            Bills every {billingPeriod()}. Subscription auto-renews unless a cancelation request is placed before the
            end of the billing cycle.
            <br />
            <span className="link" onClick={() => setOpenModalSubscription(true)}>
              Full details
            </span>
            .
          </P>
        )}
        {!isOnboarding && (
          <>
            <P>
              Your new subscription cost will start on your{' '}
              <span className="bold">next billing cycle, {nextBillingCycle}</span>, and will auto-renew every{' '}
              {BILLED_EVERY_INFO[multimonthPlan]}
            </P>
            <P className="mt24">
              Any changes to what is included in your monthly shipments will begin after this billing date.{' '}
              <a href="#" onClick={() => setOpenModalSubscription(true)}>
                Full details
              </a>
              .
            </P>
          </>
        )}
      </div>
      <SetProductDialog
        isOpen={openModalSubscription}
        title="How Your Subscription Works"
        text={
          <ModalBodySubscription
            setOpenModalSubscription={setOpenModalSubscription}
            subscriptionPeriodDays={subscriptionPeriodDays()}
          />
        }
        onClose={() => setOpenModalSubscription(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </>
  );
};

export default GenericSubscriptionSummary;
