import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { PrimaryButton } from 'app/components/common/Button';
import { P } from 'app/components/common/Typography';
import { Login } from 'app/constants/Routes';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Button } from 'mui';

const BeenHereBefore: FC<{
  setShowLoginModal: (show: boolean) => void;
}> = ({ setShowLoginModal }) => {
  const history = useHistory();
  const newVersion = useRedesign();

  return newVersion ? (
    <div>
      <Button onClick={() => history.push(Login)} type="button" className="mb-4">
        Login
      </Button>
      <Button variant="text" onClick={() => setShowLoginModal(false)}>
        Create an account
      </Button>
    </div>
  ) : (
    <div>
      <PrimaryButton text="LOGIN" onClick={() => history.push(Login)} type="button" />
      <a onClick={() => setShowLoginModal(false)}>
        <P color="primary" tagName="span" className="hover-underlined cookie-email-modal__create_account" large={false}>
          CREATE AN ACCOUNT
        </P>
      </a>
    </div>
  );
};

export default BeenHereBefore;
